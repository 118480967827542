import { Box, Typography } from "@mui/material";
import MatrialCard from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import ClampedText from "src/components/ClampedText";
import { useDisplaySettingsContext } from "src/contexts/DisplaySettings";
import { hexToRGBA } from "src/lib/utils";
import CardProps from "src/types/card";
import "./styles.css";

export default function Management3Card({
  title,
  subtitle1,
  subtitle2,
  image,
}) {
  const { heading2, text1, text2, text3, foregroundColor } =
    useDisplaySettingsContext();

  return (
    <div className="main">
      <ul className="cards">
        <li className="cards_item">
          <div className="card">
            <div className="card_image">
              <img
                src={
                  image.url ??
                  "https://assets.codepen.io/652/photo-1468777675496-5782faaea55b.jpeg"
                }
                alt={image.alt ?? "image"}
              />
            </div>
            <div className="card_content">
              <h2 className="card_title">{title}</h2>
              <div className="card_text">
                {subtitle1}
                <hr />
                {subtitle2}
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
}

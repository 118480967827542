import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useDisplaySettingsContext } from "src/contexts/DisplaySettings";

export const CategoryButton = ({
  name,
  selectedCategory,
  setSelectedCategory,
}) => {
  const isSelected = name === selectedCategory;
  const { text2, foregroundColor } = useDisplaySettingsContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <Box
        onClick={() => {
          setSelectedCategory(name);
        }}
        sx={{
          // width: isMobile ? '100px' : '150px',
          // padding: '10px',
          // borderRadius: "16px",
          gap: "4px",
          // backgroundColor: selected === name ? foregroundColor : "white",
          // border: "1px solid #65656566",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          "&:hover": {
            borderBottom: `1px solid ${foregroundColor}`,
          },
        }}
      >
        <Typography
          sx={{
            fontSize: text2,
            fontWeight: 600,
            color: isSelected ? "white" : foregroundColor,
          }}
        >
          {name}
        </Typography>
      </Box>
    </>
  );
};

import { CircularProgress, Dialog, DialogContent } from "@mui/material";

const CustomLoader = () => (
  <Dialog
    open
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogContent>
      <CircularProgress />
    </DialogContent>
  </Dialog>
);
export default CustomLoader;

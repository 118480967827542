import { Divider, useMediaQuery, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import Box from "src/components/pages/landing-page/Box";
import Management3Card from "src/components/pages/landing-page/Management3Card";
import Management2Card from "src/components/pages/landing-page/Managment2Card";
import { getBestFitImage } from "src/lib/utils";
import { selectShopManagement } from "src/slices/landing-page/selectors";

export default function Management2({ deviceType }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const managements = useSelector(selectShopManagement);

  return (
    managements &&
    managements?.length > 0 && (
      <Box
        id="management"
        title="Management"
        subtitle=""
        sx={{
          backgroundColor: "white",
          paddingLeft: { xs: 2, sm: 4 },
          paddingRight: { xs: 2, sm: 4 },
          paddingBottom: 2,
        }}
      >
        <Box.BoxContainer type="flex" gap={0}>
          {managements.map((management, index) => {
            return (
              <>
                <Management3Card
                  key={management.id}
                  image={{
                    url:
                      getBestFitImage(deviceType, management.imageList)
                        ?.value ?? null,
                    alt: management.title,
                  }}
                  title={management.title}
                  subtitle1={management.info1}
                  subtitle2={management.info2}
                />
              </>
            );
          })}
        </Box.BoxContainer>
      </Box>
    )
  );
}

import {
  Typography,
  Box,
  Grid,
  Paper,
  Stack,
  Avatar,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectIsHomeLoading,
} from "src/slices/home/selectors";
import { AppDispatch } from "src/store";
import * as Yup from "yup";
import { selectCartState } from "src/slices/cart/selectors";
import { ICartState } from "src/slices/cart/types";
import { useNavigate } from "react-router-dom";
import { selectAppointmentState } from "src/slices/appointment/selectors";
import {
  AppointmentModel,
  AppointmentState,
} from "src/slices/appointment/types";
import { selectedAppointmentEmployee } from "src/slices/appointment/thunks";
import { useDisplaySettingsContext } from "src/contexts/DisplaySettings";

export const SelectEmployee = () => {
  const navigate = useNavigate();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { heading2, heading3, text1, text2, text3 } = useDisplaySettingsContext();

  const appointmentState: AppointmentState = useSelector(
    selectAppointmentState
  );

  const dispatch = useDispatch<AppDispatch>();

  const selectIsLoading = useSelector(selectIsHomeLoading);

  const [appointmentModel, setAppointmentModel] =
    useState<AppointmentModel>(null);
  useEffect(() => {
    setAppointmentModel(appointmentState.onCreationAppointment);
  }, []);

  return (
    <>
      {appointmentState.staffList ? (
        <Box
          sx={{
            width: isMobile ? "100%" : "50%",
            alignSelf: "center",
          }}
        >
          <Paper sx={{ p: 2 }}>
            <Stack alignItems="center">
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography fontSize={heading3}>
                    {appointmentState.serviceModel.servicename ?? "Not Loaded"}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography fontSize={text2}>Select Memeber</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography fontSize={text1}>
                    Select the available memeber to see available slot
                  </Typography>
                </Grid>
                {appointmentState.staffList.map((employeeData) => (
                  <Grid item xs={12}>
                    <Stack
                      direction="row"
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        dispatch(
                          selectedAppointmentEmployee({
                            selectedEmployee: employeeData,
                          })
                        ).then(() => {
                          navigate("/appointment-cart/booktime");
                        });
                      }}
                    >
                      <Avatar
                        alt="image"
                        src={employeeData!.photo1!}
                        sx={{ width: 56, height: 56, mr: 2 }}
                      />
                      <Stack direction="column" justifyContent="center">
                        <Typography fontSize={text3}>
                          {employeeData!.name === null
                            ? "No Name"
                            : employeeData!.name!.split("+_+").join(" ")}
                        </Typography>
                        <Typography fontSize={text3}>
                          {employeeData!.basicbio === null
                            ? "No Info"
                            : employeeData!.basicbio!}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Grid>
                ))}
              </Grid>
            </Stack>
          </Paper>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: "80vh",
            margin: "0 auto",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {selectIsLoading ? (
            <></>
          ) : (
            <Typography fontSize={heading2}>Please Add Data</Typography>
          )}
        </Box>
      )}
    </>
  );
};

import { createSlice } from "@reduxjs/toolkit";

import {
  fetchProductsForCategory,
  fetchShopProductFilterResult,
  fetchShopResult,
  getClassData,
  getSubcategories,
  getSubMenuCategories,
  webPageDataRequest,
} from "./thunks";

export const webpageDataSlice = createSlice({
  name: "webpageData",
  initialState: {
    data: null,
    isError: false,
    isLoading: false,
  },
  reducers: {
    setLastProductCallPayload: (state, action) => {
      state.data = {
        ...state.data,
        payload: action.payload,
      };
    },
    setAtypeCategory: (state, action) => {
      state.data = {
        ...state.data,
        wpd: {
          ...state.data.wpd,
          prodatypeinfo: {
            ...state.data.wpd.prodatypeinfo,
            selected: action.payload,
          },
        },
      };
    },
    setAtypeData: (state, action) => {
      state.data = {
        ...state.data,
        wpd: {
          ...state.data.wpd,
          prodatypeinfo: {
            ...state.data.wpd.prodatypeinfo,
            selected: action.payload.atype,
            catlst: action.payload,
          },
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(webPageDataRequest.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(webPageDataRequest.fulfilled, (state, action) => {
        state.isLoading = false;

        if (action.payload) {
          state.data = action.payload.data;
          if (action.payload?.data?.wpd?.prodatypeinfo?.atypelst?.length > 0) {
            state.data = {
              ...state.data,
              wpd: {
                ...state.data.wpd,
                prodatypeinfo: {
                  ...state.data.wpd.prodatypeinfo,
                  selected: state.data.wpd.prodatypeinfo?.catlst?.atype,
                  [state.data.wpd.prodatypeinfo?.catlst?.atype]:
                    state.data.wpd.prodatypeinfo?.catlst,
                },
              },
            };
          }
        }
      })
      .addCase(webPageDataRequest.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      });

    builder
      .addCase(getClassData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getClassData.fulfilled, (state, action) => {
        state.isLoading = false;

        if (action.payload) {
          state.data = {
            ...state.data,
            wpd: {
              ...state.data.wpd,
              gsit: { ...state.data.wpd.gsit, bci: action.payload.data.bci },
            },
          };
        }
      })
      .addCase(getClassData.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      });

    builder
      .addCase(getSubcategories.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSubcategories.fulfilled, (state, action) => {
        state.isLoading = false;

        if (action.payload) {
          state.data = {
            ...state.data,
            wpd: {
              ...state.data.wpd,
              prodatypeinfo: {
                ...state.data.wpd.prodatypeinfo,
                selected: action.payload.data.childcatdata.atype,
                catlst: action.payload.data.childcatdata,
                [action.payload.data.childcatdata.atype]:
                  action.payload.data.childcatdata,
              },
            },
          };
        }
      })
      .addCase(getSubcategories.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      });

    builder
      .addCase(getSubMenuCategories.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSubMenuCategories.fulfilled, (state, action) => {
        state.isLoading = false;

        if (action.payload) {
          const levels =
            action.payload?.data?.childcatdata?.rootcat?.split("/");
          let subMenuData = state.data.wpd.prodatypeinfo;
          for (let i = 0; i < levels.length; i++) {
            if (!subMenuData[levels[i]]) {
              subMenuData[levels[i]] = action.payload.data.childcatdata;
            }
            subMenuData = subMenuData[levels[i]];
          }

          // state.data = {
          //   ...state.data,
          //   wpd: {
          //     ...state.data.wpd,
          //     prodatypeinfo: {
          //       ...state.data.wpd.prodatypeinfo,
          //       subMenuData
          //       // [action.payload.data.childcatdata.rootcat]:
          //       //   action.payload.data.childcatdata.catlst,
          //     },
          //   },
          // };
        }
      })
      .addCase(getSubMenuCategories.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      });

    builder
      .addCase(fetchShopProductFilterResult.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchShopProductFilterResult.fulfilled, (state, action) => {
        state.isLoading = false;
        const payload: any = action.payload;

        if (!state?.data?.shopProducts || !payload.isAppendResult) {
          state.data = {
            ...state.data,
            shopProducts: payload.payload,
          };
        } else {
          for (let i = 1; i <= 5; i++) {
            if (state.data.shopProducts[`docwithdata${i}`]) {
              state.data.shopProducts[`docwithdata${i}`].push(
                ...payload.payload[`docwithdata${i}`]
              );
            }
          }
        }
      })
      .addCase(fetchShopProductFilterResult.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      });

    builder
      .addCase(fetchShopResult.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchShopResult.fulfilled, (state, action) => {
        state.isLoading = false;
        const payload: any = action.payload;

        if (!state?.data?.shops || !payload?.isAppendResult) {
          state.data = {
            ...state.data,
            shops: payload.payload,
          };
        } else {
          state.data = {
            ...state.data,
            shops: {
              smlist: [...state.data?.shops?.smlist, ...payload.payload.smlist],
            },
          };
        }
      })
      .addCase(fetchShopResult.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});

export const categoryProductsSlice = createSlice({
  name: "categoryProduct",
  initialState: {
    data: null,
    isError: false,
    isLoading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProductsForCategory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchProductsForCategory.fulfilled, (state, action) => {
        state.isLoading = false;

        if (action.payload) {
          state.data = action.payload.data;
        }
      })
      .addCase(fetchProductsForCategory.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});

export const { setAtypeData, setAtypeCategory, setLastProductCallPayload } =
  webpageDataSlice.actions;

export const { reducer: webpageDataReducer } = webpageDataSlice;
export const { reducer: categoryProductsReducer } = categoryProductsSlice;

import {
  Box,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Logo from "../../components/pages/landing-page/Logo";
import { Link } from "react-router-dom";
import Navbar from "../../components/pages/landing-page/navbar/Navbar";
import {
  Facebook,
  LocationOnOutlined,
  PhoneInTalk,
  Twitter,
} from "@mui/icons-material";
import Map from "src/components/Map";
import { useSelector } from "react-redux";
import { selectShopAbout } from "src/slices/landing-page/selectors";
import { useDisplaySettingsContext } from "src/contexts/DisplaySettings";

const footerNavItems = [
  { id: "poducts.header", title: "Products", href: "#products" },
  { id: "features.header", title: "Features", href: "#f" },
  {
    id: "shopData?.managementheader",
    title: "Management",
    href: "#management",
  },
  { id: "curriculum.header", title: "Curriculum", href: "#c" },
  { id: "members.header", title: "Members", href: "#m" },
  { id: "contact Us.header", title: "Contact Us", href: "#c" },
];

const otherPages = [
  { id: "Pricing", title: "Pricing", href: "#" },
  { id: "Security", title: "Security", href: "#" },
  { id: "Privacy Policy", title: "Privacy Policy", href: "#" },
  { id: "Curriculum", title: "Curriculum", href: "#" },
  { id: "Terms of use", title: "Terms of use", href: "#" },
];

export default function Footer() {
  const shopAbout = useSelector(selectShopAbout);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { text3, foregroundColor } = useDisplaySettingsContext();

  return (
    shopAbout && (
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          gap: "50px",
          backgroundColor: foregroundColor,
          color: "white",
          marginTop: 0,
          padding: { xs: 2, sm: 8 },
          justifyContent: "space-between",
        }}
      >
        <Stack sx={{ width: isMobile ? "100%" : "40%", gap: "10px" }}>
          <Logo
            title={shopAbout.name}
            href="/"
            image={shopAbout.logoImage}
            textAlign="left"
            titleSize={2}
          />
          <Stack
            direction={"row"}
            alignItems={"center"}
            gap={0.5}
            marginBottom={1}
          >
            <PhoneInTalk />
            <Typography color={"inherit"} sx={{fontSize: text3}}>{shopAbout.phoneNo}</Typography>
          </Stack>

          <Stack
            direction={"row"}
            alignItems={"center"}
            gap={0.5}
            marginBottom={1}
          >
            <LocationOnOutlined sx={{ fontSize: "1.5rem" }} />
            <Typography
              color={"inherit"}
              sx={{
                overflow: "hidden",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 2,
                fontSize: text3
              }}
            >
              {shopAbout.address.addressline}
            </Typography>
          </Stack>
          {/* <Stack gap={1} direction={"row"}>
            <Link
              to="https://www.facebook.com/"
              key="https://www.facebook.com/"
              style={{ color: "inherit" }}
            >
              <Facebook />
            </Link>
            <Link
              to="https://twitter.com/"
              key="https://twitter.com/"
              style={{ color: "inherit" }}
            >
              <Twitter />
            </Link>
          </Stack> */}
        </Stack>
        {/* </Grid> */}
        {/* <Grid item xs={6} sm> */}
        {/* <Stack sx={{ width: isMobile ? "100%" : "20%" }}>
          <Typography
            color={"inherit"}
            fontSize={heading3}
            fontWeight={700}
            marginBottom={1}
          >
            Pages
          </Typography>
          <Navbar
            navItems={footerNavItems}
            direction="column"
            alignItems="flex-start"
            gap={1}
          />
        </Stack> */}
        {/* <Stack sx={{ width: isMobile ? "100%" : "20%" }}>
          <Typography
            color={"inherit"}
            fontSize={heading3}
            fontWeight={700}
            marginBottom={1}
          >
            {shopAbout?.name}
          </Typography>
          <Navbar
            navItems={otherPages}
            direction="column"
            alignItems="flex-start"
            gap={1}
          />
        </Stack> */}
        <Stack sx={{ width: isMobile ? "100%" : "70%", borderRadius: '6px' }}>
          <Map
            width={"100%"}
            height={300}
            coordinates={{
              lat: shopAbout?.address.latitude,
              lng: shopAbout?.address.longitude,
            }}
          />
        </Stack>
      </Box>
    )
  );
}

import { Grid, List, ListItem, ListItemText, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { Product } from 'src/components/pages/home/product';
import { useDisplaySettingsContext } from 'src/contexts/DisplaySettings';
import { productData } from 'src/providers/product';

export function HomePageNew() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { text1, foregroundColor } = useDisplaySettingsContext();
  
  const [selectedSection, setSelectedSection] = useState<any>();
  const [selectedSectionType, setSelectedSectionType] =
    useState<string>("single");

  const [data, setData] = useState([]);

  useEffect(() => {
    const initSection = productData.servicelistshop[0];
    setSelectedSection(initSection);
    setSelectedSectionType(initSection.displaylist);
    setData(initSection.servicetypelist);
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        // mr: 4,
        pb: 4,
        backgroundColor: "white",
      }}
    >
      <Box
        sx={{
          ml: !isMobile && 4,
        }}
      >
        <List
          component={Stack}
          direction="row"
          sx={{
            padding: "5px",
            flexWrap: "wrap",
            justifyContent: "center",
            paddingTop: "30px",
          }}
        >
          {productData.servicelistshop.map((section, index) => (
            <ListItem
              sx={{
                bgcolor: section === selectedSection ? foregroundColor : "white",
                cursor: "pointer",
                border: "1px solid #65656566",
                m: "5px",
                textAlign: "center",

                width: isMobile ? "100px" : "184px",
                height: isMobile ? "50" : "64px",
                padding: "16px 24px 16px 24px",
                borderRadius: "16px",
                gap: "16px",
              }}
              onClick={() => {
                setSelectedSection(section);
                setSelectedSectionType(section.displaylist);
                setData(section.servicetypelist);
              }}
            >
              <ListItemText
                primaryTypographyProps={{ fontSize: "12px" }}
                sx={{
                  color: section === selectedSection ? "white" : foregroundColor,
                }}
                primary={section.sectionname}
              />
            </ListItem>
          ))}
        </List>
      </Box>

      <Box sx={{ ml: !isMobile && 2 }}>
        {selectedSectionType == "single" ? (
          <Box sx={{ width: 1 }}>
            <Grid
              container
              spacing={2}
              sx={{ m: 0 }}
              justifyContent="center"
              textAlign="center"
            >
              <Grid item xs={12}>
                <Typography fontSize={text1}>
                  {selectedSection?.sectionname ?? ""}
                </Typography>
              </Grid>
              {data.map((data: any) => (
                <Grid item>
                  <Product
                    data={data}
                    sectionname={selectedSection.sectionname}
                    isShop={selectedSection.type === "shop"}
                    type={selectedSection.type}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        ) : (
          <Box>
            {data.map((data: any) => (
              <Grid
                container
                spacing={2}
                sx={{ m: 0 }}
                justifyContent="center"
                textAlign="center"
              >
                <>
                  <Grid item xs={12}>
                    <Typography fontSize={text1}>{data.servicetype}</Typography>
                  </Grid>
                  {data.childservicetype.map((subData) => (
                    <Grid item>
                      <Product
                        data={subData}
                        sectionname={selectedSection.sectionname}
                        isShop={selectedSection.type === "shop"}
                        type={selectedSection.type}
                      />
                    </Grid>
                  ))}
                </>
              </Grid>
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
}

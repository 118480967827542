import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  createUserWithEmailAndPassword,
  signInAnonymously,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { collection, doc, getDocs, query, where } from "firebase/firestore";
import { auth, db } from "src/configs/firebase";

import { setEndUserClusterInfo } from ".";
import { toastMessage } from "../commonSlice";
import {
  IAuthPayload,
  MemberRegisterRequestModel,
  SignUpRequest,
  StaffRegisterRequestModel,
  StudentRegisterRequestModel,
  ValidateOTPRequestModel,
  VendorRegisterRequestModel,
} from "./types";
import { sendRequestJSON } from "src/lib/axios";
import { BaseUrl } from "src/constants";
import { selectLsm } from "../landing-page/selectors";

export const loginUser = createAsyncThunk(
  "auth/loginUser",
  async (loginPayload: IAuthPayload, { dispatch }) => {
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        loginPayload.email,
        loginPayload.password
      );
      return userCredential.user;
    } catch (error) {
      console.log(error);
      dispatch(
        toastMessage({
          error: true,
          message: error.message,
        })
      );
      return null;
    }
  }
);

export const loginAnonymously = createAsyncThunk(
  "auth/loginUser",
  async (_, { dispatch }) => {
    try {
      const userCredential = await signInAnonymously(auth);
      return userCredential.user;
    } catch (error) {
      console.log(error);
      dispatch(
        toastMessage({
          error: true,
          message: error.message,
        })
      );
      return null;
    }
  }
);

export const signupUser = createAsyncThunk(
  "auth/signup",
  async (loginPayload: IAuthPayload, { dispatch }) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        loginPayload.email,
        loginPayload.password
      );
      return userCredential.user;
    } catch (error) {
      console.log(error);
      dispatch(
        toastMessage({
          error: true,
          message: error.message,
        })
      );
      return null;
    }
  }
);

export const fetchClusterInfo = createAsyncThunk(
  "auth/fetchClusterInfo",
  async (state: string, { dispatch, getState }) => {
    const loginState = (getState() as any).loginInformation;
    let enduserclusterInfoMap: any = {};

    const country = "INDIA"; // Replace with the actual country
    // const state = 'your_state'; // Replace with the actual state

    if (loginState.endUserClusterInfo[country + "#" + state]) {
      // setEndUserClusterInfo(GlobalBlocAccessor.enduserclusterInfoMap[country + '#' + state]);
    } else {
      try {
        const querySnapshot = await getDocs(
          query(
            collection(db, "ENDUSERCLUSTERINFO"),
            where("country", "==", country),
            where("state", "array-contains", state.toUpperCase())
          )
        );

        if (!querySnapshot.empty) {
          querySnapshot.forEach((doc) => {
            let data = doc.data();
            if (data.adata) {
              Object.keys(data.adata).forEach((key) => {
                data[key] = data.adata[key];
              });
            }
            let clusterInfo: any = data;

            if (clusterInfo.state) {
              clusterInfo.state.forEach((s: string) => {
                enduserclusterInfoMap[`${clusterInfo.country}#${s}`] =
                  clusterInfo;
              });
            }
            dispatch(setEndUserClusterInfo(enduserclusterInfoMap));
            return clusterInfo;
          });
        } else {
          // dispatch(setEndUserClusterInfo(null));
          return null;
        }
      } catch (error) {
        console.error("Error fetching cluster info:", error);
        // dispatch(setEndUserClusterInfo(null));
        return null;
      }
    }
  }
);

// export const fetchEndUserClusterInfoSP = createAsyncThunk(
//   "auth/fetchEndUserClusterInfoSP",
//   async (clustername: string, { dispatch, getState }) => {
//     const loginState = (getState() as any).loginInformation;
//     let endUserClusterInfoSPMap: any = {};

//     const country = "INDIA"; // Replace with the actual country
//     // const state = 'your_state'; // Replace with the actual state

//     if (loginState.endUserClusterInfoSP[country + "#" + state]) {
//       // setEndUserClusterInfo(GlobalBlocAccessor.enduserclusterInfoMap[country + '#' + state]);
//     } else {
//       try {
//         const querySnapshot = await getDoc(doc(db, "ENDUSERCLUSTERINFOSP", clustername));

//         if (!querySnapshot.empty) {
//           querySnapshot.forEach((doc) => {
//             let data = doc.data();
//             if (data.adata) {
//               Object.keys(data.adata).forEach((key) => {
//                 data[key] = data.adata[key];
//               });
//             }
//             let clusterInfo: any = data;

//             if (clusterInfo.state) {
//               clusterInfo.state.forEach((s: string) => {
//                 endUserClusterInfoSPMap[`${clusterInfo.country}#${s}`] =
//                   clusterInfo;
//               });
//             }
//             dispatch(setEndUserClusterInfoSP(endUserClusterInfoSPMap));
//             return clusterInfo;
//           });
//         } else {
//           // dispatch(setEndUserClusterInfo(null));
//           return null;
//         }
//       } catch (error) {
//         console.error("Error fetching cluster info:", error);
//         // dispatch(setEndUserClusterInfo(null));
//         return null;
//       }
//     }
//   }
// );

export const userProfileCreationRequest = createAsyncThunk(
  "auth/userProfileCreationRequest",
  async (signupRequest: SignUpRequest, { dispatch }) => {
    try {
      const response = await sendRequestJSON(
        {
          data: {
            ugm: {
              signupreq: signupRequest,
              action: "add",
              tzoffset: Math.abs(new Date().getTimezoneOffset() * 60),
            },
            qrytype: "USERDBA",
          },
        },
        "",
        "post",
        BaseUrl.digital
      );
      return response.result.data;
    } catch (error) {
      console.log(error);
      dispatch(
        toastMessage({
          error: true,
          message: error.message,
        })
      );
      return null;
    }
  }
);

export const initiateOTPFlowRequest = createAsyncThunk(
  "auth/initiateOTPFlowRequest",
  async (uid: string, { dispatch }) => {
    try {
      const response = await sendRequestJSON(
        {
          data: {
            ugm: {
              uid: uid,
              action: "initiateotp",
              tzoffset: Math.abs(new Date().getTimezoneOffset() * 60),
            },
            qrytype: "USERDBA",
          },
        },
        "",
        "post",
        BaseUrl.digital
      );
      return response.result.data;
    } catch (error) {
      console.log(error);
      dispatch(
        toastMessage({
          error: true,
          message: error.message,
        })
      );
      return null;
    }
  }
);

export const validateOTPRequest = createAsyncThunk(
  "auth/validateOTPRequest",
  async (payload: ValidateOTPRequestModel, { dispatch }) => {
    try {
      const response = await sendRequestJSON(
        {
          data: {
            ugm: {
              uid: payload.uid,
              genstr: payload.otpn,
              action: "initiateotp",
              tzoffset: Math.abs(new Date().getTimezoneOffset() * 60),
            },
            qrytype: "USERDBA",
          },
        },
        "",
        "post",
        BaseUrl.digital
      );
      return response.result.data;
    } catch (error) {
      console.log(error);
      dispatch(
        toastMessage({
          error: true,
          message: error.message,
        })
      );
      return null;
    }
  }
);

export const getVerificationCode = createAsyncThunk(
  "auth/getVerificationCode",
  async (_, { dispatch, getState }) => {
    const state = getState() as any;
    const loginState = state.loginInformation;
    const lsm = selectLsm(state);

    try {
      const response = await sendRequestJSON(
        {
          data: {
            tripreq: {
              entitytype: "SERVICEPROVIDERINFO",
              serviceid: lsm?.serviceID,
              // id: null,
              action: "getinitialdataenduser",
              // endcursor: null,
              // identifier1: null,
              uid: loginState?.user?.uid,
            },
            qrytype: "SERVICEMEMBERQRY",
          },
        },
        "",
        "post",
        BaseUrl.digital
      );
      return response.result.data;
    } catch (error) {
      console.log(error);
      dispatch(
        toastMessage({
          error: true,
          message: error.message,
        })
      );
      return null;
    }
  }
);

// GET USER BY ID
export const getUserById = createAsyncThunk(
  "auth/getUserById",
  async (_, { dispatch, getState }) => {
    const state = getState() as any;
    const loginState = state.loginInformation;
    const lsm = selectLsm(state);

    try {
      const response = await sendRequestJSON(
        {
          data: {
            ugm: {
              uid: loginState?.user?.uid,
              action: "getbyid",
              chslist: null,
              tzoffset: Math.abs(new Date().getTimezoneOffset() * 60),
            },
            qrytype: "USERDBA",
          },
        },
        "",
        "post",
        BaseUrl.digital
      );
      return response.result.data;
    } catch (error) {
      console.log(error);
      dispatch(
        toastMessage({
          error: true,
          message: error.message,
        })
      );
      return null;
    }
  }
);

// Member
export const registerMember = createAsyncThunk(
  "auth/registerMember",
  async (payload: MemberRegisterRequestModel, { dispatch, getState }) => {
    const state = getState() as any;
    const loginState = state.loginInformation;
    const lsm = selectLsm(state);

    let object = {
      data: {
        tripreq: {
          serviceuser: {
            docid: "",
            name: loginState?.user?.displayName,
            phone: [payload.phone],
            email: [loginState?.user?.email],
            cattype: payload?.cattype,
            catinfo: [],
            membertype: "INDIVIDUAL",
            dateofjoin: parseInt(loginState?.user?.createdAt),
            isauthorized: true,
            hasdepend: false,
            grpmemberlist: [],
            cfrm: true,
            dob: payload?.dob,
            customeruserid: [loginState?.user?.uid],
            imgtile: "",
          },
          entitytype: "SERVICEPROVIDERINFO",
          serviceid: lsm?.serviceID,
          reqsm: false,
        },
        qrytype: "SERVICEMEMBERENDUSER",
      },
    };
    if (payload?.dependents) {
      object = {
        data: {
          tripreq: {
            serviceuser: {
              docid: "",
              name: loginState?.user?.displayName,
              phone: [payload.phone],
              email: [loginState?.user?.email],
              cattype: payload?.cattype,
              catinfo: [],
              membertype: "INDIVIDUAL",
              dateofjoin: parseInt(loginState?.user?.createdAt),
              isauthorized: true,
              hasdepend: true,
              grpmemberlist: payload?.dependents,
              cfrm: true,
              dob: payload?.dob,
              customeruserid: [loginState?.user?.uid],
              imgtile: "",
            },
            entitytype: "SERVICEPROVIDERINFO",
            serviceid: lsm?.serviceID,
            reqsm: false,
          },
          qrytype: "SERVICEMEMBERENDUSER",
        },
      };
    }

    try {
      const response = await sendRequestJSON(
        object,
        "",
        "post",
        BaseUrl.digital
      );
      return response.result.data;
    } catch (error) {
      console.log(error);
      dispatch(
        toastMessage({
          error: true,
          message: error.message,
        })
      );
      return null;
    }
  }
);

// Staff
export const registerStaff = createAsyncThunk(
  "auth/registerStaff",
  async (payload: StaffRegisterRequestModel, { dispatch, getState }) => {
    const state = getState() as any;
    const loginState = state.loginInformation;
    const lsm = selectLsm(state);

    try {
      const response = await sendRequestJSON(
        {
          data: {
            tripreq: {
              serviceuser: {
                docid: "",
                name: loginState?.user?.displayName,
                phone: [payload.phone],
                email: [loginState?.user?.email],
                cattype: payload?.cattype,
                catinfo: [],
                membertype: "INDIVIDUAL",
                dateofjoin: parseInt(loginState?.user?.createdAt),
                isauthorized: true,
                hasdepend: false,
                grpmemberlist: [],
                cfrm: true,
                sex: "",
                dob: payload?.dob,
                customeruserid: [loginState?.user?.uid],
                imgtile: "",
              },
              entitytype: "SERVICEPROVIDERINFO",
              serviceid: lsm?.serviceID,
              reqsm: false,
            },
            qrytype: "SERVICEMEMBERENDUSER",
          },
        },
        "",
        "post",
        BaseUrl.digital
      );
      return response.result.data;
    } catch (error) {
      console.log(error);
      dispatch(
        toastMessage({
          error: true,
          message: error.message,
        })
      );
      return null;
    }
  }
);

// School
export const registerStudentGetList = createAsyncThunk(
  "auth/registerStudentGetList",
  async (_, { dispatch, getState }) => {
    const state = getState() as any;
    const lsm = selectLsm(state);

    try {
      const response = await sendRequestJSON(
        {
          data: {
            gm: {
              action: "getstrlist",
              entitytype: "SERVICEPROVIDERINFO",
              entityid: lsm?.serviceID,
            },
            qrytype: "SESSIONTERMDBA",
          },
        },
        "",
        "post",
        BaseUrl.digital
      );
      return response.result.data;
    } catch (error) {
      console.log(error);
      dispatch(
        toastMessage({
          error: true,
          message: error.message,
        })
      );
      return null;
    }
  }
);

export const registerStudent = createAsyncThunk(
  "auth/registerStudent",
  async (payload: StudentRegisterRequestModel, { dispatch, getState }) => {
    const state = getState() as any;
    const loginState = state.loginInformation;
    const lsm = selectLsm(state);

    try {
      const response = await sendRequestJSON(
        {
          data: {
            tripreq: {
              serviceuser: {
                docid: "",
                sesterm: payload.sessionTerm,
                name: loginState?.user?.displayName,
                phone: [payload.phone],
                email: [loginState?.user?.email],
                cattype: payload?.cattype,
                catinfo: payload.studentIds,
                membertype: "INDIVIDUAL",
                dateofjoin: parseInt(loginState?.user?.createdAt),
                isauthorized: true,
                hasdepend: false,
                grpmemberlist: [],
                cfrm: true,
                // dob: payload?.dob,
                customeruserid: [loginState?.user?.uid],
                imgtile: "",
              },
              entitytype: "SERVICEPROVIDERINFO",
              serviceid: lsm?.serviceID,
              reqsm: false,
            },
            qrytype: "SERVICEMEMBERENDUSER",
          },
        },
        "",
        "post",
        BaseUrl.digital
      );
      return response.result.data;
    } catch (error) {
      console.log(error);
      dispatch(
        toastMessage({
          error: true,
          message: error.message,
        })
      );
      return null;
    }
  }
);

// Vendor
export const registerVendor = createAsyncThunk(
  "auth/registerVendor",
  async (payload: VendorRegisterRequestModel, { dispatch, getState }) => {
    const state = getState() as any;
    const loginState = state.loginInformation;
    const lsm = selectLsm(state);

    try {
      const response = await sendRequestJSON(
        {
          data: {
            tripreq: {
              action: "subscribe",
              groupSubsInfo: {
                reqas: "VENDOR",
                entityid: payload?.entityid,
                entityname: payload?.entityname,
              },
              entitytype: "SERVICEPROVIDERINFO",
              serviceid: lsm?.serviceID,
            },
            qrytype: "SERVICEMEMBERGRP",
          },
        },
        "",
        "post",
        BaseUrl.digital
      );
      return response.result.data;
    } catch (error) {
      console.log(error);
      dispatch(
        toastMessage({
          error: true,
          message: error.message,
        })
      );
      return null;
    }
  }
);

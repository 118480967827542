import { createAction, createSlice, isAnyOf } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { SpinnerState } from "../types/spinner";
import { Slices } from "../constants";

const initialState: SpinnerState = {
  isLoading: false,
  isError: false,
  successMessage: "",
  errorMessage: "",
  generalMessage: {},
  infoMessage: "",
  infoMessageOpen: false,
  infoMessageError: false,
  infoMessageInformation: false,
  okButtonHandler: null,
  cancelButtonHandler: null,
};

const infoBoxNew = createAction(`${Slices.COMMON_REDUCER}/toastMessage`);

const slice = createSlice({
  name: Slices.COMMON_REDUCER,
  initialState: { ...initialState },

  reducers: {
    setLoading(state: SpinnerState, action: PayloadAction<any>): void {
      state.isLoading = action.payload;
    },
    toastMessageInfoBoxRemove(state: SpinnerState): void {
      state.infoMessage = "";
      state.infoMessageOpen = false;
      state.infoMessageError = false;
      state.infoMessageInformation = false;
      state.okButtonHandler = null;
      state.cancelButtonHandler = null;
    },
    toastMessage(state: SpinnerState, action: PayloadAction<any>): void {
      const actionpayload = action.payload;
      console.log("infoMessage ::", actionpayload);
      const name = actionpayload.message.split(" ").join();
      if (actionpayload.error) {
        state.generalMessage[name] = {
          open: true,
          error: true,
          message: actionpayload.message,
        };
        // state.errorMessage = actionpayload.message;
      } else {
        state.generalMessage[name] = {
          open: true,
          error: false,
          message: actionpayload.message,
        };
        // state.successMessage = actionpayload.message;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(infoBoxNew),
      (state: any, action: PayloadAction<any>) => {
        const actionpayload = action.payload;

        state.infoMessage = actionpayload.message;
        state.infoMessageOpen = true;
        state.infoMessageError = actionpayload.error
          ? actionpayload.error
          : false;
        state.infoMessageInformation = actionpayload.info
          ? actionpayload.info
          : false;
        state.okButtonHandler = actionpayload.okButtonHandler
          ? actionpayload.okButtonHandler
          : null;
        state.cancelButtonHandler = actionpayload.cancelButtonHandler
          ? actionpayload.cancelButtonHandler
          : null;
      }
    );
  },
});

export const { setLoading, toastMessageInfoBoxRemove, toastMessage } =
  slice.actions;
export const { reducer } = slice;

/* eslint-disable */
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
declare global {
    // eslint-disable-next-line no-var
    var FIREBASE_APPCHECK_DEBUG_TOKEN: boolean | string | undefined;
  }


// OLD DB
// const firebaseConfig = {
//   apiKey: "AIzaSyA4xHrCYttvqMHAh1nbtO30F7Y3eBn3deE",
//   authDomain: "brindavan-c61b7.firebaseapp.com",
//   databaseURL: "https://brindavan-c61b7.firebaseio.com",
//   projectId: "brindavan-c61b7",
//   storageBucket: "brindavan-c61b7.appspot.com",
//   messagingSenderId: "154238857434",
//   appId: "1:154238857434:web:c6a5db2ecd758ad05cb5e3",
//   measurementId: "G-8K7TT62N9R"
// };

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// NEW DB
const firebaseConfig = {
  apiKey: "AIzaSyD_5TEupGlHCUXHS5X-nJKRXNI0rN0_dck",
  authDomain: "vthepeople-2e59a.firebaseapp.com",
  projectId: "vthepeople-2e59a",
  storageBucket: "vthepeople-2e59a.appspot.com",
  messagingSenderId: "978713006905",
  appId: "1:978713006905:web:4c51151d4a0120329f4ee8",
  measurementId: "G-1CZ7GMC6XZ"
};

// TEST WAQAR
// const firebaseConfig = {
//   apiKey: "AIzaSyCJu2oTh44G-mainSZbSOZ6ySKe_iTIwtc",
//   authDomain: "v-the-people-test.firebaseapp.com",
//   projectId: "v-the-people-test",
//   storageBucket: "v-the-people-test.appspot.com",
//   messagingSenderId: "96734538890",
//   appId: "1:96734538890:web:a743e4054bc9ef10635173",
// };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

self.FIREBASE_APPCHECK_DEBUG_TOKEN = false;
// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider("6LcPbG8pAAAAAHmZkiHoRczWimkiUAstHtEZZtfe"),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true
});

// Initialize Firebase Authentication and get a reference to the service
export const db = getFirestore(app);
export const mychatdb1 = getFirestore(app, "mychatdb1");
export const auth = getAuth(app);
export default app;

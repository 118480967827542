import { useSelector } from "react-redux";
import { selectShopHeroContent } from "src/slices/landing-page/selectors";
import { getBestFitImage, getBestFitImageForHero } from "src/lib/utils";
import Banner from "src/components/pages/landing-page/banner";
import Slider from "src/components/pages/landing-page/slider";
import { DEVICE_TYPES } from "src/constants";
import { useEffect, useState } from "react";

export default function Hero({ deviceType, setHeroImages }) {
  const heroData = useSelector(selectShopHeroContent);
  const [slides, setSlides] = useState([]);

  useEffect(() => {
    if (!heroData) return;
    const filteredData = heroData?.imageList?.filter(
      (image) => image?.ssize?.toUpperCase() === deviceType
    );
    let slidesList = filteredData?.map((image) => {
      const bestFitImage = getBestFitImageForHero(image.difresimglst);

      return {
        image: bestFitImage?.value,
        alt: bestFitImage?.imgname,
        title: image?.title,
        subtitle: image?.info1 || image?.info2,
      };
    });
    if (slidesList?.length > 0) {
      setSlides(slidesList);
    } 
    // else {
    //   const slidesList = heroData.imageList.map((image) => {
    //     const bestFitImage = getBestFitImage(
    //       DEVICE_TYPES.DESKTOP,
    //       image.difresimglst
    //     );

    //     return {
    //       image: bestFitImage?.value,
    //       alt: bestFitImage?.imgname,
    //       title: image?.title,
    //       subtitle: image?.info1 || image?.info2,
    //     };
    //   });
    //   setSlides(slidesList);
    // }
  }, [heroData, deviceType]);

  useEffect(()=>{
    setHeroImages(slides);
  },[slides]);


  if (!heroData) {
    return null;
  }
  return heroData.type === "slider" && slides?.length > 0 ? (
    <Slider containerHeight="300px" slides={slides} />
  ) : (
    <Banner
      title={heroData.imageList.title}
      subtitle={heroData.imageList.info1}
      alt={heroData.imageList.title}
      image={getBestFitImage("orignal", heroData.imageList.differentSizeImages)}
    />
  );
}

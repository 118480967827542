import { Chat } from "@mui/icons-material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import SpeedIcon from "@mui/icons-material/Speed";
import {
  Box,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { auth } from "src/configs/firebase";
import { useDisplaySettingsContext } from "src/contexts/DisplaySettings";
import {
  selectRoomListState,
  selectcmdictState,
} from "src/slices/chat/selectors";
import { toastMessage } from "src/slices/commonSlice";
import { selectLsm } from "src/slices/landing-page/selectors";
import { AppDispatch } from "src/store";

export const VehicleListItem: FC<any> = ({ vehicle }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { heading3, text2, text3 } = useDisplaySettingsContext();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const roomlist = useSelector(selectRoomListState);
  const cmdict = useSelector(selectcmdictState);

  const lsm = useSelector(selectLsm);

  const checkifchannelexist = (pid, metaid) => {
    var mlst = roomlist;

    for (var v of mlst) {
      if (v.isindi && v.metaid === metaid && v.pid === pid) {
        return v;
      }
    }
  };
  const goToChatRoomClassified = (vehicle) => {
    console.log(vehicle);
    if (!vehicle.crid) {
      dispatch(
        toastMessage({
          error: true,
          message: "Service is not supporting chat",
        })
      );
      return;
    }

    const finaldocid =
      vehicle.productid! +
      "#" +
      (vehicle?.serviceproviderid ?? vehicle?.userid ?? "NotDefined");

    let channel = checkifchannelexist(finaldocid, vehicle.crid!.split("@")[0]);
    let ccr = {
      pid: finaldocid,
      cid: vehicle.crid!.split("@")[0],
      serviceID: vehicle.serviceproviderid,
      prodid: finaldocid,
      title: vehicle.title ?? "",
      image: vehicle.tileimage,
      appname: vehicle.dbapp,
      crid: vehicle.crid,
    };

    if (!channel) {
      navigate("/chat", {
        state: {
          cmagg: null,
          croi: null,
          fromChatlist: false,
          username: auth.currentUser?.displayName ?? "",
          uid: auth.currentUser?.uid ?? "",
          newchatreq: ccr,
        },
      });
    } else {
      let cmm = cmdict[channel.metaid];
      if (!cmm) {
        dispatch(
          toastMessage({
            error: true,
            message: "No Chat room configured",
          })
        );
        return;
      }
      let mc = {
        cm: cmm.cm,
        forroom: {
          rid: channel.rid,
          isact: cmm.listeneractive,
          pid: channel.pid,
          rkey: channel.rkey,
        },
        isindi: channel.isindi,
        metaid: channel.metaid,
        toconsider: true,
        info: false,
        defaultactivated: cmm.listeneractive ? "T" : "F",
        listeneractive: !cmm.userdeactivated ? "T" : "F",
      };
      navigate("/chat", {
        state: {
          cmagg: mc,
          croi: channel.lastopeninfo,
          fromChatlist: true,
          username: auth.currentUser?.displayName ?? "",
          uid: auth.currentUser?.uid ?? "",
          newchatreq: null,
        },
      });
    }
  };

  const KeyValue = ({ title, value }) => {
    return (
      <>
        <Grid item xs={6}>
          <Typography sx={{ fontSize: text2, fontWeight: "bold" }}>
            {title}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography sx={{ fontSize: text2 }}>{value}</Typography>
        </Grid>
      </>
    );
  };

  return (
    <Box
      key={vehicle.productid}
      onClick={() => {
        let path = `vehicle-details?productid=${vehicle.productid}&serviceid=${vehicle.serviceproviderid}&userid=${vehicle.userid}`;
        navigate(lsm ? "vehicle/" + path : path);
      }}
      sx={{
        padding: "10px",
        wordBreak: "break-word",
        background: "white",
        borderRadius: "10px",
        "&:hover": {
          border: "1px solid #000000",
          cursor: "pointer",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
        }}
      >
        <Box
          minHeight={isMobile ? "100px" : "150px"}
          minWidth={isMobile ? "100px" : "150px"}
          sx={{
            borderRadius: "5px",
            marginRight: "10px",
            backgroundImage: `url(${vehicle.tileimage})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "contain",
          }}
        ></Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Typography
            sx={{
              fontSize: heading3,
              fontWeight: "bold",
              color: "primary.main",
            }}
          >
            {vehicle.title}
          </Typography>
          <Grid container>
            <KeyValue title="Make" value={vehicle.make} />
            <KeyValue title="Type" value={vehicle.vehicletype} />
            <KeyValue title="Model" value={vehicle.model} />
            <Grid xs={12}>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  goToChatRoomClassified(vehicle);
                }}
              >
                <Chat />
              </IconButton>
            </Grid>
          </Grid>
          <Grid container sx={{ mt: 2 }}>
            <Grid item xs={6}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <CalendarTodayIcon sx={{ color: "primary.main", mr: 1 }} />
                <Typography sx={{ fontSize: text3 }}>
                  {vehicle.yearmade}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <SpeedIcon sx={{ color: "primary.main", mr: 1 }} />
                <Typography sx={{ fontSize: text3 }}>
                  {vehicle.milage}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { Slices } from "../constants";

export const ProtectedRoute = ({ children }) => {
  const { isLoggedIn } = useSelector(
    (state: any) => state[Slices.LOGIN_INFORMATION]
  );
  if (!isLoggedIn) {
    // user is not authenticated
    return <Navigate to="/login" />;
  }
  return children;
};

import { Paper, Grid } from "@mui/material";
import { Form, Formik } from "formik";
import CTypography from "src/components/CTypography";
import FormikControl from "src/components/formik/FormikControl";
import { useDisplaySettingsContext } from "src/contexts/DisplaySettings";

export const RentalSpListWithFilter = ({ formikRef }) => {

  const { text3 } = useDisplaySettingsContext();

  // Form Inital Values
  const formInitialValue = {
    startDate: new Date(Date.now()),
    endDate: new Date(Date.now()),
    // numRoom: "",
  };

  return (
    <Paper elevation={0} sx={{ p: 0, display: "flex", alignSelf: "center" }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Formik
            initialValues={formInitialValue}
            innerRef={formikRef}
            onSubmit={() => {}}
          >
            {({ touched, errors, getFieldProps }) => (
              <Form>
                <Grid container spacing={1} pb={1}>
                  <Grid item xs={12} md={6}>
                    <FormikControl
                      control="CalendarTime"
                      label="Start Date"
                      dateOrTimeOnly="date"
                      name="startDate"
                      error={Boolean(touched.startDate && errors.startDate)}
                      helperText={touched.startDate && errors.startDate}
                      {...getFieldProps("startDate")}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormikControl
                      control="CalendarTime"
                      label="End Date"
                      dateOrTimeOnly="date"
                      name="endDate"
                      error={Boolean(touched.endDate && errors.endDate)}
                      helperText={touched.endDate && errors.endDate}
                      {...getFieldProps("endDate")}
                    />
                  </Grid>

                  {/* {atype === "HOTELROOM" && (
                    <>
                      <Grid item xs={12} sm={6}>
                        <FormikControl
                          control="InputField"
                          name="numRoom"
                          label={"Num Room"}
                          error={Boolean(touched.numRoom && errors.numRoom)}
                          helperText={touched.numRoom && errors.numRoom}
                          {...getFieldProps("numRoom")}
                        />
                      </Grid>
                    </>
                  )} */}
                </Grid>
                <Grid item xs={12}>
                  <CTypography
                    sx={{
                      color: "#1E1E1E",
                      fontSize: text3,
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                    }}
                  >
                    * Please select Start and End dates for Booking
                  </CTypography>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Paper>
  );
};

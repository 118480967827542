import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../store";

const selectSelf = (state: RootState) => state;
const selectUserPanelState = createSelector(
  selectSelf,
  (state) => state?.userPanel
);

export const selectIsUserPanelLoading = createSelector(
  selectUserPanelState,
  (userPanel) => userPanel?.isLoading
);

export const selectAttendances = createSelector(
  selectUserPanelState,
  (userPanel) => userPanel?.attendances
);

export const selectExamTerms = createSelector(
  selectUserPanelState,
  (userPanel) => userPanel?.examTerms
);

export const selectProgress = createSelector(
  selectUserPanelState,
  (userPanel) => userPanel?.progress
);

export const selectEvents = createSelector(
  selectUserPanelState,
  (userPanel) => userPanel?.events
);

export const selectAssignments = createSelector(
  selectUserPanelState,
  (userPanel) => userPanel?.assignments
);
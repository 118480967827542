import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Rating from '@mui/material/Rating';
import TextField from '@mui/material/TextField';
import React, { useState } from 'react';
import { useDisplaySettingsContext } from 'src/contexts/DisplaySettings';

const RatingDialog = ({ open, setOpen, onRatingSubmitted }) => {
  //   const [open, setOpen] = useState(false);
  const { textColor } = useDisplaySettingsContext();
  const [rating, setRating] = useState(1);
  const [comment, setComment] = useState("");

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    // Perform any additional actions before submitting the rating.
    // You can call an API here to submit the rating and comment.
    const response = {
      rating: rating,
      comment: comment,
    };

    onRatingSubmitted(response);

    // Close the dialog
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Rate Order</DialogTitle>
      <DialogContent>
        <Rating
          value={rating}
          onChange={(event, newValue) => {
            setRating(newValue);
          }}
          size="large"
        />
        <TextField
          label="Comment"
          multiline
          rows={4}
          variant="outlined"
          fullWidth
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          sx={{
            color: textColor,
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          sx={{
            color: textColor,
          }}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RatingDialog;

import { FC } from "react";
import PropTypes from "prop-types";
import { Typography, TypographyProps } from "@mui/material";

interface CustomTypographyProps extends TypographyProps {
  children?: any;
  component?: string;
}

const CTypography: FC<CustomTypographyProps> = (props) => {
  const { children, ...rest } = props;
  return <Typography {...rest}>{children}</Typography>;
};

CTypography.propTypes = {
  children: PropTypes.string,
  component: PropTypes.string,
};
export default CTypography;

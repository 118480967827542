import { Paper } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useDisplaySettingsContext } from 'src/contexts/DisplaySettings';

const OrderCard = ({
  label1,
  value1,
  label2,
  value2,
  label3,
  value3,
  label4,
  value4,
  total = "",
  onLoadPdf = null,
  onRate = null,
  showratebutton = false,
  tapEvent = () => {},
}) => {
  const { text1, text3, textColor } = useDisplaySettingsContext();

  return (
    <Paper
      sx={{
        p: 2,
        mt: 3,
        backgroundColor: "white",
        borderRadius: 4,
      }}
    >
      <div
        onClick={() => {
          tapEvent();
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            <OrderLabelValue label={label1} value={value1} />
            <Box mt={3}>
              <OrderLabelValue label={label3} value={value3} />
            </Box>
          </div>
          <div>
            <OrderLabelValue label={label2} value={value2} />
            <Box mt={3}>
              <OrderLabelValue label={label4} value={value4} />
            </Box>
          </div>
        </div>
      </div>
      {total && total !== "" && (
        <Typography
          fontSize={text1}
          component="div"
          mt={2}
          sx={{
            color: "#1976D2",
            fontWeight: 600,
          }}
        >
          Total Amount: {total}
        </Typography>
      )}
      {(onLoadPdf || onRate) && (
        <Box
          mt={1}
          mb={0.5}
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          {onRate && (
            <Button
              variant="contained"
              onClick={() => {
                onRate();
              }}
              sx={{ marginRight: 1, fontSize: text3, color: textColor }}
            >
              Rate
            </Button>
          )}
          {onLoadPdf && (
            <Button
              variant="contained"
              onClick={() => {
                onLoadPdf();
              }}
              sx={{ fontSize: text3, color: textColor }}
            >
              Generate PDF
            </Button>
          )}
        </Box>
      )}
    </Paper>
  );
};

const OrderLabelValue = ({ label, value }) => {
  const { text2 } = useDisplaySettingsContext();

  return (
    <div>
      <Typography
        fontSize={text2}
        sx={{
          color: "#1976D2",
          fontWeight: 600,
        }}
      >
        {label}
      </Typography>
      <Typography fontSize={text2} fontWeight="600">
        {value}
      </Typography>
    </div>
  );
};

export default OrderCard;

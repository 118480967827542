import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import InfoIcon from '@mui/icons-material/Info';
import { Box, Button, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as CONSTANTS from 'src/constants';
import { useDisplaySettingsContext } from 'src/contexts/DisplaySettings';
import { addedProductCartEvent } from 'src/slices/cart/thunks';
import { selectProdatypeinfo } from 'src/slices/landing-page/selectors';
import { AppDispatch } from 'src/store';
import { LimitedPackageData } from 'src/types/generatedTypes';

import { ItemsTypesListDialog } from './item-types-list-dialog';

export const ProductListItem: FC<any> = ({ productData }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { text1, text3, textColor } = useDisplaySettingsContext();

  const productInfo = useSelector(selectProdatypeinfo);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [packvalue, setPackvalue] = useState<LimitedPackageData>(null);
  const [openItemsDialog, setOpenItemsDialog] = useState(false);

  useEffect(() => {
    const data = productData.packdata;
    if (data) {
      if (data?.length !== 0) {
        setPackvalue(data[0]);
      }
    }
  }, []);

  const addProductIntoCart = () => {
    let price =
      packvalue !== null
        ? packvalue!.discountedprice !== 0
          ? packvalue!.discountedprice
          : packvalue!.origprice!
        : productData.discountedprice !== 0
        ? productData.discountedprice!
        : productData.origprice;

    if (packvalue === null) {
      dispatch(
        addedProductCartEvent({
          product: {
            id: Math.floor(Math.random() * 2340).toString(),
            itemid: productData.productid,
            productid: productData.productid,
            tileimage: productData.tileimage,
            title: productData.title,
            price: price,
            perioddiscountlst: productData.perioddiscountlst,
            category: productData.category,
            unitprice: price.toString(),
            rtype: productData.atyp,
            rcat:
              productData.invcat == null
                ? productData.productid
                : productData.invcat,
            namedrentalres:
              productData.invcat != null &&
              [
                CONSTANTS.prodat_rentprod,
                CONSTANTS.prodat_hospital,
                CONSTANTS.prodat_hotel,
              ].includes(productData.atyp ?? ""),
            // productData.discountedprice?.toString() ??
            //     productData.origprice.toString(),
            unit: productData.unitcount.toString(),
            varianttype: productData.varianttype,
            finid: productData.productid,
            qty: 1.0,
            totalprice: price,
            discountedprice: price,
            //productData.discountedprice,
            taxbracket: productData.taxbracket,
            gsttoken: productData.gsttoken,
            reportid: productData.reportid,
          },
        })
      );
    } else {
      dispatch(
        addedProductCartEvent({
          product: {
            id: Math.floor(Math.random() * 2340).toString(),
            itemid: productData.productid,
            productid: productData.productid,
            tileimage: productData.tileimage,
            title: productData.title,
            price: price,
            category: productData.category,
            unitprice: price.toString(),
            unit: packvalue?.desc,
            qty: 1.0,
            totalprice: price,
            discountedprice: price,
            perioddiscountlst: productData.perioddiscountlst,
            taxbracket: productData.taxbracket,
            gsttoken: productData.gsttoken,
            reportid: productData.reportid,
          },
        })
      );
    }
  };

  return (
    <Box
      key={productData.productid}
      onClick={() => {
        // navigate(
        //   `vehicle-details?productid=${productData.productid}&serviceid=${productData.serviceproviderid}&userid=${productData.userid}`
        // );
      }}
      sx={{
        padding: "10px",
        wordBreak: "break-word",
        background: "white",
        borderRadius: "10px",
        // "&:hover": {
        //   border: "1px solid #000000",
        //   cursor: "pointer",
        // },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
        }}
      >
        <Box
          minHeight={isMobile ? "100px" : "150px"}
          minWidth={isMobile ? "100px" : "150px"}
          sx={{
            borderRadius: "5px",
            marginRight: "10px",
            backgroundImage: `url(${productData.tileimage})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "contain",
          }}
        ></Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            position: "relative",
          }}
        >
          <div style={{ position: "absolute", right: 0, cursor: "pointer" }}>
            <InfoIcon
              onClick={() => {
                navigate(
                  `/product/product-details?productid=${productData.productid}&serviceid=${productData.serviceproviderid}&userid=${productData.userid}`
                );
              }}
            />
          </div>
          {productData.varianttype != "SERVICE" && (
            <Typography fontSize={text3}>Agora</Typography>
          )}
          <Typography
            sx={{
              fontSize: text1,
              fontWeight: "bold",
              color: "primary.main",
            }}
          >
            {productData.title}
          </Typography>
          <Box
            sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          >
            {(productData.varianttype === "PACKAGE" ||
              productData.varianttype === "NOPACKAGE") && (
              <Typography fontSize={text3}>{`UNIT : ${packvalue?.desc} `}</Typography>
            )}
            {productData.varianttype == "abc" && (
              <>
                <Typography fontSize={text3}>{`Color : `}</Typography>
                <Box
                  sx={{
                    width: "10px",
                    height: "10px",
                    borderRadius: 44 / 2,
                    background:
                      `#${parseInt(packvalue?.color).toString(16).slice(2)}` ??
                      "0",
                  }}
                />
                <Typography fontSize={text3}>{`Size :  ${
                  packvalue?.size ?? "NoSize"
                }`}</Typography>
              </>
            )}
            {productData.varianttype == "SIZEANDCOLOR" && (
              <>
                <Typography fontSize={text3}>{`Color : `}</Typography>
                <Box
                  sx={{
                    width: "10px",
                    height: "10px",
                    borderRadius: 44 / 2,
                    background:
                      `#${parseInt(packvalue?.color).toString(16).slice(2)}` ??
                      "0",
                  }}
                />
                <Typography fontSize={text3}>{`Size :  ${
                  packvalue?.size ?? "NoSize"
                }`}</Typography>
              </>
            )}
            {productData.varianttype == "SINGLE" && (
              <>
                {productData.unitcount !== null && (
                  <Grid>
                    <Typography fontSize={text3}>{`UNIT : ${productData.unitcount} `}</Typography>
                  </Grid>
                )}
                {productData.unitmeasure !== null && (
                  <Grid>
                    <Typography fontSize={text3}>{`UNIT : ${productData.unitmeasure} `}</Typography>
                  </Grid>
                )}
              </>
            )}
            {(productData.varianttype === "SIZEANDCOLOR" ||
              productData.varianttype === "PACKAGE") && (
              <ArrowDropDownIcon
                onClick={() => {
                  setOpenItemsDialog(true);
                }}
                sx={{ cursor: "pointer" }}
              />
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {!packvalue &&
              (!productData.discountedprice ? (
                <Typography
                  sx={{
                    m: 1,
                  }}
                  fontSize={text3}
                >
                  {(productData.origprice ?? 0).toFixed(2)}
                </Typography>
              ) : (
                <Typography
                  sx={{
                    m: 1,
                  }}
                  fontSize={text3}
                >
                  {(productData.discountedprice ?? 0).toFixed(2)}
                </Typography>
              ))}
            {packvalue && (packvalue!.discountedprice?.toFixed(2) ?? "0")}
            {!packvalue && productData!.discountedprice !== 0 && (
              <Typography
                sx={{
                  m: 1,
                  textDecorationLine: "line-through",
                }}
                fontSize={text3}
              >
                {productData.origprice!.toFixed(2)}
              </Typography>
            )}
            {packvalue && !productData!.discountedprice && (
              <Typography
                sx={{
                  m: 1,
                  textDecorationLine: "line-through",
                }}
                fontSize={text3}
              >
                {packvalue!.origprice!.toFixed(2)}
              </Typography>
            )}
          </Box>
          {!productInfo?.catlst?.isspecial && (
            <Button
              variant="contained"
              onClick={() => {
                addProductIntoCart();
              }}
              sx={{fontSize: text3, color: textColor}}
            >
              ADD
            </Button>
          )}
        </Box>
      </Box>
      <ItemsTypesListDialog
        open={openItemsDialog}
        setOpen={setOpenItemsDialog}
        productData={productData}
        setPackData={setPackvalue}
      />
    </Box>
  );
};

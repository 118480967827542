import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDisplaySettingsContext } from "src/contexts/DisplaySettings";
import { useGeolocationContext } from "src/contexts/GeolocationContext";
import {
  selectIsConsolidateMarket,
  selectEntityId,
  selectLsm,
} from "src/slices/landing-page/selectors";
import { fetchShopResult } from "src/slices/landing-page/thunks";
import { AppDispatch } from "src/store";

export const CategoryButton = ({
  name,
  selectedCategory,
  setSelectedCategory,
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const isSelected = name === selectedCategory;
  const { text2, foregroundColor } = useDisplaySettingsContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const consolidateMarket = useSelector(selectIsConsolidateMarket);
  const { latitude, longitude } = useGeolocationContext();
  const entityId = useSelector(selectEntityId);
  const lsm = useSelector(selectLsm);

  return (
    <>
      <Box
        onClick={() => {
          setSelectedCategory(name);
        }}
        sx={{
          minWidth: isMobile ? "100px" : "130px",
          padding: "10px",
          borderRadius: "16px",
          gap: "4px",
          backgroundColor: isSelected ? foregroundColor : "white",
          border: "1px solid #65656566",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <Typography
          sx={{
            fontSize: text2,
            fontWeight: 600,
            color: isSelected ? "white" : foregroundColor,
          }}
        >
          {name}
        </Typography>
      </Box>
    </>
  );
};

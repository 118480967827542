import { FC } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useDisplaySettingsContext } from "src/contexts/DisplaySettings";
// import Grid from '@mui/material/Grid';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

type TableRowItem = {
  key: any;
  value: any;
};

interface ITableView {
  list: TableRowItem[];
  size?: "small" | "medium";
  labelWidth?: string | number;
}
const CKeyValueList: FC<ITableView> = ({
  list,
  size = "small",
  labelWidth = 170,
}) => {
  const { text2 } = useDisplaySettingsContext();
  return (
    <TableContainer component={Paper}>
      <Table aria-label="readonly table" size={size}>
        <TableBody>
          {list.map((row) => (
            <StyledTableRow key={row.key}>
              <StyledTableCell
                component="th"
                scope="row"
                sx={{
                  fontWeight: 700,
                  width: labelWidth,
                  verticalAlign: "top",
				  fontSize: text2
                }}
              >
                {row.key}
              </StyledTableCell>
              <StyledTableCell align="left" sx={{ wordBreak: "break-all", fontSize: text2 }}>
                {row.value}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CKeyValueList;

import { Grid, Typography } from "@mui/material";
import { ServiceListItem } from "src/components/pages/home/service-list-item";
import { LimitedServiceModel } from "src/slices/cart/types";
import Box from "src/components/pages/landing-page/Box";
import { hexToRGBA } from "src/lib/utils";
import { useDisplaySettingsContext } from "src/contexts/DisplaySettings";
import { useDispatch, useSelector } from "react-redux";
import { selectLastPayloadForProductCall } from "src/slices/landing-page/selectors";
import { useCallback } from "react";
import { AppDispatch } from "src/store";
import { fetchShopResult } from "src/slices/landing-page/thunks";

export const Shops = ({ providers }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { foregroundColor, heading3 } = useDisplaySettingsContext();
  const payload: any = useSelector(selectLastPayloadForProductCall);
  const loadMoreData = useCallback(() => {
    const newPayload = {
      dynamicProperty: {
        ...payload.dynamicProperty,
        offset: payload.dynamicProperty.offset + 10,
      },
    };
    dispatch(fetchShopResult(newPayload));
  }, [payload]);

  const LoadMore = () => {
    return (
      <>
        {providers?.hasmorerecords && (
          <Typography
            sx={{
              textAlign: "center",
              fontSize: heading3,
              padding: "10px",
              cursor: "pointer",
            }}
            onClick={() => loadMoreData()}
          >
            Load More
          </Typography>
        )}
      </>
    );
  };

  return (
    providers?.smlist?.length > 0 && (
      <Box
        id="shops"
        title="Shops"
        subtitle=""
        sx={{
          backgroundColor: hexToRGBA(foregroundColor, 0.1),
          paddingLeft: { xs: 2, sm: 12 },
          paddingRight: { xs: 2, sm: 12 },
          paddingBottom: 5,
        }}
      >
        <Grid container spacing={1}>
          {providers?.smlist?.map((data: LimitedServiceModel) => (
            <Grid item xs={12} sm={6}>
              <ServiceListItem key={data.serviceID} sm={data} />
            </Grid>
          )) ?? <></>}
        </Grid>
        <LoadMore />
      </Box>
    )
  );
};

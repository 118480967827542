import PropTypes from "prop-types";
import { FC } from "react";
import { Field } from "formik";
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
} from "@mui/material";

interface RadioBtnProps {
  inlineGroupLabel?: boolean;
  showInRow?: boolean;
  groupLabel: string;
  defaultValue: string;
  options: any[];
  size?: "small" | "medium";
  name: string;
  required?: boolean;
  helperText?: any;
  radioLabelsx?: any;
  handleChange?: any;
}

const RadioBtn: FC<RadioBtnProps> = (props) => {
  const {
    inlineGroupLabel,
    groupLabel,
    showInRow,
    options,
    size,
    defaultValue,
    name,
    helperText,
    handleChange,
    required = false,
    radioLabelsx,
    ...rest
  } = props;

  // console.log(helperText);

  return (
    <Field name={name}>
      {({ field, form }) => {
        // console.log(field, form);

        return (
          <FormControl
            sx={{
              flexDirection: inlineGroupLabel ? "row" : "column",
              alignItems: inlineGroupLabel ? "center" : "undefined",
              width: "100%",
            }}
            component="fieldset"
            error={form.errors[name] && form.touched[name]}
          >
            {groupLabel && (
              <FormLabel
                component="legend"
                id="demo-error-radios"
                sx={{
                  fontWeight: 600,
                  marginRight: inlineGroupLabel ? "20px" : "0",
                }}
              >
                {groupLabel}
              </FormLabel>
            )}

            <RadioGroup
              defaultValue={defaultValue}
              row={showInRow}
              name={name}
              required={required}
              sx={
                radioLabelsx !== undefined
                  ? radioLabelsx.group
                  : { justifyContent: "space-around" }
              }
              aria-labelledby="demo-error-radios"
              {...rest}
              {...field}
            >
              {options.map((option) => (
                <FormControlLabel
                  value={option.value}
                  control={<Radio size={size} name={name} />}
                  label={option.label}
                  key={option.value}
                  onChange={handleChange}
                  labelPlacement={option.labelPlacement}
                  disabled={option.disabled}
                  sx={radioLabelsx !== undefined ? radioLabelsx.label : null}
                />
              ))}
            </RadioGroup>
            <FormHelperText>{helperText}</FormHelperText>
          </FormControl>
        );
      }}
    </Field>
  );
};

RadioBtn.propTypes = {
  inlineGroupLabel: PropTypes.bool,
  groupLabel: PropTypes.string,
  options: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  // initialValue: PropTypes.string,
  required: PropTypes.bool,
  helperText: PropTypes.any,
  handleChange: PropTypes.any,
};
export default RadioBtn;

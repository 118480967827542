import { FC } from "react";
import PropTypes from "prop-types";
import { Button, ButtonProps } from "@mui/material";
import { useDisplaySettingsContext } from "src/contexts/DisplaySettings";

export interface CustomButtonProps extends ButtonProps {
  children?: string;
  onClick?: any;
}

const CButton: FC<CustomButtonProps> = (props) => {
  const { children, onClick, ...rest } = props;
  return (
    <Button
      {...rest}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
    >
      {children}
    </Button>
  );
};

CButton.propTypes = {
  children: PropTypes.string,
  onClick: PropTypes.func,
};
export default CButton;

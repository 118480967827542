import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { FC, useCallback, useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  fetchShopProductFilterResult,
  getSubMenuCategories,
} from "src/slices/landing-page/thunks";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "src/store";
import {
  selectCategoryMenuItems,
  selectEntityId,
  selectLsm,
  selectMenuItems,
  selectSelectedAtypeCategory,
} from "src/slices/landing-page/selectors";
import { useGeolocationContext } from "src/contexts/GeolocationContext";
import { NestedMenu } from "./NestedMenu";
import { useDisplaySettingsContext } from "src/contexts/DisplaySettings";

interface CategoryMenuProps {
  id: number;
  name: string;
  hc: boolean;
  subdomain: string;
  selected: string;
  setSelected: any;
}

export const PopoverMenu: FC<CategoryMenuProps> = ({
  id,
  name,
  hc,
  subdomain,
  selected,
  setSelected,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const entityId = useSelector(selectEntityId);
  const atype = useSelector(selectSelectedAtypeCategory);
  const lsm = useSelector(selectLsm);
  const menuItems = useSelector((state) => selectMenuItems(state, name));
  const selectedCategoryMenuData = useSelector((state) =>
    selectCategoryMenuItems(state, name)
  );
  const position = useGeolocationContext();
  const { text2, text3, foregroundColor } = useDisplaySettingsContext();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const getCategoryData = useCallback(
    (name) => {
      dispatch(
        fetchShopProductFilterResult({
          dynamicProperty: {
            atyp: atype,
            offset: 0,
            categorytype: name, //'VEHICLE/BUY/Motorcycle',
            psc: {
              atyp: atype,
              isshop: true,
              lsm: lsm,
            },
            termquery: "",
            serviceproviderid: entityId,
            isservice: false,
          },
        })
      );
    },
    [atype, entityId, position, lsm]
  );

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (hc) {
      if (!menuItems) {
        dispatch(
          getSubMenuCategories({
            entityId: entityId,
            websitename: subdomain,
            cat: name,
          })
        ).then(() => {
          setAnchorEl(event.currentTarget);
        });
      } else {
        setAnchorEl(event.currentTarget);
      }
    } else {
      getCategoryData(getCategoryName(name));
      setSelected(name);
    }
  };

  const getCategoryName = useCallback(
    (name) => {
      if (atype === "PET") {
        return `PET/${name}`;
      }
      return name;
    },
    [atype]
  );

  const handleClose = () => {
    setAnchorEl(null);
  };

  const convertSubMenu = (level) => {
    const subMenuItems = level?.catlst?.map((item) => ({
      name: item.name,
      hasSubMenu: item.hc,
      menuItems: convertSubMenu(level[item.name]),
    }));
    return subMenuItems;
  };

  useEffect(() => {
    if (selectedCategoryMenuData) {
      const data = {
        name: name,
        hasSubMenu: hc,
        menuItems: convertSubMenu(selectedCategoryMenuData[name]),
      };
      console.log("data", data);
    }
  }, [selectedCategoryMenuData, name]);

  return (
    <>
      <Box
        onClick={handleClick}
        sx={{
          // width: isMobile ? '100px' : '150px',
          // padding: '10px',
          // borderRadius: "16px",
          gap: "4px",
          // backgroundColor: selected === name ? foregroundColor : "white",
          // border: "1px solid #65656566",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          "&:hover": {
            borderBottom: `1px solid ${foregroundColor}`,
          },
        }}
      >
        <Typography
          sx={{
            fontSize: text3,
            fontWeight: 600,
            color: foregroundColor,
            // color: selected === name ? "white" : foregroundColor,
          }}
        >
          {name}
        </Typography>
        {hc && (
          <KeyboardArrowDownIcon
            sx={{
              fontSize: text2,
              color: foregroundColor,
              // color: selected === name ? "white" : foregroundColor,
            }}
          />
        )}
      </Box>
      {hc && (
        <NestedMenu
          id={name}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          menuItems={
            selectedCategoryMenuData
              ? convertSubMenu(selectedCategoryMenuData[name])
              : []
          }
          onClickSubChild={(childName) => {
            dispatch(
              getSubMenuCategories({
                entityId: entityId,
                websitename: subdomain,
                cat: childName,
              })
            );
          }}
          onClickLeafChild={(childName) => {
            getCategoryData(getCategoryName(childName));
          }}
        />
      )}
    </>
  );
};

import { createSlice } from "@reduxjs/toolkit";

import {
  loadAssignments,
  loadAttendances,
  loadEvents,
  loadExamTerms,
  loadProgress,
} from "./thunks";
import { IUserPanelSlice } from "./types";

export const userPanelSlice = createSlice({
  name: "userPanel",
  initialState: {
    attendances: [],
    examTerms: [],
    progress: [],
    events: [],
    assignments: [],
    isError: false,
    isLoading: false,
  } as IUserPanelSlice,
  reducers: {
    clearAssignments(state): void {
      state.assignments = [];
    },
  },
  extraReducers: (builder) => {
    // loadAttendances
    builder.addCase(loadAttendances.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(loadAttendances.fulfilled, (state, action) => {
      state.isLoading = false;
      state.attendances = action.payload.data;
    });
    builder.addCase(loadAttendances.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    // loadExamTerms
    builder.addCase(loadExamTerms.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(loadExamTerms.fulfilled, (state, action) => {
      state.isLoading = false;
      state.examTerms = action.payload.data;
    });
    builder.addCase(loadExamTerms.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    // loadProgress
    builder.addCase(loadProgress.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(loadProgress.fulfilled, (state, action) => {
      state.isLoading = false;
      state.progress = action.payload.data;
    });
    builder.addCase(loadProgress.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    // loadEvents
    builder.addCase(loadEvents.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(loadEvents.fulfilled, (state, action) => {
      state.isLoading = false;
      state.events = action.payload.data;
    });
    builder.addCase(loadEvents.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    // loadAssignments
    builder.addCase(loadAssignments.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(loadAssignments.fulfilled, (state, action) => {
      state.isLoading = false;
      state.assignments = action.payload.genericlist;
    });
    builder.addCase(loadAssignments.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });
  },
});

export const { clearAssignments } = userPanelSlice.actions;
export const { reducer } = userPanelSlice;

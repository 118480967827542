import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  Divider,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import CKeyValueList from "src/components/CKeyValueList";
import { useDisplaySettingsContext } from "src/contexts/DisplaySettings";
import { toastMessage } from "src/slices/commonSlice";
import { loadResults } from "src/slices/user-panel/thunks";
import { AppDispatch } from "src/store";

const ALPHABET = ["A", "B", "C", "D", "E", "F", "G", "H"];

interface IViewElectionResultDialog {
  dialogName: string;
  resultData: any;
}

export const ViewElectionResultDialog: FC<IViewElectionResultDialog> = ({
  dialogName,
  resultData,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [result, setResult] = useState(null);
  const [mcqs, setMcqs] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const { foregroundColor } = useDisplaySettingsContext();

  useEffect(() => {
    if (resultData) {
      dispatch(loadResults(resultData)).then((response) => {
        setIsLoading(false);
        const res = response.payload?.data?.answerperQsum?.answerlist ?? null;
        setMcqs(response.payload?.data?.rawasg?.qlist ?? null);
        setResult(res);
      });
    }
  }, [resultData]);

  const KeyValue = ({ label, value }) => {
    return (
      <Stack direction="row" justifyContent="space-between" sx={{ my: 1 }}>
        <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
          {label}
        </Typography>
        <Typography sx={{ fontSize: "12px" }}>{value}</Typography>
      </Stack>
    );
  };

  return (
    <>
      {result ? (
        <Grid container sx={{ maxHeight: "70vh" }}>
          <Typography
            sx={{
              width: "100%",
              height: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: "bold",
              color: "white",
              background: foregroundColor,
            }}
          >
            Results
          </Typography>
          <Grid item xs={12} sx={{ display: "flex", flexDirection: "column" }}>
            {mcqs &&
              mcqs.map((mcq, index) => (
                <Box sx={{ py: 2 }}>
                  <Typography sx={{ marginBottom: "5px" }} variant="h6">{`${
                    index + 1
                  }. ${mcq?.questiontext}`}</Typography>
                  {mcq?.canlist?.map((candidate, candIndex) => (
                    <Box sx={{border: '1px solid black', borderRadius: '4px', padding: '5px', mb: 1}}>
                      <Typography variant="h6" sx={{mb: 2}}>{candidate.name}</Typography>
                      <Stack direction='row'>
                        <Typography variant="body1" fontWeight='bold' sx={{width: '200px'}}>Support:</Typography>
                        <Typography variant="body1">
                          {result[index].countperoption[candIndex].pos ===
                          candIndex
                            ? result[index].countperoption[candIndex].count
                            : 0}
                        </Typography>
                      </Stack>
                      <Stack direction='row'>
                        <Typography variant="body1" fontWeight='bold' sx={{width: '200px'}}>Total Attempt:</Typography>
                        <Typography variant="body1">
                        {result[index]?.totattempt}
                        </Typography>
                      </Stack>
                    </Box>
                  )) ?? <></>}
                  <Divider />
                </Box>
              ))}
          </Grid>
        </Grid>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {!isLoading ? (
            <Typography>No Result Found</Typography>
          ) : (
            <CircularProgress color="inherit" size={20} />
          )}
        </Box>
      )}
    </>
  );
};

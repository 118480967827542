import { Box, Grid, Typography } from "@mui/material";
import {
  EmailAuthProvider,
  User,
  createUserWithEmailAndPassword,
  linkWithCredential,
  updateProfile,
} from "firebase/auth";
import { Form, Formik } from "formik";
import { useCallback, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { ContainedButton } from "src/components/button-group";
import FormikControl from "src/components/formik/FormikControl";
import { auth } from "src/configs/firebase";
import { RoutePaths, Slices } from "src/constants";
import logo from "src/icons/logo.png";
import { toastMessage } from "src/slices/commonSlice";
import { AppDispatch } from "src/store";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import * as Yup from "yup";
import "./styles.css";
import { SignUpRequest } from "src/slices/auth/types";
import { userProfileCreationRequest } from "src/slices/auth/thunks";
// import { userProfileCreationResponseSelector } from "src/slices/auth/selectors";

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}
export const Signup = () => {
  const formikRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [selectedCountryCode, setSelectedCountryCode] = useState({
    countryCode: "+91",
    countryName: "IN",
  });
  const [createdUser, setCreatedUser] = useState<User>(null);
  const [isSubmitted, setSubmitted] = useState(false);

  // const userProfileCreationResponse = useSelector(
  //   userProfileCreationResponseSelector
  // );
  const { isLoggedIn } = useSelector(
    (state: any) => state[Slices.LOGIN_INFORMATION]
  );

  const validationSchemaModel = Yup.object({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    email: Yup.string().required("Email is required"),
    password: Yup.string()
      .min(8, "Password must be of minimum 8 characters length")
      .required("Password is required")
      .test(
        "upperCharacterRequired",
        "Password must contain an upper case, lower case, number and special character",
        (password) => {
          return (
            /[a-z]/.test(password) &&
            /[A-Z]/.test(password) &&
            /[0-9]/.test(password) &&
            /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(password)
          );
        }
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const getName = (values) => {
    if (values.middleName) {
      return [values.firstName, values.middleName, values.lastName].join(" ");
    }
    return [values.firstName, values.lastName].join(" ");
  };
  const signupAPI = async (values) => {
    try {
      let signupRequest: SignUpRequest = {
        email: values.email,
        username: values.email,
        phonenum: values.phone.split(selectedCountryCode.countryCode)[1],
        password: values.password,
        countrycode: selectedCountryCode.countryCode,
        requesttype: "PROFILE",
        country: selectedCountryCode.countryName,
      };
      const credential = EmailAuthProvider.credential(
        values.email,
        values.password
      );
      let userCredential = null;
      if (auth.currentUser) {
        userCredential = await linkWithCredential(auth.currentUser, credential);
      } else {
        userCredential = await createUserWithEmailAndPassword(
          auth,
          values.email,
          values.password
        );
      }
      if (userCredential.user) {
        setCreatedUser(userCredential.user);
        signupRequest = { ...signupRequest, userid: userCredential.user.uid };
        await updateProfile(userCredential.user, {
          displayName: getName(values),
        });
        dispatch(userProfileCreationRequest(signupRequest)).then((response) => {
          onResponse(response.payload?.data, userCredential.user);
          // console.log(userProfileCreationResponse);
          setSubmitted(false);
        });
      } else {
        setSubmitted(false);
        dispatch(
          toastMessage({
            error: true,
            message: "Unable to create user!",
          })
        );
      }
    } catch (error) {
      setSubmitted(false);
      if (error.code === "auth/email-already-exists") {
        dispatch(
          toastMessage({
            error: true,
            message: "Email already exists!",
          })
        );
      } else if (error.code === "auth/email-already-in-use") {
        dispatch(
          toastMessage({
            error: true,
            message: "Email already in use!",
          })
        );
      } else if (error.code === "auth/invalid-email") {
        dispatch(
          toastMessage({
            error: true,
            message: "Email is not correct. kindly enter a corrent email!",
          })
        );
      } else {
        dispatch(
          toastMessage({
            error: true,
            message: error.message,
          })
        );
      }
    }
  };

  const onResponse = useCallback(
    (response, user?: User) => {
      if (response) {
        if (!response?.errorid || response?.errorid !== -1) {
          user?.delete();
        } else {
          navigate(RoutePaths.OTP, { state: { uid: user.uid, phone: formikRef?.current?.values?.phone } });
        }
      }
    },
    [createdUser, formikRef]
  );

  if (!createdUser && isLoggedIn && !auth?.currentUser?.isAnonymous) {
    // Redirect the user to the home page
    return <Navigate to="/" />;
  }

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{ p: 0, m: 0, height: "95vh", backgroundColor: "white" }}
    >
      <Grid item xs={8} md={4}>
        <Formik
          initialValues={{
            firstName: "",
            middleName: "",
            lastName: "",
            email: "",
            password: "",
            confirmPassword: "",
            phone: "",
          }}
          validationSchema={validationSchemaModel}
          innerRef={formikRef}
          onSubmit={async (values) => {
            setSubmitted(true);
            signupAPI(values);
          }}
        >
          {({ values, touched, errors, setFieldValue, getFieldProps }) => (
            <Form id="Signup">
              <Grid
                container
                spacing={1}
                justifyContent="center"
                alignItems="center"
                textAlign="center"
              >
                <Grid item xs={12}>
                  <Box
                    component="img"
                    src={logo}
                    alt="logo"
                    height={100}
                    justifySelf="center"
                    sx={{
                      backgroundColor: "primary.main",
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography fontSize={30}>Sign up</Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormikControl
                    control="InputField"
                    name="firstName"
                    label="First Name"
                    error={touched.firstName && Boolean(errors.firstName)}
                    helperText={touched.firstName && errors.firstName}
                    {...getFieldProps("firstName")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormikControl
                    control="InputField"
                    name="middleName"
                    label="Middle Name"
                    error={touched.middleName && Boolean(errors.middleName)}
                    helperText={touched.middleName && errors.middleName}
                    {...getFieldProps("middleName")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormikControl
                    control="InputField"
                    name="lastName"
                    label="Last Name"
                    error={touched.lastName && Boolean(errors.lastName)}
                    helperText={touched.lastName && errors.lastName}
                    {...getFieldProps("lastName")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormikControl
                    control="InputField"
                    name="email"
                    label="Email"
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                    {...getFieldProps("email")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <PhoneInput
                    country={"in"}
                    autoFormat={false}
                    value={values.phone}
                    onChange={(phone, data: any) => {
                      const { dialCode, countryCode } = data;
                      console.log(data);
                      setSelectedCountryCode({
                        countryCode: `+${dialCode}`,
                        countryName: countryCode?.toUpperCase(),
                      });
                      setFieldValue("phone", `+${phone}`);
                    }}
                    // inputStyle={{
                    //   padding: "8.5px 14px"
                    // }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormikControl
                    control="PasswordInputField"
                    name="password"
                    label="Password"
                    error={touched.password && Boolean(errors.password)}
                    helperText={touched.password && errors.password}
                    {...getFieldProps("password")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormikControl
                    control="PasswordInputField"
                    name="confirmPassword"
                    label="Confirm Password"
                    error={
                      touched.confirmPassword && Boolean(errors.confirmPassword)
                    }
                    helperText={
                      touched.confirmPassword && errors.confirmPassword
                    }
                    {...getFieldProps("confirmPassword")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography display="inline">
                    Already have an account?
                  </Typography>
                  <Typography
                    display="inline"
                    onClick={() => {
                      navigate(RoutePaths.LOGIN);
                    }}
                    sx={{
                      color: "primary.main",
                      cursor: "pointer",
                    }}
                  >
                    {" "}
                    Login!
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <ContainedButton
                    fullWidth
                    type="submit"
                    sx={{ color: "white" }}
                    disabled={isSubmitted}
                  >
                    Signup
                  </ContainedButton>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};

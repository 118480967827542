import React from "react";
import ReactDOM from "react-dom/client";
import { AppSetup } from "./AppSetup";
import { DisplaySettingsProvider } from "./contexts/DisplaySettings";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <DisplaySettingsProvider>
      <AppSetup />
    </DisplaySettingsProvider>
  </React.StrictMode>
);

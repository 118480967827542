import { Typography, styled } from "@mui/material";
import MatrialCard from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { useDisplaySettingsContext } from "src/contexts/DisplaySettings";

const TestimonialCardContainer = styled(MatrialCard)(() => ({
  width: "368px",
  height: "175px",
  borderRadius: "8px",
  border: "1px solid #65656533",
  gap: "16px",
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  position: 'relative'
}));

const TypographyName = styled(Typography)(() => ({
  fontWeight: 600,
  lineHeight: "24px",
}));

const TypographyReview = styled(Typography)(() => ({
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "17px",
}));

export default function TestimonialCard({ name, review, maxWidth = "368px" }) {
  const { heading3, text2, foregroundColor } = useDisplaySettingsContext();
  return (
    <TestimonialCardContainer sx={{ maxWidth: maxWidth }} variant="elevation">
        <TypographyName sx={{ color: foregroundColor }} fontSize={heading3}>{name}</TypographyName>
        <TypographyReview fontSize={text2}>{review}</TypographyReview>
    </TestimonialCardContainer>
  );
}

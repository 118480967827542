import { createSlice } from '@reduxjs/toolkit';

import { getListDataEndUser, shareEndUserRating } from './thunks';
import { IOrderHistorySlice, OrderHistoryData } from './types';

export const orderHistorySlice = createSlice({
  name: "orderHistory",
  initialState: {
    history: null,
    isError: false,
    isLoading: false,
  } as IOrderHistorySlice,
  reducers: {
    updateHistory(state, action): void {
      state.history = { ...state.history, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    // getListDataEndUser
    builder.addCase(getListDataEndUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getListDataEndUser.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload) {
        const ud = action.payload.data;
        const data: OrderHistoryData = {
          doctorcaselist: ud.cvpi,
          listorderdata: ud.ordi,
          listaptdata: ud.apti,
          medicalresultlist: ud.mtci,
          frilst: ud.frilst,
          atilst: ud.atilst,
          entityid: state.history.entityid,
          sdt: ud.sdt,
          edt: ud.edt,
          usagetype: "eu",
          qrytype: state.history.qrytype,
          lastcursor: ud.endcursor,
          fcall: true,
        };
        state.history = {
          ...state.history,
          ...data,
        };
      }
    });
    builder.addCase(getListDataEndUser.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    // shareEndUserRating
    builder.addCase(shareEndUserRating.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(shareEndUserRating.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(shareEndUserRating.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });
  },
});

export const { updateHistory } = orderHistorySlice.actions;
export const { reducer } = orderHistorySlice;

import {
  Box,
  Paper,
  Stack,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import FileDropzone from "src/components/dropzone";
import { DEVICE_TYPES } from "src/constants";
import Hero from "src/pages/test-page/Hero";

const HeroBox = styled(Box)(() => ({
  position: "relative",
  height: "100%",
  background: "#eee",
  fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
  fontSize: "14px",
  color: "#000",
  margin: 0,
  padding: 0,
}));

export function TestPage() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));
  let deviceType = isTablet ? DEVICE_TYPES.TABLET : DEVICE_TYPES.DESKTOP;
  deviceType = isMobile ? DEVICE_TYPES.MOBILE : DEVICE_TYPES.DESKTOP;
  const [files, setFiles] = useState([]);

  const [slides, setSlides] = useState([
    {
      image:
        "https://icms-image.slatic.net/images/ims-web/2a43656a-5aff-46c9-a0c9-29cbf5825b5a.jpg_1200x1200.jpg",
      alt: "image",
      title: "",
      subtitle: "",
    },
  ]);

  useEffect(() => {
    if (files.length > 0) {
      setSlides([
        {
          image: URL.createObjectURL(files[0]),
          alt: "image",
          title: "",
          subtitle: "",
        },
      ]);
    }
  }, [files]);

  return (
    <Paper sx={{ height: "90vh" }}>
      <Stack direction="column" gap="20px" sx={{ marginTop: "20px" }}>
        <HeroBox>
          <Hero deviceType={deviceType} slides={slides} />
        </HeroBox>
        <Box sx={{ width: "80%", margin: '0 auto' }}>
          <FileDropzone
            acceptedFileTypes={{
              "image/jpg": [],
              "image/jpeg": [],
              "image/png": [],
            }}
            onSelectedFilesChange={(files) => {
              setFiles(files);
            }}
          />
        </Box>
      </Stack>
    </Paper>
  );
}

import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Box,
  Button,
  Checkbox,
  Chip,
  Fab,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { Formik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, useLocation, useNavigate } from 'react-router-dom';
import CModal from 'src/components/CModal';
import FormikControl from 'src/components/formik/FormikControl';
import { VehicleListItem } from 'src/components/pages/vehicle/vehicle-list-item';
import { useDisplaySettingsContext } from 'src/contexts/DisplaySettings';
import { useGeolocationContext } from 'src/contexts/GeolocationContext';
import {
  selectIsHomeLoading,
  selectProductFilterResult,
  selectProductFilterResultLastCall,
} from 'src/slices/home/selectors';
import { fetchProductFilterResult } from 'src/slices/home/thunks';
import { IFetchProductFilterResultPayload } from 'src/slices/home/types';
import { setDialog } from 'src/slices/uiSettingsSlice';
import { AppDispatch } from 'src/store';

export function VehiclesPage() {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const position = useGeolocationContext();
  const { heading2, text2, text3, textColor } = useDisplaySettingsContext();
  const { state } = useLocation();

  const selectIsLoading = useSelector(selectIsHomeLoading);
  const vechiles = useSelector(selectProductFilterResult);
  const vehicleLastCallPayload = useSelector(selectProductFilterResultLastCall);
  const { dialog } = useSelector((state: any) => state.uiSettings);
  const formikRef = useRef(null);

  const [searchText, setSearchText] = useState("");
  const [filterList, setFilterList] = useState<any>([]);

  const [selectedFilters, setSelectedFilters] = useState<any>([]);
  const [paginationCount, setPaginationCount] = useState(0);

  useEffect(() => {
    if (vechiles) {
      if (selectedFilters.length !== 0) {
        const fltrList = vechiles.diminfo.map((data) => {
          if (
            selectedFilters.filter(
              (fiteredData) => fiteredData.paraname === data.dimname
            ).length !== 0
          ) {
            return {
              paraname: data.dimname,
              fieldvalues: data.fieldvalues.map((fieldData) => ({
                ...fieldData,
                checked: true,
              })),
            };
          } else {
            return {
              paraname: data.dimname,
              fieldvalues: data.fieldvalues.map((fieldData) => ({
                ...fieldData,
                checked: false,
              })),
            };
          }
        });
        setFilterList(fltrList);
      } else {
        const fltrList = vechiles.diminfo.map((data) => ({
          paraname: data.dimname,
          fieldvalues: data.fieldvalues.map((fieldData) => ({
            ...fieldData,
            checked: false,
          })),
        }));
        setFilterList(fltrList);
      }
    }
  }, [vechiles]);

  useEffect(() => {
    if (paginationCount !== -1) {
      dispatch(
        fetchProductFilterResult({
          ...state,
          dynamicProperty: {
            ...state.dynamicProperty,
            offset: paginationCount,
          },
        })
      );
    }
  }, [paginationCount]);

  return (
    <>
      {vechiles ? (
        <>
          <Badge
            badgeContent={0}
            color="secondary"
            overlap="circular"
            sx={{
              position: "absolute",
              bottom: 20,
              right: 100,
              zIndex: 1,
            }}
          >
            <Fab
              disabled={paginationCount === 0}
              size="large"
              color="primary"
              aria-label="add"
              sx={{
                zIndex: 0,
                color: 'white'
              }}
              onClick={() => {
                if (paginationCount > 0) {
                  setPaginationCount((pCount) => pCount - 10);
                }
              }}
            >
              <ArrowUpward />
            </Fab>
          </Badge>
          <Badge
            badgeContent={0}
            color="secondary"
            overlap="circular"
            sx={{
              position: "absolute",
              bottom: 20,
              right: 30,
              zIndex: 1,
            }}
          >
            <Fab
              disabled={vechiles?.docwithdata4?.length === 0}
              size="large"
              color="primary"
              aria-label="add"
              sx={{
                zIndex: 0,
                color: 'white'
              }}
              onClick={() => {
                if (vechiles?.docwithdata4?.length !== 0) {
                  setPaginationCount((pCount) => pCount + 10);
                }
              }}
            >
              <ArrowDownward />
            </Fab>
          </Badge>
          <Box
            sx={{
              background: "white",
              padding: "10px",
              marginBottom: "10px",
              borderRadius: "5px",
              display: "flex",
              gap: "5px",
            }}
          >
            <TextField
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              id="search"
              label="Search..."
              variant="outlined"
              sx={{ width: "100%" }}
            />
            <Button
              sx={{
                color: textColor,
              }}
              onClick={() => {
                dispatch(
                  setDialog({ open: true, dialogName: "VehicleFilters" })
                );
              }}
            >
              Filters
            </Button>
          </Box>
          {selectedFilters.length === 0 ? (
            <></>
          ) : (
            <Box
              sx={{
                bgcolor: "white",
                p: 1,
                mt: "5px",
                overflow: "auto",
                display: "flex",
                alignItems: "center",
                gap: 1,
              }}
            >
              {selectedFilters.map((filter) => (
                <>
                  <Typography
                    fontSize={text3}
                  >{`${filter.paraname}:`}</Typography>
                  {filter.fieldvalues.map((subFilter) => (
                    <Chip label={subFilter} variant="filled" color="primary" />
                  ))}
                </>
              ))}
            </Box>
          )}
          <Grid container spacing={1}>
            {vechiles.docwithdata4
              .filter((data) =>
                data.title.toLowerCase().includes(searchText.toLowerCase())
              )
              .map((data) => (
                <Grid item xs={12} sm={6}>
                  <VehicleListItem key={data.productid} vehicle={data} />
                </Grid>
              ))}
          </Grid>
          {dialog.VehicleFilters !== undefined && dialog.VehicleFilters ? (
            <CModal
              dialogName="VehicleFilters"
              title={"Vehicle Filters"}
              maxWidth="sm"
              showSaveButton={true}
              handleSaveButtonText="Apply"
              handleCancel={() => {}}
              handleSave={() => {
                dispatch(
                  setDialog({ open: false, dialogName: "VehicleFilters" })
                );

                const selectedFilterList = filterList.filter(
                  (data) =>
                    data.fieldvalues.filter((fieldData) => fieldData.checked)
                      .length !== 0
                );

                const selectedFilterListValues = selectedFilterList.map(
                  (data) => ({
                    paraname: data.paraname,
                    fieldvalues: data.fieldvalues
                      .filter((fieldData) => fieldData.checked)
                      .map((fieldData) => fieldData.fieldvalue),
                  })
                );
                setSelectedFilters(selectedFilterListValues);

                // Price Filter
                let filterObj1 = {
                  fieldname: "Price",
                  minval: 0,
                  maxval: 0,
                };

                // Milage Filter
                let filterObj2 = {
                  fieldname: "Milage",
                  minval: 0,
                  maxval: 0,
                };

                // Year Build Filter
                let filterObj3 = {
                  fieldname: "Year Build",
                  minval: 0,
                  maxval: 0,
                };

                let isPriceFilterSelected = false;
                if (formikRef.current.values.priceMin !== "") {
                  filterObj1.minval = parseInt(
                    formikRef.current.values.priceMin
                  );
                  isPriceFilterSelected = true;
                }
                if (formikRef.current.values.priceMax !== "") {
                  filterObj1.maxval = parseInt(
                    formikRef.current.values.priceMax
                  );
                  isPriceFilterSelected = true;
                }

                let isMilageFilterSelected = false;
                if (formikRef.current.values.milageMin !== "") {
                  filterObj2.minval = parseInt(
                    formikRef.current.values.milageMin
                  );
                  isMilageFilterSelected = true;
                }
                if (formikRef.current.values.milageMax !== "") {
                  filterObj2.maxval = parseInt(
                    formikRef.current.values.milageMax
                  );
                  isMilageFilterSelected = true;
                }

                let isYearBuildFilterSelected = false;
                if (formikRef.current.values.yearBuildMin !== "") {
                  filterObj3.minval = parseInt(
                    formikRef.current.values.yearBuildMin
                  );
                  isYearBuildFilterSelected = true;
                }
                if (formikRef.current.values.yearBuildMax !== "") {
                  filterObj3.maxval = parseInt(
                    formikRef.current.values.yearBuildMax
                  );
                  isYearBuildFilterSelected = true;
                }

                let filterValue = [];
                if (isPriceFilterSelected) filterValue.push(filterObj1);
                if (isMilageFilterSelected) filterValue.push(filterObj2);
                if (isYearBuildFilterSelected) filterValue.push(filterObj3);

                dispatch(
                  fetchProductFilterResult({
                    dynamicProperty: {
                      psc: {
                        servicetype:
                          vehicleLastCallPayload.dynamicProperty.psc
                            .servicetype,
                        grouptype:
                          vehicleLastCallPayload.dynamicProperty.psc.grouptype,
                        isshop: false,
                        sp: {
                          curStatename: position.district,
                          statename: position.district,
                          lati: position.latitude,
                          longi: position.longitude,
                        },
                      },
                      categorytype:
                        vehicleLastCallPayload.dynamicProperty.categorytype,
                      termquery: "",
                      filters: filterValue,
                      facetparalist: selectedFilterListValues,
                      serviceproviderid: "",
                      isservice: false,
                    },
                  } as IFetchProductFilterResultPayload)
                );
              }}
              open={
                dialog.VehicleFilters === undefined
                  ? false
                  : dialog.VehicleFilters
              }
              content={
                <Formik
                  initialValues={{
                    priceMin: "",
                    priceMax: "",
                    milageMin: "",
                    milageMax: "",
                    yearBuildMin: "",
                    yearBuildMax: "",
                  }}
                  innerRef={formikRef}
                  onSubmit={() => console.log()}
                >
                  {({ touched, errors, getFieldProps }) => (
                    <Form>
                      <Grid container spacing={2}>
                        {/* Price */}
                        <Grid item xs={12}>
                          <Typography fontSize={text2}>Price</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <FormikControl
                            control="InputField"
                            label={"Min"}
                            name="priceMin"
                            size="small"
                            error={Boolean(touched.priceMin && errors.priceMin)}
                            helperText={touched.priceMin && errors.priceMin}
                            {...getFieldProps("priceMin")}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <FormikControl
                            control="InputField"
                            label={"Max"}
                            name="priceMax"
                            size="small"
                            error={Boolean(touched.priceMax && errors.priceMax)}
                            helperText={touched.priceMax && errors.priceMax}
                            {...getFieldProps("priceMax")}
                          />
                        </Grid>

                        {/* Milage */}
                        <Grid item xs={12}>
                          <Typography fontSize={text2}>Milage</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <FormikControl
                            control="InputField"
                            label={"Min"}
                            name="milageMin"
                            size="small"
                            error={Boolean(
                              touched.milageMin && errors.milageMin
                            )}
                            helperText={touched.milageMin && errors.milageMin}
                            {...getFieldProps("milageMin")}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <FormikControl
                            control="InputField"
                            label={"Max"}
                            name="milageMax"
                            size="small"
                            error={Boolean(
                              touched.milageMax && errors.milageMax
                            )}
                            helperText={touched.milageMax && errors.milageMax}
                            {...getFieldProps("milageMax")}
                          />
                        </Grid>

                        {/* Year Build */}
                        <Grid item xs={12}>
                          <Typography fontSize={text2}>Year Build</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <FormikControl
                            control="InputField"
                            label={"Min"}
                            name="yearBuildMin"
                            size="small"
                            error={Boolean(
                              touched.yearBuildMin && errors.yearBuildMin
                            )}
                            helperText={
                              touched.yearBuildMin && errors.yearBuildMin
                            }
                            {...getFieldProps("yearBuildMin")}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <FormikControl
                            control="InputField"
                            label={"Max"}
                            name="yearBuildMax"
                            size="small"
                            error={Boolean(
                              touched.yearBuildMax && errors.yearBuildMax
                            )}
                            helperText={
                              touched.yearBuildMax && errors.yearBuildMax
                            }
                            {...getFieldProps("yearBuildMax")}
                          />
                        </Grid>
                        {filterList.map((filterData, filterListIndex) => (
                          <Grid item xs={12}>
                            <Accordion>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                              >
                                <Typography fontSize={text2}>
                                  {filterData.paraname}
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Grid container xs={2}>
                                  {filterData.fieldvalues.map(
                                    (fieldData, fieldValueIndex) => (
                                      <>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <Checkbox
                                            size="medium"
                                            color="primary"
                                            checked={fieldData.checked ?? false}
                                            onClick={(e: any) => {
                                              if (e.target.checked) {
                                                filterList[
                                                  filterListIndex
                                                ].fieldvalues[
                                                  fieldValueIndex
                                                ].checked = true;
                                                setFilterList([...filterList]);
                                              } else {
                                                filterList[
                                                  filterListIndex
                                                ].fieldvalues[
                                                  fieldValueIndex
                                                ].checked = false;
                                                setFilterList([...filterList]);
                                              }
                                            }}
                                          />

                                          <Typography
                                            sx={{ width: 100, fontSize: text3 }}
                                          >
                                            {fieldData.fieldvalue}
                                          </Typography>
                                        </Box>
                                      </>
                                    )
                                  )}
                                </Grid>
                              </AccordionDetails>
                            </Accordion>
                          </Grid>
                        ))}
                      </Grid>
                    </Form>
                  )}
                </Formik>
              }
            />
          ) : null}
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: "80vh",
            margin: "0 auto",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {selectIsLoading ? (
            <></>
          ) : (
            <Typography fontSize={heading2}>No Data Found</Typography>
          )}
        </Box>
      )}
    </>
  );
}

import "src/styles/slider-style.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import Slide from "./Slide";
import { SliderProps } from "src/types/slide";

export default function Index({
  id,
  containerHeight = window.innerHeight || document.documentElement.clientHeight,
  slides,
}: SliderProps) {
  return (
    <Swiper
      id={id}
      modules={[Autoplay, Pagination, Navigation]}
      pagination={{
        clickable: true,
        renderBullet: function (_, className) {
          return `<span class="${className}" style="width: 30px; height: 5px; border-radius: .5rem; background-color: #D9D9D966; opacity: 1"></span>`;
        },
      }}
      navigation={true}
      autoplay={true}
      loop={true}
      style={{ height: containerHeight }}
    >
      {slides.map((slide, index) => {
        return (
          <SwiperSlide key={index}>
            <Slide
              image={slide.image}
              alt={slide.alt}
              title={slide.title}
              subtitle={slide.subtitle}
            />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
}

// import "src/styles/slider-style.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import ReviewCard from "./ReviewCard";
import { DEVICE_TYPES } from "src/constants";
import { useDisplaySettingsContext } from "src/contexts/DisplaySettings";

interface ReviewSliderProps {
  id?: any;
  containerHeight?: any;
  reviews: any[];
  deviceType: any;
}

export default function Index({
  id,
  containerHeight = window.innerHeight || document.documentElement.clientHeight,
  reviews,
  deviceType,
}: ReviewSliderProps) {
  const { foregroundColor } = useDisplaySettingsContext();

  return (
    <Swiper
      id={id}
      spaceBetween={50}
      slidesPerView={deviceType === DEVICE_TYPES.MOBILE ? 1 : 3}
      modules={[Autoplay, Pagination]}
      pagination={{
        clickable: true,
        renderBullet: function (_, className) {
          return `<span class="${className}" style="width: 30px; height: 5px; border-radius: .5rem; background-color: #FFFFFF66; opacity: 1"></span>`;
        },
      }}
      autoplay={true}
      loop={true}
      style={{ height: containerHeight, backgroundColor: foregroundColor }}
    >
      {reviews.map((slide, index) => {
        return (
          <SwiperSlide key={index}>
            <ReviewCard
              name={slide.name}
              review={slide.review}
              rating={slide.rating}
              subTitle={slide.subTitle}
            />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
}

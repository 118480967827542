import {
  Typography,
  Box,
  Grid,
  Paper,
  Button,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CKeyValueList from "src/components/CKeyValueList";
import { AppDispatch } from "src/store";
import { OrderSummary } from "../order-summary";
import CTypography from "src/components/CTypography";
import { selectCartState } from "src/slices/cart/selectors";
import { ICartState } from "src/slices/cart/types";
import { addDays } from "date-fns";
import { useNavigate } from "react-router-dom";
import { OrderCartItem } from "./appointment-order-cart-item";
import { cartCheckout } from "src/slices/cart/thunks";
import { selectAppointmentState } from "src/slices/appointment/selectors";
import { AppointmentState } from "src/slices/appointment/types";
import { fetchAppointmentEmployee } from "src/slices/appointment/thunks";
import { selectLsm } from "src/slices/landing-page/selectors";
import { useDisplaySettingsContext } from "src/contexts/DisplaySettings";
import { selectIsHomeLoading } from "src/slices/home/selectors";

interface ICartViewProps {
  popsCount?: number;
  usagetype?: string;
}
export const AppointmentCartPage: FC<ICartViewProps> = ({
  popsCount = 4,
  usagetype = "DEFAULT",
}) => {
  const navigate = useNavigate();

  const selectedCartState: ICartState = useSelector(selectCartState);
  const shopLsm = useSelector(selectLsm);
  const selectedAppointmentState: AppointmentState = useSelector(
    selectAppointmentState
  );

  const serviceModel = selectedAppointmentState?.serviceModel || shopLsm;

  const dispatch = useDispatch<AppDispatch>();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { heading2, textColor } = useDisplaySettingsContext();
  
  const selectIsLoading = useSelector(selectIsHomeLoading);

  useEffect(() => {
    if (selectedCartState) {
      if (![null, undefined].includes(selectedCartState.cart!.id)) {
        if (serviceModel!.apttype === "PerEmployee") {
          // _appointmentBloc.add(
          //   FetchAppointmentEmployee(),
          // );
          // PersistentNavBarNavigator.pushNewScreen(
          //   context,
          //   screen: MultiBlocProvider(
          //     providers: [
          //       BlocProvider.value(value: context.read<CartBloc>()),
          //       BlocProvider.value(
          //         value: context.read<AppointmentBloc>(),
          //       ),
          //     ],
          //     child: SelectEmployeeView(),
          //   ),
          // );
          dispatch(fetchAppointmentEmployee()).then(() => {
            navigate("/appointment-cart/select-employee");
          });
        } else {
          navigate("/appointment-cart/booktime");
          // navigate();
          // PersistentNavBarNavigator.pushNewScreen(
          //   context,
          //   screen: MultiBlocProvider(
          //     providers: [
          //       BlocProvider.value(value: context.read<CartBloc>()),
          //       BlocProvider.value(
          //         value: context.read<AppointmentBloc>(),
          //       ),
          //     ],
          //     child: BookTimeView(),
          //   ),
          // );
        }
      }
    }
  }, [selectedCartState]);

  return (
    <>
      {selectedCartState ? (
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <Paper
              sx={{
                [theme.breakpoints.up("md")]: { minHeight: "79.5vh" },
                overflow: "scroll",
                padding: 2,
                alignSelf: "center",
              }}
            >
              {selectedCartState.cart?.productlist?.map((item) => (
                <OrderCartItem
                  isActive={selectedCartState.orderid != null}
                  item={item}
                  state={selectedCartState}
                  showresourceavailabilityonitemcard={false}
                  //showrentalrelatedfields && state.cart?.id ==null,
                  showrentalreturnbutton={true}
                  //showrentalrelatedfields && state.cart?.id !=null,
                  showresouurcepicekeroncard={true}
                  //showrentalrelatedfields && state.cart?.id !=null && item.namedrentalres ==true ,

                  onRentStatusChange={(isAvailable: boolean) => {
                    // setState(() {
                    //   availableForRent = isAvailable;
                    // });
                  }}
                  startDate={selectedCartState.sdt ?? new Date(Date.now())}
                  endDate={
                    selectedCartState.edt ?? addDays(new Date(Date.now()), 1)
                  }
                />
              ))}
            </Paper>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Paper sx={{ padding: 2, alignSelf: "center" }}>
              <Grid container spacing={2}>
                <>
                  <Grid item xs={12}>
                    <CTypography fontWeight="bold">Payment Info</CTypography>
                  </Grid>

                  <Grid item xs={12}>
                    <CKeyValueList
                      labelWidth={isMobile ? 100 : 250}
                      size="medium"
                      list={[
                        {
                          key: "Service Provider",
                          value: selectedCartState.lsm?.spi?.payeeto ?? "",
                        },
                        {
                          key: "Mobile",
                          value: selectedCartState.lsm?.spi?.phonenum ?? "",
                        },
                        {
                          key: "Direction",
                          value: selectedCartState.lsm?.spi?.address ?? "",
                        },
                      ]}
                    />
                  </Grid>
                </>

                <Grid item xs={12}>
                  <CTypography fontWeight="bold">Summary</CTypography>
                </Grid>
                <Grid item xs={12}>
                  <OrderSummary state={selectedCartState} />
                </Grid>

                {/* SUBMIT FORM */}

                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <Grid item>
                      <Button
                      sx={{
                        color: textColor
                      }}
                        variant="contained"
                        onClick={() => {
                          dispatch(cartCheckout({}));
                        }}
                      >
                        {"CHECKOUT"}
                      </Button>
                    </Grid>
                    {/* <Grid item>
                              <Button
                                variant="contained"
                                onClick={() => {
                                  // navigate(-1);
                                }}
                              >
                                CANCEL
                              </Button>
                            </Grid> */}
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      ) : (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: "80vh",
            margin: "0 auto",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {selectIsLoading ? (
            <></>
          ) : (
            <Typography fontSize={heading2}>Please Add Data</Typography>
          )}
        </Box>
      )}
    </>
  );
};

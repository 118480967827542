import { Stack, Typography } from "@mui/material";
import { useDisplaySettingsContext } from "src/contexts/DisplaySettings";
import { SlideProps } from "src/types/slide";

export default function Slide({ image, alt, title, subtitle }: SlideProps) {
  const { heading2, text1 } = useDisplaySettingsContext();
  
  return (
    <Stack
      alignItems={"center"}
      justifyContent={"center"}
      position={"relative"}
      height={"100%"}
      sx={{
        "&::before": {
          content: "''",
          width: "100%",
          height: "100%",
          position: "absolute",
          inset: "0px",
        },
      }}
    >
      <img
        loading="eager"
        src={image}
        data-src={image}
        alt={alt}
        width={"100%"}
        height={"100%"}
        style={{ objectFit: "fill" }}
      />
      <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
    </Stack>
  );
}

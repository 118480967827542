import { Checkbox, FormControlLabel, Grid, Paper, Rating, Stack, TextField, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import CButton from 'src/components/button-group/CButton';
import CModal from 'src/components/CModal';
import CTypography from 'src/components/CTypography';
import FormikControl from 'src/components/formik/FormikControl';
import { BookingListItem } from 'src/components/pages/booking/booking-list-item';
import { useCurrentLocation } from 'src/hooks/useCurrentLocation';
import { selectProductState } from 'src/slices/product/selectors';
import { rentalSpFilterGetDataEvent } from 'src/slices/product/thunks';
import { ProductState, RentalRelatedFilterInfo } from 'src/slices/product/types';
import { setDialog } from 'src/slices/uiSettingsSlice';
import { AppDispatch } from 'src/store';

export const RentalSpListWithFilter = () => {
  const dispatch = useDispatch<AppDispatch>();
  const formikRef = useRef(null);
  const { state } = useLocation();

  // const { coords, isGeolocationAvailable, isGeolocationEnabled } =
  //   useGeolocated({
  //     positionOptions: {
  //       enableHighAccuracy: false,
  //     },
  //     userDecisionTimeout: 5000,
  //   });

  const productState: ProductState = useSelector(selectProductState);
  const { dialog } = useSelector((state: any) => state.uiSettings);
  const locationData = useCurrentLocation();

  // Form Inital Values
  const formInitialValue = {
    startDate: new Date(Date.now()),
    endDate: new Date(Date.now()),
    numRoom: "",
  };

  const [itemsList, setItemsList] = useState<any>([]);
  const [value, setValue] = useState(2);
  const [userRating1, setUserRating1] = useState(4);
  const [userRating2, setUserRating2] = useState(4);

  useEffect(() => {
    if (productState?.productFilterResultModel?.rpds?.rentalspprodlist) {
      setItemsList(
        productState.productFilterResultModel?.rpds?.rentalspprodlist
      );
    }
  }, [productState]);

  useEffect(()=> {
    if(state?.rrfi){
      formikRef.current.setFieldValue('startDate', state.rrfi.sdt);
      formikRef.current.setFieldValue('endDate', state.rrfi.edt);
      formikRef.current.setFieldValue('numRoom', state.rrfi.numroom);
      dispatch(
        rentalSpFilterGetDataEvent({
          rrfi: state.rrfi,
          lsm: null,
          location: state.location,
        })
      );
    }
  },[state]);

  const getData = () => {
    
  };

  return (
    <Paper sx={{ p: 2, width: "50%", display: "flex", alignSelf: "center" }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Formik
            initialValues={formInitialValue}
            innerRef={formikRef}
            onSubmit={(values) => {
              const rrfi = {
                sdt: values.startDate,
                edt: values.endDate,
                forsid: null,
                occupancytype: "o1A0C",
                numroom:
                  values.numRoom === "" ? null : parseInt(values.numRoom),
                usecurloc: true,
                citydistrictstate: null,
                servicetype: state?.servicetype,
              } as RentalRelatedFilterInfo;

              let location = {
                lat: locationData.latitude,
                long: locationData.longitude,
              };
              // if (isGeolocationAvailable && isGeolocationEnabled) {
              //   location = {
              //     lat: coords.latitude,
              //     long: coords.longitude,
              //   };
              // }

              dispatch(
                rentalSpFilterGetDataEvent({
                  rrfi: rrfi,
                  lsm: null,
                  location: location,
                })
              );
            }}
          >
            {({ touched, errors, setFieldValue, getFieldProps }) => (
              <Form>
                {/* Complaint info */}
                <Grid container spacing={1} pb={3}>
                  <Grid item xs={12}>
                    <CTypography
                      sx={{
                        color: "#1E1E1E",
                        fontFamily: "Poppins",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "normal",
                      }}
                    >
                      Search...
                    </CTypography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormikControl
                      control="CalendarTime"
                      label="Start Date"
                      dateOrTimeOnly="date"
                      name="startDate"
                      error={Boolean(touched.startDate && errors.startDate)}
                      helperText={touched.startDate && errors.startDate}
                      {...getFieldProps("startDate")}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormikControl
                      control="CalendarTime"
                      label="End Date"
                      dateOrTimeOnly="date"
                      name="endDate"
                      error={Boolean(touched.endDate && errors.endDate)}
                      helperText={touched.endDate && errors.endDate}
                      {...getFieldProps("endDate")}
                    />
                  </Grid>

                  {state?.servicetype === "HOTELROOM" && (
                    <>
                      <Grid item xs={12} sm={6}>
                        <FormikControl
                          control="InputField"
                          name="numRoom"
                          label={"Num Room"}
                          error={Boolean(touched.numRoom && errors.numRoom)}
                          helperText={touched.numRoom && errors.numRoom}
                          {...getFieldProps("numRoom")}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <CButton
                          variant="outlined"
                          onClick={() => {
                            dispatch(
                              setDialog({
                                open: true,
                                dialogName: "RentalFilterDialog",
                              })
                            );
                          }}
                        >
                          Filter
                        </CButton>
                      </Grid>
                    </>
                  )}

                  <Grid item xs={12}>
                    <CButton variant="contained" type="submit">
                      Go
                    </CButton>
                  </Grid>

                  {itemsList.map((itemData) => (
                    <Grid item xs={12}>
                      <BookingListItem
                        data={itemData}
                        productState={productState}
                        state={state}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
      {dialog.RentalFilterDialog !== undefined && dialog.RentalFilterDialog ? (
        <CModal
          dialogName="RentalFilterDialog"
          title={"Filter"}
          maxWidth="xs"
          handleSaveButtonText="Apply"
          handleCancelButtonText="close"
          handleCancel={() => {}}
          handleSave={() => {
            dispatch(
              setDialog({ open: false, dialogName: "RentalFilterDialog" })
            );
          }}
          open={
            dialog.RentalFilterDialog === undefined
              ? false
              : dialog.RentalFilterDialog
          }
          content={
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <TextField
                  label="Price Minimum"
                  variant="outlined"
                  size="small"
                  type="number"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Price Maximum"
                  variant="outlined"
                  size="small"
                  type="number"
                />
              </Grid>
              <Grid item xs={12}>
                <Typography fontWeight="bold">Hotel Ameties</Typography>
              </Grid>
              <Grid item xs={12}>
                <Rating
                  value={value}
                  onChange={(event, newValue) => {
                    setValue(newValue);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography fontWeight="bold">User Rating</Typography>
              </Grid>
              <Grid item xs={12}>
                <Rating
                  value={userRating1}
                  onChange={(event, newValue) => {
                    setUserRating1(newValue);
                  }}
                  sx={{
                    mr: 3,
                  }}
                />
                <Rating
                  value={userRating2}
                  onChange={(event, newValue) => {
                    setUserRating2(newValue);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography fontWeight="bold">Amenities</Typography>
              </Grid>
              <Grid item xs={12}>
                <Stack direction="column">
                  <FormControlLabel control={<Checkbox />} label="Breakfast" />
                  <FormControlLabel control={<Checkbox />} label="Lunch" />
                  <FormControlLabel control={<Checkbox />} label="Dinner" />
                  <FormControlLabel control={<Checkbox />} label="Wifi" />
                  <FormControlLabel control={<Checkbox />} label="TV" />
                  <FormControlLabel control={<Checkbox />} label="Fridge" />
                </Stack>
              </Grid>
            </Grid>
          }
        />
      ) : null}
    </Paper>
  );
};

import { Paper, Box, Typography, styled } from "@mui/material";
import { FC } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { auth } from "src/configs/firebase";
import { useGeolocationContext } from "src/contexts/GeolocationContext";
import {
  fetchProductFilterResult,
  getServiceProviders,
} from "src/slices/home/thunks";
import {
  IFetchProductFilterResultPayload,
  IGetServiceProvidersPayload,
} from "src/slices/home/types";
import { AppDispatch } from "src/store";
import { ServiceTypeList, ChildServiceType } from "src/types/exploreTypes";
import * as CONSTANTS from "src/constants";

const ProductPaper = styled(Paper)(({ theme }) => ({
  border: `0px`,
  padding: "1rem",
  width: 150,
  height: 200,
  [theme.breakpoints.down("md")]: {
    width: 100,
    height: 150,
  },
  wordBreak: 'break-all',
  display: "flex",
  flexDirection: "column",
  textAlign: "center",
  "&:hover": {
    border: `1px solid ${theme.palette.text.primary}`,
    cursor: "pointer",
  },
}));

interface IProductProps {
  data: ServiceTypeList | ChildServiceType;
  sectionname: string;
  isShop: boolean;
  type: string;
}

export const Product: FC<IProductProps> = ({
  data,
  sectionname,
  isShop,
  type,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const position = useGeolocationContext();

  const conditionWidget = () => {
    // other logic
    // Declare a widget variable,
    // it will be assigned later according
    // to the condition
    let comp;

    // Using switch statement to display desired
    // widget if any certain condition is met
    // You are free to use any condition
    // For simplicity I have used boolean contition
    //"SELF", user.userID
    if (data.isspecial !== null && data.isspecial) {
      switch (data.specialtype) {
        case CONSTANTS.sert_hotel:
          navigate("/rental-sp-list-with-filter", {
            state: {
              servicetype: CONSTANTS.sert_hotel,
              displaytype: CONSTANTS.dispt_hotel,
            },
          });
          break;

        case CONSTANTS.sert_banquet:
          navigate("/rental-sp-list-with-filter", {
            state: {
              servicetype: CONSTANTS.sert_banquet,
              displaytype: CONSTANTS.dispt_banquet,
            },
          });
          break;

        case CONSTANTS.sert_homestay:
          navigate("/rental-sp-list-with-filter", {
            state: {
              servicetype: CONSTANTS.sert_homestay,
              displaytype: CONSTANTS.dispt_homestay,
            },
          });
          break;

        case CONSTANTS.sert_wedding:
          navigate("/rental-sp-list-with-filter", {
            state: {
              servicetype: CONSTANTS.sert_wedding,
              displaytype: CONSTANTS.dispt_wedding,
            },
          });
          break;

        case "VEHICLE":
          const payloadVehicle = {
            dynamicProperty: {
              psc: {
                servicetype: data.querystr,
                grouptype: data.grptype,
                isshop: false,
                sp: {
                  curStatename: position.district,
                  statename: position.district,
                  lati: position.latitude,
                  longi: position.longitude,
                },
              },
              categorytype: data.querystr,
              termquery: "",
              filters: [],
              facetparalist: [],
              serviceproviderid: "",
              isservice: false,
              offset: 0,
            },
          } as IFetchProductFilterResultPayload;
          dispatch(fetchProductFilterResult(payloadVehicle)).then(() => {
            navigate("/vehicle", { state: payloadVehicle });
          });
          break;

        case "REALESTATE":
          const payloadRealEstate = {
            dynamicProperty: {
              psc: {
                servicetype: data.querystr,
                grouptype: data.grptype,
                isshop: false,
                sp: {
                  curStatename: position.district,
                  statename: position.district,
                  lati: position.latitude,
                  longi: position.longitude,
                },
              },
              categorytype: data.querystr,
              termquery: "",
              filters: [],
              facetparalist: [],
              serviceproviderid: "",
              isservice: false,
            },
          } as IFetchProductFilterResultPayload;
          dispatch(fetchProductFilterResult(payloadRealEstate)).then(() => {
            navigate("/real-estate", { state: payloadRealEstate });
          });
          break;

        case "JOB":
          const payloadJob = {
            dynamicProperty: {
              psc: {
                servicetype: data.querystr,
                grouptype: data.grptype,
                isshop: false,
                sp: {
                  curStatename: position.district,
                  statename: position.district,
                  lati: position.latitude,
                  longi: position.longitude,
                },
              },
              categorytype: data.querystr,
              termquery: "",
              filters: [],
              facetparalist: [],
              serviceproviderid: "",
              isservice: false,
            },
          } as IFetchProductFilterResultPayload;
          dispatch(fetchProductFilterResult(payloadJob)).then(() => {
            navigate("/job", { state: payloadJob });
          });
          break;

        case "PET":
          const payloadPet = {
            dynamicProperty: {
              psc: {
                servicetype: data.querystr,
                grouptype: data.grptype,
                isshop: false,
                sp: {
                  curStatename: position.district,
                  statename: position.district,
                  lati: position.latitude,
                  longi: position.longitude,
                },
              },
              categorytype: data.querystr,
              termquery: "",
              filters: [],
              facetparalist: [],
              serviceproviderid: "",
              isservice: false,
            },
          } as IFetchProductFilterResultPayload;
          dispatch(fetchProductFilterResult(payloadPet)).then(() => {
            navigate("/pet", { state: payloadPet });
          });
          break;

        case "SHOPDEALS":
          dispatch(
            getServiceProviders({
              dynamicProperty: {
                servicetype: data.querystr,
                grouptype: sectionname,
                isshop: isShop,
                sp: {
                  statename: position.district,
                  lati: position.latitude,
                  longi: position.longitude,
                },
                cust: {
                  customeruserid: auth.currentUser.uid,
                },
                spoffset: 0,
              },
              Query: "",
            } as IGetServiceProvidersPayload)
          ).then(() => {
            navigate(`${data.querystr}`);
          });
          break;

        case "ADMISSIONS":
          dispatch(
            getServiceProviders({
              dynamicProperty: {
                servicetype: data.querystr,
                grouptype: sectionname,
                isshop: isShop,
                sp: {
                  statename: position.district,
                  lati: position.latitude,
                  longi: position.longitude,
                },
                cust: {
                  customeruserid: auth.currentUser.uid,
                },
                spoffset: 0,
              },
              Query: "",
            } as IGetServiceProvidersPayload)
          ).then(() => {
            navigate(`${data.querystr}`);
          });
          break;

        default:
          break;
      }
      switch (data.specialtype) {
      //   case "ADHOCTRIPENDUSER":
      //     widget = AdhocTripEndUserView(
      //       entitytype: "SELF",
      //       entityid: UserSession.userId,
      //     );
      //     break;
      //   case HelpUtil.sert_hotel:
      //     widget = RentalSpListView(
      //     servicetype: HelpUtil.sert_hotel,
      //         displaytype:HelpUtil.dispt_hotel
      //     );
      //     break;
      //   case HelpUtil.sert_banquet:
      //     widget = RentalSpListView(
      //         servicetype: HelpUtil.sert_banquet,
      //         displaytype:HelpUtil.dispt_banquet
      //     );
      //     break;
      //   case HelpUtil.sert_homestay:
      //     widget = RentalSpListView(
      //         servicetype: HelpUtil.sert_homestay,
      //         displaytype:HelpUtil.dispt_homestay
      //     );
      //     break;
      //   case HelpUtil.sert_wedding:
      //     widget = RentalSpListView(
      //         servicetype: HelpUtil.sert_wedding,
      //         displaytype:HelpUtil.dispt_wedding
      //     );
      //     break;
        case "TRIPSEARCH":
          navigate(`end-user`);
          // widget = EndUserList(
          //   forstate: true,
          //   searchtype: "CURLOC",
          // );
          break;
      //   case "ENDUSEREVENT":
      //     widget = DailyEvents(
      //       entitytype: null,
      //       entityid: null,
      //       issp: false,
      //     );
      //     break;
      //   default:
      //     widget = Container();
      default: {
          break;
      }
      }
    } else {
      dispatch(
        getServiceProviders({
          dynamicProperty: {
            servicetype: data.querystr,
            grouptype: sectionname,
            isshop: isShop,
            sp: {
              statename: position.district,
              lati: position.latitude,
              longi: position.longitude,
            },
            cust: {
              customeruserid: auth.currentUser.uid,
            },
            spoffset: 0,
          },
          Query: "",
        } as IGetServiceProvidersPayload)
      ).then(() => {
        navigate(`${data.querystr}`);
      });
    }
  };

  return (
    <ProductPaper
      elevation={2}
      onClick={(e) => {
        e.preventDefault();
        // console.log(data);
        // final _bloc = BlocProvider.of<ProductBloc>(context);
        if (type != "shop") {
          // _bloc.add(
          //   ServiceTypeClassifiedSelected(serviceType: serviceType),
          // );
        } else {
          if (data.isspecial == null || !data.isspecial!) {
            // _bloc.add(
            //   ServiceTypeSelected(
            //       serviceType, state.serviceGroups![state.selected]),
            // );
          }
        }
        if (data.filterdata != null) {
          // Service Filter View
        } else {
          if (type == "shop") {
            conditionWidget();
          } else {
            if (type == "VEHICLE") {
              const payload = {
                dynamicProperty: {
                  psc: {
                    servicetype: data.querystr,
                    grouptype: data.grptype,
                    isshop: false,
                    sp: {
                      curStatename: position.district,
                      statename: position.district,
                      lati: position.latitude,
                      longi: position.longitude,
                    },
                  },
                  categorytype: data.querystr,
                  termquery: "",
                  filters: [],
                  facetparalist: [],
                  serviceproviderid: "",
                  isservice: false,
                  offset: 0,
                },
              } as IFetchProductFilterResultPayload;
              dispatch(fetchProductFilterResult(payload)).then(() => {
                navigate("/vehicle", { state: payload });
              });

              // VehiclesListView();
            } else {
              if (type == "REALESTATE") {
                dispatch(
                  fetchProductFilterResult({
                    dynamicProperty: {
                      psc: {
                        servicetype: data.querystr,
                        grouptype: data.grptype,
                        isshop: false,
                        sp: {
                          curStatename: position.district,
                          statename: position.district,
                          lati: position.latitude,
                          longi: position.longitude,
                        },
                      },
                      categorytype: data.querystr,
                      termquery: "",
                      filters: [],
                      facetparalist: [],
                      serviceproviderid: "",
                      isservice: false,
                    },
                  } as IFetchProductFilterResultPayload)
                ).then(() => {
                  navigate("/real-estate");
                });
              } else {
                if (type == "JOB") {
                  dispatch(
                    fetchProductFilterResult({
                      dynamicProperty: {
                        psc: {
                          servicetype: data.querystr,
                          grouptype: data.grptype,
                          isshop: false,
                          sp: {
                            curStatename: position.district,
                            statename: position.district,
                            lati: position.latitude,
                            longi: position.longitude,
                          },
                        },
                        categorytype: data.querystr,
                        termquery: "",
                        filters: [],
                        facetparalist: [],
                        serviceproviderid: "",
                        isservice: false,
                      },
                    } as IFetchProductFilterResultPayload)
                  ).then(() => {
                    navigate("/job");
                  });
                } else {
                  if (type == "PET") {
                    dispatch(
                      fetchProductFilterResult({
                        dynamicProperty: {
                          psc: {
                            servicetype: data.querystr,
                            grouptype: data.grptype,
                            isshop: false,
                            sp: {
                              curStatename: position.district,
                              statename: position.district,
                              lati: position.latitude,
                              longi: position.longitude,
                            },
                          },
                          categorytype: data.querystr,
                          termquery: "",
                          filters: [],
                          facetparalist: [],
                          serviceproviderid: "",
                          isservice: false,
                        },
                      } as IFetchProductFilterResultPayload)
                    ).then(() => {
                      navigate("/pet");
                    });
                  } else {
                    // ProductsClassifiedListView(prodtype: type ?? "")
                  }
                }
              }
            }
          }
        }
        // Navigator.of(context).push(
        //   MaterialPageRoute(
        //     builder: (_) => serviceType.filterdata != null
        //         ? ServiceFilterView(filterData: serviceType.filterdata)
        //         : type == 'shop'
        //             ? conditionWidget()
        //             : BlocProvider(
        //                 create: (_) => CartBloc(),
        //                 child: type == 'VEHICLE'
        //                     ? VehiclesListView()
        //                     : type == 'REALESTATE'
        //                         ? RealEstatesListView()
        //                         : type == 'JOB'
        //                             ? JobsListView()
        //                             : type == 'PET'
        //                                 ? PetsGridView()
        //                                 : ProductsClassifiedListView(prodtype: type ?? ""),
        //               ),
        //   ),
      }}
    >
      <Box
        component="img"
        src={data.icon}
        alt="logo"
        justifySelf="center"
        sx={{
          backgroundColor: "#ffffff",
          height: "100%",
          width: "auto",
          maxWidth: "100%",
          objectFit: "contain",
        }}
      />
      <Typography
        sx={{
          fontSize: "12px",
        }}
      >
        {data.servicetype ?? "Hello"}
      </Typography>
    </ProductPaper>
  );
};

import { Box, Stack, styled, useMediaQuery, useTheme } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DEVICE_TYPES } from "src/constants";
import {
  fetchShopResult,
  webPageDataRequest,
} from "src/slices/landing-page/thunks";
import { AppDispatch } from "src/store";
import Hero from "src/pages/landing-page/Hero";
import Footer from "./Footer";
import { HomePageForMarket } from "../home";
import School from "./School";
import Testimonial from "./Testimonial";
import Review from "./Reviews";
import { Category1 } from "./Category1";
import { Category2 } from "./Category2";
import {
  selectEntityId,
  selectIsConsolidateMarket,
  selectLsm,
  selectProdatypeinfo,
  selectShopAbout,
  selectShopHeroContent,
  selectShops,
  selectWebpageData,
} from "src/slices/landing-page/selectors";
import { useGeolocationContext } from "src/contexts/GeolocationContext";
import Management2 from "./Management2";
import { Deals1 } from "./Deals1";
import Features2 from "./Features2";
import { ShopProduct } from "./ShopProduct";
import { SpecialWidget } from "./SpecialWidget";
import { RentalsProductListSPView } from "./rental-product-list-sp-view";
import { DBLevelCategory } from "./ConsolidateCategory/DBLevelCategory";
import { StListCategory } from "./ConsolidateCategory/StListCategory";
import { Shops } from "./Shops";

const HeroBox = styled(Box)(() => ({
  position: "relative",
  height: "100%",
  background: "#eee",
  fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
  fontSize: "14px",
  color: "#000",
  margin: 0,
  padding: 0,
}));

export default function Index({ subdomain }) {
  const dispatch = useDispatch<AppDispatch>();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));
  let deviceType = isTablet ? DEVICE_TYPES.TABLET : DEVICE_TYPES.DESKTOP;
  deviceType = isMobile ? DEVICE_TYPES.MOBILE : DEVICE_TYPES.DESKTOP;

  const { updateGeoLocation, latitude, longitude } = useGeolocationContext();
  const entityId = useSelector(selectEntityId);
  const lsm = useSelector(selectLsm);

  const heroData = useSelector(selectShopHeroContent);
  const shopAbout = useSelector(selectShopAbout);
  const consolidateMarket = useSelector(selectIsConsolidateMarket);
  const wpd = useSelector(selectWebpageData);
  const prodatypeinfo = useSelector(selectProdatypeinfo);

  const shops = useSelector(selectShops);

  const [heroImages, setHeroImages] = useState(null);

  useEffect(() => {
    dispatch(
      webPageDataRequest({
        websitename: subdomain,
        action: "getdataforshop",
      })
    );
  }, []);

  useEffect(() => {
    if (shopAbout) {
      if (shopAbout.address) {
        updateGeoLocation(
          shopAbout.address.latitude,
          shopAbout.address.longitude,
          shopAbout.address.district
        );
      }
    }
  }, [shopAbout]);

  const Category = () => {
    if (!prodatypeinfo?.catlst?.split) {
      return <Category1 subdomain={subdomain} />;
    }
    return <Category2 subdomain={subdomain} />;
  };

  const getShops = useCallback(
    (name, dbllevel) => {
      if (name) {
        const st = consolidateMarket?.stlst?.filter(
          (st) => st.name === name
        )[0];
        const { loctype = "", state, district, country } = consolidateMarket;

        let payload = {};
        if (!dbllevel && latitude && longitude) {
          payload = {
            dynamicProperty: {
              // atyp: st.sid,
              servicetype: name?.toUpperCase(),
              // psc: {
              atyp: st.sid,
              lsm: lsm,
              cmi: {
                uselocal: true,
                loctype,
                state,
                district,
                country,
              },
              sp: {
                lati: latitude,
                longi: longitude,
                aii: {
                  district: lsm?.adm?.district,
                  state: lsm?.adm?.district,
                },
              },
              // },
              serviceproviderid: entityId,
              offset: 0,
              // isservice: false,
            },
          };
        } else {
          payload = {
            dynamicProperty: {
              // atyp: st.sid,
              servicetype: name?.toUpperCase(),
              // psc: {
              atyp: st.sid,
              lsm: lsm,
              cmi: {
                uselocal: false,
                selkey: dbllevel.key,
                selvalue: dbllevel.name,
                loctype,
                state,
                district,
                country,
              },
              sp: {
                lati: null,
                longi: null,
                aii: {
                  district: lsm?.adm?.district,
                  state: lsm?.adm?.state,
                },
              },
              // },
              serviceproviderid: entityId,
              offset: 0,
              // isservice: false,
            },
          };
        }
        dispatch(fetchShopResult(payload));
      }
    },
    [consolidateMarket, entityId, lsm, latitude, longitude]
  );

  const [selectedCat, setSelectedCat] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");

  useEffect(() => {
    if (consolidateMarket) {
      setSelectedCategory(consolidateMarket?.stlst[0]?.name ?? "");
    }
  }, [consolidateMarket]);

  useEffect(() => {
    if (selectedCategory) {
      getShops(selectedCategory, selectedCat);
    }
  }, [selectedCat, selectedCategory]);

  return (
    <Stack direction="column" gap="20px" sx={{ marginTop: "20px" }}>
      {prodatypeinfo && <Category />}
      {consolidateMarket && (
        <DBLevelCategory
          subdomain={subdomain}
          selectedCat={selectedCat}
          setSelectedCat={setSelectedCat}
        />
      )}
      {consolidateMarket && (
        <StListCategory
          subdomain={subdomain}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
        />
      )}

      {heroData?.imageList?.length > 0 && (
        <HeroBox>
          <Hero deviceType={deviceType} setHeroImages={setHeroImages} />
        </HeroBox>
      )}

      {/* <Box
        sx={{
          backgroundColor: "white",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: '5px'
        }}
      >
        <ShopUiRentalSpListFilter servicetype={'HOTELROOM'} />
      </Box> */}
      <Shops providers={shops} />
      <SpecialWidget />
      <ShopProduct />
      <RentalsProductListSPView />
      <HomePageForMarket />
      <Deals1 />
      <Features2 deviceType={deviceType} />
      <Management2 deviceType={deviceType} />
      <School deviceType={deviceType} subdomain={subdomain} />
      {!wpd?.ismarket && (
        <>
          <Testimonial deviceType={deviceType} />
          <Review deviceType={deviceType} />
        </>
      )}
      <Footer />
    </Stack>
  );
}

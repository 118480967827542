import {
  Grid,
  IconButton,
  Box as MaterialBox,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useRef } from "react";
import { useDisplaySettingsContext } from "src/contexts/DisplaySettings";
import { BoxProps, BoxContainerProps } from "src/types/box";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

function BoxContainer({
  type,
  direction = "row",
  gap = 3,
  children,
}: BoxContainerProps) {
  const ref = useRef();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const scroll = (scrollOffset) => {
    (ref.current as any).scrollLeft += scrollOffset;
  };

  return type === "flex" ? (
    <Stack direction="row" justifyContent="center">
      <IconButton
        sx={{ alignSelf: "center", marginRight: 2 }}
        onClick={() => scroll(!isMobile ? -300 : -100)}
      >
        <KeyboardArrowLeftIcon sx={{ fontSize: !isMobile ? "30px" : "20px" }} />
      </IconButton>

      <Stack
        direction={direction}
        gap={gap}
        ref={ref}
        // flexWrap={"wrap"}
        overflow="scroll"
        justifyContent={"left"}
        sx={{
          width: '90vw',
          "&::-webkit-scrollbar": {
            display: "none",
          },
          "-ms-overflow-style": "none",
          "scrollbar-width": "none",
          "scroll-behavior": "smooth",
        }}
      >
        {children}
      </Stack>

      <IconButton
        sx={{ alignSelf: "center", marginLeft: 2 }}
        onClick={() => scroll(!isMobile ? 300 : 100)}
      >
        <KeyboardArrowRightIcon
          sx={{ fontSize: !isMobile ? "30px" : "20px" }}
        />
      </IconButton>
    </Stack>
  ) : (
    <Grid container direction={direction} spacing={gap} ref={ref}>
      {children}
    </Grid>
  );
}

function Box({ id, title, subtitle, sx, ref, children }: BoxProps) {
  const { heading1, text3 } = useDisplaySettingsContext();
  return (
    <MaterialBox component={"div"} id={id} sx={{ ...sx }} ref={ref}>
      <Typography
        marginBottom={!subtitle ? 2 : 1}
        marginTop={1}
        textAlign={"left"}
        sx={{
          fontSize: heading1,
          fontWeight: 600,
          letterSpacing: "0em",
          marginLeft: '60px'
        }}
      >
        {title}
      </Typography>
      {subtitle && (
        <Typography
          margin={"auto"}
          component={"p"}
          marginBottom={2}
          textAlign={"center"}
          sx={{
            fontSize: text3,
            fontWeight: 400,
            letterSpacing: "0em",
            width: { xs: "90%", sm: "70%" },
          }}
        >
          {subtitle}
        </Typography>
      )}
      {children}
    </MaterialBox>
  );
}
Box.BoxContainer = BoxContainer;
export default Box;

import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import { format } from 'date-fns';
import { Form, Formik } from 'formik';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import FormikControl from 'src/components/formik/FormikControl';
import { useDisplaySettingsContext } from 'src/contexts/DisplaySettings';
import { useGeolocationContext } from 'src/contexts/GeolocationContext';
import { selectHistory } from 'src/slices/order-history/selectors';
import { getListDataEndUser, shareEndUserRating } from 'src/slices/order-history/thunks';
import { OrderHistoryData } from 'src/slices/order-history/types';
import { AppDispatch } from 'src/store';

import OrderCard from './order-card';
import RatingDialog from './rating-modal';

export function OrderHistory() {
  const dispatch = useDispatch<AppDispatch>();

  const navigate = useNavigate();
  const position = useGeolocationContext();
  const { heading2, heading3, textColor } = useDisplaySettingsContext();

  const historyState: OrderHistoryData = useSelector(selectHistory);
  const { dialog } = useSelector((state: any) => state.uiSettings);
  const formikRef = useRef(null);

  const [open, setOpen] = useState(false);
  const [docid, setDocid] = useState(null);
  return (
    <>
      <Formik
        initialValues={{
          curdatatype: "order",
          startDate: new Date(Date.now()),
          endDate: new Date(Date.now()),
        }}
        innerRef={formikRef}
        onSubmit={(values) => {
          dispatch(
            getListDataEndUser({
              type: values.curdatatype,
              sdt: values.startDate,
              edt: values.endDate,
            })
          );
        }}
      >
        {({ values, touched, errors, getFieldProps }) => (
          <Form>
            <Paper sx={{ p: 2 }}>
              <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      fontSize: heading3,
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    History
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormikControl
                    control="Radio"
                    showInRow
                    required
                    name="curdatatype"
                    error={Boolean(touched.curdatatype && errors.curdatatype)}
                    helperText={touched.curdatatype && errors.curdatatype}
                    {...getFieldProps("curdatatype")}
                    options={[
                      {
                        value: "order",
                        label: "Order",
                      },
                      {
                        value: "apt",
                        label: "Appointment",
                      },
                      {
                        value: "doc",
                        label: "Doctor",
                      },
                      {
                        value: "mt",
                        label: "MedicalTest",
                      },
                      {
                        value: "deli",
                        label: "Delivery",
                        disabled: true,
                      },
                      {
                        value: "ride",
                        label: "Rides",
                        disabled: true,
                      },
                      {
                        value: "payrec",
                        label: "Fee Receipt",
                        disabled: true,
                      },
                    ]}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormikControl
                    control="CalendarTime"
                    label="Start Date"
                    dateOrTimeOnly="date"
                    name="startDate"
                    error={Boolean(touched.startDate && errors.startDate)}
                    helperText={touched.startDate && errors.startDate}
                    {...getFieldProps("startDate")}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormikControl
                    control="CalendarTime"
                    label="End Date"
                    dateOrTimeOnly="date"
                    name="endDate"
                    error={Boolean(touched.endDate && errors.endDate)}
                    helperText={touched.endDate && errors.endDate}
                    {...getFieldProps("endDate")}
                  />
                </Grid>
                <Grid item>
                  <Button
                    sx={{
                      color: textColor,
                    }}
                    variant="contained"
                    type="submit"
                  >
                    Get Data
                  </Button>
                </Grid>
              </Grid>
            </Paper>
            {historyState ? (
              <Grid container spacing={1}>
                {values.curdatatype === "order" &&
                  historyState?.listorderdata?.length > 0 &&
                  historyState?.listorderdata.map((ord) => (
                    <Grid item xs={12} sm={6}>
                      <OrderCard
                        label1="Order Id"
                        value1={
                          ord.docid?.split("@")[
                            ord.docid?.split("@").length - 1
                          ] ?? ""
                        } // TODO: Get order id
                        label2="Date"
                        value2={
                          ord.odt ?? format(new Date("1970-01-01"), "dd-MM-y")
                        } // TODO: Get order date,
                        label3="Provider Name"
                        value3={ord.spname ?? "NotDefined"} // TODO: Get service provider name
                        label4="Phone Number"
                        value4={ord.phonenum ?? "Not Defined"}
                        total={ord.totalprice?.toString() ?? "NotDef"}
                        onRate={
                          ord.ratec === null
                            ? () => {
                                setDocid(ord.docid ?? "NotSet");
                                setOpen(true);
                              }
                            : null
                        }
                        tapEvent={() => {}}
                      />
                    </Grid>
                  ))}

                {values.curdatatype === "apt" &&
                  historyState?.listaptdata?.length > 0 &&
                  historyState?.listaptdata.map((apt) => (
                    <Grid item xs={12} sm={6}>
                      <OrderCard
                        label1="Service Provider Name"
                        value1={apt.spname ?? "Not Defined"}
                        label2="Phone Number"
                        value2={apt.phonenum ?? "Not Defined"}
                        label3="Date"
                        value3={`${
                          format(apt.odt * 1000, "d-M-y") ?? "Not Defined"
                        } - ${apt.period ?? "Not Defined"}`}
                        label4="Time"
                        value4={apt.period ?? "Not Defined"}
                        onRate={() => {}}
                        tapEvent={() => {}}
                      />
                    </Grid>
                  ))}

                {values.curdatatype === "doc" &&
                  historyState?.doctorcaselist?.length > 0 &&
                  historyState?.doctorcaselist.map((cv) => (
                    <Grid item xs={12} sm={6}>
                      <OrderCard
                        label1="Service Provider Name"
                        value1={cv.spname ?? "Not Defined"}
                        label2="Phone Number"
                        value2={cv.phonenum ?? "Not Defined"}
                        label3="First Visit Date"
                        value3={cv.startdate ?? ""}
                        label4="Last Visit Date"
                        value4={cv.lastvisitdate ?? ""}
                        onRate={() => {}}
                        tapEvent={() => {}}
                      />
                    </Grid>
                  ))}

                {values.curdatatype === "mt" &&
                  historyState?.medicalresultlist?.length > 0 &&
                  historyState?.medicalresultlist.map((mt) => (
                    <Grid item xs={12} sm={6}>
                      <OrderCard
                        label1="Service Provider Name"
                        value1={mt.spname ?? "Not Defined"}
                        label2="Phone Number"
                        value2={mt.phonenum ?? "Not Defined"}
                        label3="Order Date"
                        value3={mt.orderdate ?? ""}
                        label4="Test Name"
                        value4={
                          !mt.sometestnames
                            ? mt.sometestnames!.join(",")
                            : "Not Defined"
                        }
                        onRate={() => {}}
                        tapEvent={() => {}}
                      />
                    </Grid>
                  ))}
              </Grid>
            ) : (
              <>
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    height: "80vh",
                    margin: "0 auto",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography fontSize={heading2}>No Data Found</Typography>
                </Box>
              </>
            )}
          </Form>
        )}
      </Formik>
      <RatingDialog
        open={open}
        setOpen={setOpen}
        onRatingSubmitted={(ratingData) => {
          dispatch(
            shareEndUserRating({
              oid: docid,
              comment: ratingData.comment,
              rating: ratingData.rating,
            })
          ).then(() => {
            dispatch(
              getListDataEndUser({
                type: formikRef.current.values.curdatatype,
                sdt: formikRef.current.values.startDate,
                edt: formikRef.current.values.endDate,
              })
            );
          });
        }}
      />
    </>
  );
}

import {
  Box,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BookIcon from "src/icons/Book.png";
import StudentIcon from "src/icons/Student.png";
import Clock from "src/icons/Clock.png";
import Calendar from "src/icons/Calendar.png";
import CalendarBlank from "src/icons/CalendarBlank.png";
import { getBestFitImage } from "src/lib/utils";
import { selectGradeInfo } from "src/slices/landing-page/selectors";
import { getClassData } from "src/slices/landing-page/thunks";
import { AppDispatch } from "src/store";
import { useDisplaySettingsContext } from "src/contexts/DisplaySettings";
import Management3Card from "src/components/pages/landing-page/Management3Card";

const SchoolContainer = styled(Box)(() => ({
  backgroundColor: "white",
}));

const ClassButtonContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "20px",
  textAlign: "center",
  marginBottom: "42px",
}));

const ClassButton = styled("div")(() => ({
  height: "35px",
  border: "1px solid #65656533",
  flexGrow: 1,
  display: "flex",
  fontSize: "14px",
  fontWeight: 400,
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
}));

const ActiveButton = styled("div")(() => ({
  height: "35px",
  border: "1px solid #65656533",
  backgroundColor: "#00487A",
  flexGrow: 1,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "white",
  cursor: "pointer",
  fontSize: "14px",
  fontWeight: 400,
}));

const TypographyTitle = styled("div")(() => ({
  fontSize: "32px",
  fontWeight: 600,
  lineHeight: "38px",
}));

const TypographyHeading1 = styled(Typography)(() => ({
  fontSize: "18px",
  fontWeight: 600,
  lineHeight: "22px",
}));

const TypographyHeading2 = styled(Typography)(() => ({
  fontSize: "14px",
  fontWeight: 600,
  lineHeight: "19px",
}));

const TypographyParagraph = styled(Typography)(() => ({
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "22px",
}));

const School = ({ deviceType, subdomain }) => {
  const dispatch = useDispatch<AppDispatch>();
  const gradeInfo = useSelector(selectGradeInfo);
  const [selectedButton, setSelectedButton] = useState("");
  const [selectedSection, setSelectedSection] = useState("");
  const [selectedSession, setSelectedSession] = useState("");

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { heading1, heading2, text1, text2, text3 } =
    useDisplaySettingsContext();

  const onClickButton = (value) => {
    setSelectedButton(value);
    dispatch(getClassData({ websitename: subdomain, gradename: value }));
  };

  const onSelectSection = (value) => {
    setSelectedSection(value);
  };

  const onSessionSection = (value) => {
    setSelectedSession(value);
  };

  useEffect(() => {
    if (gradeInfo) {
      setSelectedButton(gradeInfo.selectedClass);
    }
  }, [gradeInfo]);

  return (
    <>
      {gradeInfo && (
        <SchoolContainer sx={{ padding: isMobile ? "15px" : "15px 50px" }}>
          <Typography
            marginBottom={1}
            marginTop={1}
            textAlign={"center"}
            sx={{
              fontSize: heading1,
              fontWeight: 600,
              letterSpacing: "0em",
            }}
          >
            Our Offering
          </Typography>
          {isMobile ? (
            <FormControl fullWidth sx={{ my: 2 }}>
              <InputLabel id="class-select-label">Class</InputLabel>
              <Select
                labelId="class-select-label"
                id="class-select"
                value={selectedButton}
                label="Class"
                onChange={(e) => {
                  onClickButton(e.target.value);
                }}
              >
                {gradeInfo?.classList?.map((cName) => (
                  <MenuItem value={cName}>{cName}</MenuItem>
                )) ?? []}
              </Select>
            </FormControl>
          ) : (
            <ClassButtonContainer
              sx={{
                fontSize: text3,
              }}
            >
              {gradeInfo?.classList?.map((className) => {
                const SelectedButton =
                  selectedButton === className ? ActiveButton : ClassButton;
                return (
                  <SelectedButton
                    onClick={() => {
                      onClickButton(className);
                    }}
                  >
                    {className}
                  </SelectedButton>
                );
              }) ?? []}
            </ClassButtonContainer>
          )}

          {/* <Divider sx={{ my: 5 }} /> */}
          {/* {gradeInfo?.sectionList?.length > 0 && (
            <>
              {isMobile ? (
                <FormControl fullWidth sx={{ my: 2 }}>
                  <InputLabel id="section-select-label">Section</InputLabel>
                  <Select
                    labelId="section-select-label"
                    id="section-select"
                    value={selectedSection}
                    label="Section"
                    onChange={(e) => {
                      onSelectSection(e.target.value);
                    }}
                  >
                    {gradeInfo?.sectionList?.map((sectionName) => (
                      <MenuItem value={sectionName}>{sectionName}</MenuItem>
                    )) ?? []}
                  </Select>
                </FormControl>
              ) : (
                <ClassButtonContainer gap={2}>
                  {gradeInfo?.sectionList?.map((sectionName) => {
                    const SelectedButton =
                      selectedSection === sectionName
                        ? ActiveButton
                        : ClassButton;
                    return (
                      <SelectedButton
                        onClick={() => {
                          onSelectSection(sectionName);
                        }}
                      >
                        {sectionName}
                      </SelectedButton>
                    );
                  }) ?? []}
                </ClassButtonContainer>
              )}
            </>
          )} */}
          {/* <Divider sx={{ my: 5 }} /> */}
          <Stack direction="row" gap={isMobile ? 1 : 2} flexWrap="wrap">
            <Stack direction="row" alignItems="center">
              <Box component="img" src={StudentIcon} alt="Student" />
              <TypographyHeading2 sx={{ m: 2, fontSize: text2 }}>
                Student:
              </TypographyHeading2>
              <TypographyParagraph fontSize={text3}>32</TypographyParagraph>
            </Stack>
            <Stack direction="row" alignItems="center">
              <Box component="img" src={BookIcon} alt="Book" />
              <TypographyHeading2 sx={{ m: 2, fontSize: text2 }}>
                Subject:
              </TypographyHeading2>
              <TypographyParagraph fontSize={text3}>
                {gradeInfo.mainSubjects?.join(", ") ?? "-"}
              </TypographyParagraph>
            </Stack>
            <Stack direction="row" alignItems="center">
              <Box component="img" src={BookIcon} alt="Book" />
              <TypographyHeading2 sx={{ m: 2, fontSize: text2 }}>
                Optional Subject:
              </TypographyHeading2>
              <TypographyParagraph fontSize={text3}>
                {gradeInfo.electiveSubjects?.join(", ") ?? "-"}
              </TypographyParagraph>
            </Stack>
          </Stack>
          {gradeInfo?.sessioninf && (
            <>
              {isMobile ? (
                <FormControl fullWidth sx={{ my: 2 }}>
                  <InputLabel id="session-select-label">Session</InputLabel>
                  <Select
                    labelId="session-select-label"
                    id="session-select"
                    value={selectedSession}
                    label="Session"
                    onChange={(e) => {
                      onSessionSection(e.target.value);
                    }}
                  >
                    {Object.keys(gradeInfo?.sessioninf)?.map((sessionName) => (
                      <MenuItem value={sessionName}>{sessionName}</MenuItem>
                    )) ?? []}
                  </Select>
                </FormControl>
              ) : (
                <ClassButtonContainer gap={2}>
                  {Object.keys(gradeInfo?.sessioninf)?.map((sessionName) => {
                    const SelectedButton =
                      selectedSession === sessionName
                        ? ActiveButton
                        : ClassButton;
                    return (
                      <SelectedButton
                        onClick={() => {
                          onSessionSection(sessionName);
                        }}
                      >
                        {sessionName}
                      </SelectedButton>
                    );
                  }) ?? []}
                </ClassButtonContainer>
              )}
            </>
          )}

          <Box
            sx={{
              padding: "2px",
              borderBottom: "1px solid #00487A",
              mb: "21px",
            }}
          >
            <TypographyTitle sx={{ color: "#00487A", fontSize: heading1 }}>
              Class Schedule
            </TypographyTitle>
          </Box>
          {gradeInfo?.sessioninf &&
            selectedSession &&
            gradeInfo?.sessioninf[selectedSession]?.map(
              (schedule) =>
                (
                  <Stack
                    direction="row"
                    alignItems="center"
                    sx={{ mb: "21px" }}
                  >
                    <TypographyHeading2
                      sx={{
                        textAlign: "left",
                        color: "#656565",
                        mr: 2,
                        fontSize: text3,
                      }}
                    >
                      Schedule:
                    </TypographyHeading2>
                    <Stack
                      direction="row"
                      gap={4}
                      overflow="scroll"
                      sx={{
                        "&::-webkit-scrollbar": {
                          display: "none",
                        },
                        "-ms-overflow-style": "none",
                        "scrollbar-width": "none",
                      }}
                    >
                      <Stack direction="row" alignItems="center" gap={1}>
                        <Box
                          component="img"
                          src={CalendarBlank}
                          alt="CalendarBlank"
                        />
                        <TypographyParagraph fontSize={text3}>
                          {schedule.sessionname}
                        </TypographyParagraph>
                      </Stack>
                      <Stack direction="row" alignItems="center" gap={1}>
                        <Box component="img" src={Calendar} alt="Calendar" />
                        <TypographyParagraph fontSize={text3}>
                          {schedule.info1}
                        </TypographyParagraph>
                      </Stack>
                      <Stack direction="row" alignItems="center" gap={1}>
                        <Box component="img" src={Clock} alt="Clock" />
                        <TypographyParagraph fontSize={text3}>
                          {schedule.info2}
                        </TypographyParagraph>
                      </Stack>
                    </Stack>
                  </Stack>
                ) ?? []
            )}
          {gradeInfo.openForAdmission && (
            <>
              <Stack direction="row" alignItems="center" sx={{ mb: "21px" }}>
                <TypographyHeading2
                  sx={{
                    textAlign: "left",
                    color: "#656565",
                    mr: 1,
                    fontSize: text2,
                  }}
                >
                  Admission:
                </TypographyHeading2>
                <ActiveButton
                  sx={{
                    flexGrow: 0,
                    width: "90px",
                    height: "28px",
                    border: 0,
                    color: "white",
                    fontSize: text3,
                    fontWeight: 600,
                  }}
                >
                  Open
                </ActiveButton>
              </Stack>

              {gradeInfo.openForAdmissionInfo && (
                <Stack direction={isMobile ? "column" : "row"}>
                  <TypographyHeading2
                    sx={{
                      color: "#656565",
                      mr: 1,
                      fontSize: text2,
                    }}
                  >
                    Info:
                  </TypographyHeading2>
                  <TypographyParagraph
                    sx={{
                      color: "#656565",
                      fontSize: text3,
                    }}
                  >
                    {gradeInfo.openForAdmissionInfo}
                  </TypographyParagraph>
                </Stack>
              )}
            </>
          )}

          {/** Classroom photos commented */
          /* <Stack
            direction="row"
            gap={isMobile ? 1 : 4}
            overflow="scroll"
            sx={{
              "&::-webkit-scrollbar": {
                display: "none",
              },
              "-ms-overflow-style": "none",
              "scrollbar-width": "none",
            }}
          >
            {gradeInfo?.classPhotoLst?.map((cls) => (
              <Box>
                <Box
                  component="img"
                  src={
                    getBestFitImage(deviceType, cls.imageList)?.value ?? null
                  }
                  alt="croom"
                  sx={{
                    width: "100%",
                    height: "400px",
                  }}
                />
              </Box>
            ))}
          </Stack> */}
          {gradeInfo?.teachersList?.length > 0 && (
            <>
              <Box
                sx={{
                  padding: "2px",
                  borderBottom: "1px solid #00487A",
                  mb: "21px",
                }}
              >
                <TypographyTitle sx={{ color: "#00487A", fontSize: heading1 }}>
                  Faculty
                </TypographyTitle>
              </Box>
              <Stack
                direction="row"
                gap="21px"
                justifyContent="space-evenly"
                overflow="scroll"
                sx={{
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                  "-ms-overflow-style": "none",
                  "scrollbar-width": "none",
                }}
              >
                {gradeInfo?.teachersList?.map((teacher) => (
                  <Management3Card
                    image={{
                      url: getBestFitImage(deviceType, teacher.imageList)
                        ?.value,
                      alt: "image",
                    }}
                    title={teacher.name}
                    subtitle1={teacher.education}
                    subtitle2={teacher.info}
                  />
                  // <Stack direction="column" sx={{ width: "200px" }}>
                  //   <Box
                  //     component="img"
                  //     src={
                  //       getBestFitImage(deviceType, teacher.imageList)?.value ??
                  //       null
                  //     }
                  //     alt="croom"
                  //     sx={{
                  //       width: "200px",
                  //       height: "300px",
                  //     }}
                  //   />
                  //   <Box
                  //     sx={{
                  //       padding: "2px",
                  //       borderBottom: "1px solid #00487A",
                  //       my: 1,
                  //     }}
                  //   >
                  //     <TypographyHeading1
                  //       sx={{ textAlign: "center", fontSize: text1 }}
                  //     >
                  //       {teacher.name}
                  //     </TypographyHeading1>
                  //   </Box>
                  //   <Stack direction="row">
                  //     <TypographyHeading2
                  //       sx={{
                  //         color: "#00487A",
                  //         mr: 2,
                  //         flex: 1,
                  //         fontSize: text3,
                  //       }}
                  //     >
                  //       Education:
                  //     </TypographyHeading2>
                  //     <TypographyParagraph
                  //       sx={{
                  //         color: "#656565",
                  //         flex: 2,
                  //         fontSize: text3,
                  //       }}
                  //     >
                  //       {teacher.education}
                  //     </TypographyParagraph>
                  //   </Stack>
                  //   <Stack direction="row">
                  //     <TypographyHeading2
                  //       sx={{
                  //         color: "#00487A",
                  //         mr: 2,
                  //         flex: 1,
                  //         fontSize: text3,
                  //       }}
                  //     >
                  //       Details:
                  //     </TypographyHeading2>
                  //     <TypographyParagraph
                  //       sx={{
                  //         color: "#656565",
                  //         flex: 2,
                  //         fontSize: text3,
                  //       }}
                  //     >
                  //       {teacher.info}
                  //     </TypographyParagraph>
                  //   </Stack>
                  // </Stack>
                )) ?? []}
              </Stack>
            </>
          )}
        </SchoolContainer>
      )}
    </>
  );
};

export default School;

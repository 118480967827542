import { createSlice } from '@reduxjs/toolkit';
import { differenceInDays } from 'date-fns';
import { getRateForStartDateForRateRule } from 'src/constants';
import * as CONSTANTS from 'src/constants';

import {
  addedProductCartEvent,
  cartCheckout,
  changedQuantityCartEvent,
  getRentalProductInventoryStatus,
  saveUserAddress,
} from './thunks';
import { CartOrigin, ICartSlice, ICartState, longanddouble, productinorder } from './types';

const getPeriodDiscount = (
  periodDiscounts: longanddouble[],
  selectedPeriod: number
): number => {
  periodDiscounts.sort(function (a, b) {
    return a[1] - b[1];
  });
  for (let i = periodDiscounts.length - 1; i >= 0; i--) {
    if (periodDiscounts[i].name! <= selectedPeriod) {
      return 1 - (periodDiscounts[i].value ?? 0) / 100;
    }
  }
  return 1;
};

export const cartSlice = createSlice({
  name: "cart",
  initialState: {
    cartState: {
      cart: {
        productlist: [],
        totalcalculatedprice: 0,
        totaltax: 0,
        askedprice: 0,
        orderstate: "PENDING",
        custname: null,
        custphonenum: null,
        customerid: null,
      },
      // calledfrom: CartOrigin.NotSP,
      calledfrom: CartOrigin.EcomEndUser,
    },
    isError: false,
    isLoading: false,
  } as ICartSlice,
  reducers: {
    resetCartState(state): void {
      state.cartState = {
        cart: {
          productlist: [],
          totalcalculatedprice: 0,
          totaltax: 0,
          askedprice: 0,
          orderstate: "PENDING",
          custname: null,
          custphonenum: null,
          customerid: null,
        },
        // calledfrom: CartOrigin.NotSP,
        calledfrom: CartOrigin.EcomEndUser,
      } as ICartState;
    },
    setCartItem(state, action): void {
      state.cartState.cart = action.payload;
    },
    updateCartState(state, action): void {
      state.cartState = { ...state.cartState, ...action.payload };
    },
    addCustomerInfo(state, action): void {
      state.cartState.cart = { ...state.cartState.cart, ...action.payload };
      // var newcart =cart.copyWith(custname: event.custname,cusphotoid: event.photoid,custaddress:event.address !=null ?AddressDataModel(caddr:event.address):null,custphonenum: event.phone,addtoledger: event.addtoledger );
    },
    addToLedgerOrUpdateMasterOrderId(state, action): void {
      if (action.payload.type == "ledger") {
        state.cartState.cart = {
          ...state.cartState.cart,
          addtoledger: action.payload.addtoledger,
        };
        // yield state.copyWith(
        //   cart: state.cart?.copyWith(addtoledger: event.addtoledger),
        // );
      } else {
        state.cartState.cart = {
          ...state.cartState.cart,
          masterorder: action.payload.masterorderid,
        };
        // yield state.copyWith(
        //   cart: state.cart?.copyWith(masterorder: event.masterorderid),
        // );
      }
    },
    calculateupdatedprice(state, action): void {
      let duration = 1;
      if (action.payload.sdt !== null && action.payload.edt !== null) {
        duration = differenceInDays(action.payload.edt, action.payload.sdt);
      }
      const updatedProdList: productinorder[] = [];
      for (var e of action.payload.prodList) {
        let singledayprice = 0.0;
        let perioddis = 1.0;
        if (e.ratecard) {
          singledayprice = getRateForStartDateForRateRule(
            e.ratecard,
            action.payload.sdt,
            action.payload.edt
          );
          if (e.ratecard!.perioddiscountlst) {
            perioddis = getPeriodDiscount(
              e.ratecard!.perioddiscountlst!,
              duration
            );
          }
        } else {
          if (e.discountedprice != null) {
            singledayprice = e.discountedprice ?? 0;
          } else {
            singledayprice = parseFloat(e.unitprice ?? "0") ?? 0;
          }
          if (e.perioddiscountlst != null) {
            perioddis = getPeriodDiscount(e.perioddiscountlst!, duration);
          }
        }

        let ww = { ...e, totalprice: singledayprice * perioddis * duration };
        updatedProdList.push(ww);
      }
      let totalCalcltd = 0;
      if (updatedProdList.length > 0)
        totalCalcltd = updatedProdList
          .map((e) => e.totalprice! * e.qty!)
          .reduce((value, element) => value + element);

      state.cartState.cart.productlist = updatedProdList;
      state.cartState.cart.sdate = action.payload.sdt;
      state.cartState.cart.edate = action.payload.edt;
      state.cartState.cart.totalcalculatedprice = totalCalcltd;
      state.cartState.cart.askedprice = totalCalcltd;
      state.cartState.sdt = action.payload.sdt;
      state.cartState.edt = action.payload.edt;
    },
    getUserAddresses(state): void {
      if (
        state.cartState.calledfrom === CartOrigin.AppointmentEndUser ||
        state.cartState.calledfrom === CartOrigin.EcomEndUser
      ) {
        state.cartState.useraddressloaded = true;
      }
      //owner side, customer address is loaded as part of customer search
      else {
        state.cartState.useraddressloaded = true;
      }
    },
    fetchedCartEventWithSP(state, action): void {
      if (action.payload.sdt === null || action.payload.edt === null) {
        state.cartState.readyfordetailorigin = true;
        state.cartState.prodtype = action.payload.prodtype;
        state.cartState.entitytype = action.payload.entitytype;
        state.cartState.entityid = action.payload.entityid;
        state.cartState.calledfrom = action.payload.calledfrom;
        state.cartState.lsm = action.payload.lsm;
        state.cartState.cart.oorigin = action.payload.calledfrom.toString();
        state.cartState.cart.rtype = action.payload.prodtype;
      } else {
        state.cartState.readyfordetailorigin = true;
        state.cartState.prodtype = action.payload.prodtype;
        state.cartState.entitytype = action.payload.entitytype;
        state.cartState.entityid = action.payload.entityid;
        state.cartState.calledfrom = action.payload.calledfrom;
        state.cartState.lsm = action.payload.lsm;
        state.cartState.cart.oorigin = action.payload.calledfrom.toString();
        state.cartState.cart.rtype = action.payload.prodtype;
        state.cartState.cart.sdate = action.payload.sdt;
        state.cartState.cart.edate = action.payload.edt;
      }
    },
    changedQuantityCartEvent(state, action): void {
      if (
        action.payload.fromProductsList !== null &&
        action.payload.fromProductsList!
      ) {
        let products = [...state.cartState.cart!.productlist!];
        let index = products.findIndex(
          (product) => product.itemid === action.payload.product!.itemid
        );
        let oldQuantity = products[index].qty!;
        // let oldProductPrice = products[index].totalprice!;
        // let pr = products[index];
        // let np = action.payload.product;

        if (
          products[index].itemid == action.payload.product!.itemid &&
          products[index].totalprice == action.payload.product!.totalprice &&
          products[index].unit == action.payload.product!.unit
        ) {
          let newq = oldQuantity + 1;
          let newprod = { ...products![index], qty: newq };
          let updatedProducts = [
            ...products.slice(0, index),
            ...products.slice(index + 1),
          ];
          updatedProducts = [
            ...updatedProducts.slice(0, index),
            newprod,
            ...updatedProducts.slice(index),
          ];
          calculateupdatedprice({
            prodList: updatedProducts ?? [],
            sdt: state.cartState.cart!.sdate,
            edt: state.cartState.cart!.edate,
            state,
          });
        } else {
          let updatedProducts = [
            ...products.slice(0, index),
            ...products.slice(index + 1),
          ];
          if (action.payload.newQuantity! > 0)
            updatedProducts = [
              ...updatedProducts.slice(0, index),
              action.payload.product!,
              ...updatedProducts.slice(index),
            ];
          calculateupdatedprice({
            prodList: updatedProducts ?? [],
            sdt: state.cartState.cart!.sdate,
            edt: state.cartState.cart!.edate,
            state,
          });
        }
      } else {
        let products = [...state.cartState.cart!.productlist!];
        let index = products.findIndex(
          (product) => product.itemid === action.payload.product!.itemid
        );
        // let oldQuantity = products[index].qty!;
        let updatedProducts = products.filter((_, i) => i !== index);
        if (action.payload.newQuantity! > 0) {
          const newelement = {
            ...action.payload.product,
            qty: action.payload.newQuantity,
          };
          updatedProducts.splice(index, 0, newelement);
        }
        calculateupdatedprice({
          prodList: updatedProducts ?? [],
          sdt: state.cartState.cart!.sdate,
          edt: state.cartState.cart!.edate,
          state,
        });
      }
    },
    setCheckoutInitState(state, action): void {
      state.cartState.cart.orderdate = CONSTANTS.toTimeStamp(
        new Date(Date.now())
      );
      state.cartState.cart.custaddress = action.payload.adm;
      state.cartState.cart.finalprice = action.payload.finalprice;
      state.cartState.cart.amountpaid = action.payload.amountpaid;
      state.cartState.cart.reqdel = action.payload.delmech;
    },
    setCheckoutFinalState(state, action): void {
      if (action.payload.error) {
      } else {
        state.cartState.cart = action.payload.cart;
        state.cartState.ordersaved = action.payload.ordersaved;
      }
    },
  },
  extraReducers: (builder) => {
    // saveUserAddress
    builder.addCase(saveUserAddress.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(saveUserAddress.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.data?.errorid === -1) {
        state.cartState.custaddresslist = action.payload.data.useraddresslist;
      }
    });
    builder.addCase(saveUserAddress.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    // addedProductCartEvent
    builder.addCase(addedProductCartEvent.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addedProductCartEvent.fulfilled, (state, _) => {
      state.isLoading = false;
    });
    builder.addCase(addedProductCartEvent.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    // changedQuantityCartEvent
    builder.addCase(changedQuantityCartEvent.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(changedQuantityCartEvent.fulfilled, (state, _) => {
      state.isLoading = false;
    });
    builder.addCase(changedQuantityCartEvent.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    // changedQuantityCartEvent
    builder.addCase(cartCheckout.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(cartCheckout.fulfilled, (state, _) => {
      state.isLoading = false;
    });
    builder.addCase(cartCheckout.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });

    // getRentalProductInventoryStatus
    builder.addCase(getRentalProductInventoryStatus.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getRentalProductInventoryStatus.fulfilled, (state, _) => {
      state.isLoading = false;
    });
    builder.addCase(getRentalProductInventoryStatus.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });
  },
});

export const {
  setCartItem,
  updateCartState,
  getUserAddresses,
  fetchedCartEventWithSP,
  calculateupdatedprice,
  setCheckoutInitState,
  setCheckoutFinalState,
  resetCartState,
  // addedProductCartEvent,
} = cartSlice.actions;
export const { reducer } = cartSlice;

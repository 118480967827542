import { useState, useEffect } from "react";
import { ILatLng } from "src/slices/cart/types";

export const useCurrentLocation = () => {
  const [currentLocation, setCurrentLocation] = useState<ILatLng>({
    latitude: 0,
    longitude: 0,
  });

  useEffect(() => {
    getCurrentLocation();
  }, []);

  const getCurrentLocation = () => {
    if (currentLocation.latitude === 0 && currentLocation.longitude === 0) {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setCurrentLocation({ latitude, longitude });
          },
          (_) => {
            setCurrentLocation({
              latitude: 0,
              longitude: 0,
            });
          }
        );
      } else {
        setCurrentLocation({
          latitude: 0,
          longitude: 0,
        });
      }
    }
  };

  return currentLocation;
};

import * as React from "react";
import { Box, TextField, Button, Typography, Grid, Paper } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  // initializeRoom,
  initializeTrafficRoom,
} from "src/slices/chat/thunks";
import { AppDispatch } from "src/store";
import {
  addDoc,
  collection,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  where,
} from "firebase/firestore";
import { auth, db, mychatdb1 } from "src/configs/firebase";
import {
  selectFclState,
  selectRoomStatusState,
} from "src/slices/chat/selectors";
import { useDisplaySettingsContext } from "src/contexts/DisplaySettings";

export const Chat = () => {
  const { state } = useLocation();
  const {
    cmagg,
    croi,
    fromChatlist,
    username,
    uid,
    newchatreq,
    usertraffic = false,
  } = state;

  console.log("auth", auth.currentUser);

  const fcl = useSelector(selectFclState);
  const roomStatus = useSelector(selectRoomStatusState);

  const { textColor } = useDisplaySettingsContext();

  const [messages, setMessages] = useState([
    // { id: "1", text: "Hi there!", sender: "bot" },
    // { id: '2', text: "Hello!", sender: "user" },
    // { id: '3', text: "How can I assist you today?", sender: "bot" },
  ]);

  const dispatch = useDispatch<AppDispatch>();
  const [input, setInput] = React.useState("");

  const handleSend = async () => {
    if (input.trim() !== "") {
      console.log(roomStatus);
      const { uid } = auth.currentUser;
      if (newchatreq!.pid) {
        const res = await addDoc(
          collection(mychatdb1, `CROOM/${fcl.roomid}/MESSAGES`),
          {
            msg: JSON.stringify({ type: 0, msg: input.trim() }),
            from: (auth.currentUser.displayName ?? "NoName") + "(U)",
            to: "",
            oid: auth.currentUser.uid,
            rid: newchatreq?.pid + "@" + auth.currentUser.uid,
            version: 0,
            servertimestamps: serverTimestamp(),
          }
        );
      } else {
        const res = await addDoc(
          collection(mychatdb1, `CROOM/${fcl.roomid}/MESSAGES`),
          {
            msg: JSON.stringify({ type: 0, msg: input.trim() }),
            from: (auth.currentUser.displayName ?? "NoName") + "(U)",
            to: "",
            oid: auth.currentUser.uid,
            rid: auth.currentUser.uid,
            version: 0,
            servertimestamps: serverTimestamp(),
          }
        );
      }
      // setMessages([
      //   ...messages,
      //   { id: messages.length + 1, text: input.trim(), sender: "user" },
      // ]);
      setInput("");
    }
  };

  const handleInputChange = (event) => {
    setInput(event.target.value);
  };

  useEffect(() => {
    console.log(newchatreq);
    console.log(uid);
    console.log(auth);
    console.log(usertraffic);

    // if (fromChatlist) {
    //   dispatch(
    //     initializeRoom({
    //       cm: cmagg!,
    //       userid: uid,
    //       usertraffic: usertraffic,
    //     })
    //   );
    // } else {
    // if (usertraffic) {
    dispatch(
      initializeTrafficRoom({
        ccr: newchatreq,
        userid: uid,
        usertraffic: usertraffic,
      })
    );
    // }
    // }
  }, []);

  useEffect(() => {
    const starttime: Date = new Date();
    const tenminago: Date = new Date(starttime.getTime() - 10 * 60 * 1000); // Subtract 10 minutes in milliseconds

    let q: any = "";
    if (newchatreq!.pid) {
      q = query(
        collection(mychatdb1, `CROOM/${fcl?.roomid}/MESSAGES`),
        where("rid", "==", newchatreq?.pid + "@" + auth.currentUser.uid),
        // where('servertimestamps', '>', tenminago),
        orderBy("servertimestamps")
      );
    } else {
      q = query(
        collection(mychatdb1, `CROOM/${fcl?.roomid}/MESSAGES`),
        where("rid", "==", auth.currentUser.uid),
        // where('servertimestamps', '>', tenminago),
        orderBy("servertimestamps")
      );
    }

    const unsubscribes = onSnapshot(q, (snapshot) => {
      setMessages(
        snapshot.docs.map((doc) => ({
          id: doc.id,
          text: JSON.parse(doc.data().msg).msg,
          sender: doc.data().from,
        }))
      );
    });
    return unsubscribes;
  }, [fcl]);

  console.log(usertraffic);

  const Message = ({ message }) => {
    const t = auth.currentUser.uid;
    const isMine = !message.sender.endsWith("(U)");

    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: isMine ? "flex-start" : "flex-end",
          mb: 2,
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: isMine ? "start" : "end",
          }}
        >
          <Typography
            sx={{ fontSize: "10px", ml: isMine ? 1 : 0, mr: isMine ? 0 : 1 }}
          >
            {message.sender}
          </Typography>
          <Paper
            variant="outlined"
            sx={{
              p: 2,
              color: isMine ? "white" : "primary.main",
              backgroundColor: isMine ? "primary.main" : "white",
              borderRadius: isMine
                ? "20px 20px 20px 5px"
                : "20px 20px 5px 20px",
            }}
          >
            <Typography sx={{ fontSize: "15px" }}>{message.text}</Typography>
          </Paper>
        </Box>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        height: "82vh",
        display: "flex",
        flexDirection: "column",
        bgcolor: "grey.200",
      }}
    >
      <Box sx={{ flexGrow: 1, overflow: "auto", p: 2 }}>
        {messages.map((message) => (
          <Message key={message.id} message={message} />
        ))}
      </Box>
      <Box sx={{ p: 2, backgroundColor: "background.default" }}>
        <Grid container spacing={2}>
          <Grid item xs={10}>
            <TextField
              fullWidth
              size="small"
              placeholder="Type a message"
              variant="outlined"
              value={input}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              sx={{
                color: textColor,
              }}
              fullWidth
              size="large"
              variant="contained"
              endIcon={<SendIcon />}
              onClick={handleSend}
            >
              Send
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

import { Box, Checkbox, Grid } from "@mui/material";
import { Formik } from "formik";
import { FC, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CModal from "src/components/CModal";
import CTypography from "src/components/CTypography";
import CButton from "src/components/button-group/CButton";
import { AddEditAddressDialog } from "src/components/dialogs/add-edit-address-dialog";
import FormikControl from "src/components/formik/FormikControl";
import { useDisplaySettingsContext } from "src/contexts/DisplaySettings";
import { getUserAddresses } from "src/slices/cart";
import { selectCartState } from "src/slices/cart/selectors";
import {
  AddressDataModel,
  ICartState,
  UserSavedAddress,
} from "src/slices/cart/types";
import { setDialog } from "src/slices/uiSettingsSlice";
import { AppDispatch } from "src/store";

interface IDeliveryMethod {
  reqDelivery: boolean;
  onUpdateDeliveryMethod: (isReqDelivery: boolean) => void;
  onSelectDeliveryAddress: (adm?: AddressDataModel) => void;
  selectedaddressmodel?: AddressDataModel;
}

export const DeliveryMethod: FC<IDeliveryMethod> = ({
  reqDelivery,
  onUpdateDeliveryMethod,
  onSelectDeliveryAddress,
  selectedaddressmodel,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const formikRef = useRef(null);

  const [
    actionButtonsAddEditAddressDialog,
    setActionButtonsAddEditAddressDialog,
  ] = useState([]);

  const { dialog } = useSelector((state: any) => state.uiSettings);
  const selectedCartState: ICartState = useSelector(selectCartState);

  const [selectedAddressIndex, setSelectedAddressIndex] = useState(-1);

  const [editAddress, setEditAddress] = useState<UserSavedAddress>(null);

  const { text1, text2, text3 } = useDisplaySettingsContext();
  // const [myaddress, setMyaddress] = useState<UserSavedAddress[]>([
  // {
  //   shortname: "add",
  //   adm: {
  //     country: "Ethiopia",
  //     state: "Amhara",
  //     district: "East Gojjam",
  //     town_village: "Addis",
  //     area_sector: "hshs",
  //     societyname: "hdhs",
  //     addressline: "F53X+753",
  //     latitude: 10.4525436,
  //     longitude: 38.1977737,
  //     pincode: null,
  //     caddr: null,
  //   },
  // },
  // {
  //   shortname: "add 2",
  //   adm: {
  //     country: "Ethiopia",
  //     state: "Amhara",
  //     district: "East Gojjam",
  //     town_village: "Addis",
  //     area_sector: "hshs",
  //     societyname: "hdhs",
  //     addressline: "F53X+753 2",
  //     latitude: 10.4525436,
  //     longitude: 38.1977737,
  //     pincode: null,
  //     caddr: null,
  //   },
  // },
  // ]);

  useEffect(() => {
    dispatch(getUserAddresses());
  }, []);

  const handleRadioChange = ({ target }: any): void => {
    onUpdateDeliveryMethod(target.value === "ReqDelivery");
  };

  return (
    <>
      <Formik
        initialValues={{
          selection: "SelfPickup",
          address: "0",
        }}
        innerRef={formikRef}
        onSubmit={(_) => {}}
      >
        {({ values, touched, errors, getFieldProps }) => (
          <Grid
            container
            sx={{
              border: "1px solid black",
              padding: 1,
              borderRadius: "5px",
            }}
            spacing={1}
          >
            <Grid item xs={12}>
              <FormikControl
                control="Radio"
                showInRow
                required
                name="selection"
                handleChange={(value) => handleRadioChange(value)}
                error={Boolean(touched.selection && errors.selection)}
                helperText={touched.selection && errors.selection}
                {...getFieldProps("selection")}
                options={[
                  { value: "ReqDelivery", label: "Req Delivery" },
                  { value: "SelfPickup", label: "Self Pickup" },
                ]}
              />
            </Grid>
            {reqDelivery ? (
              <>
                <Grid item xs={12} textAlign="center">
                  <CButton
                    onClick={() => {
                      dispatch(
                        setDialog({
                          open: true,
                          dialogName: "AddEditAddress",
                        })
                      );
                    }}
                  >
                    + Add New Address
                  </CButton>
                </Grid>

                {selectedCartState.custaddresslist ? (
                  selectedCartState.custaddresslist.map(
                    (data: UserSavedAddress, index) =>
                      data.adm ? (
                        <Grid item xs={12}>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              border: "1px solid black",
                              p: 1,
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Box
                              sx={{
                                width: "60%",
                              }}
                            >
                              <CTypography fontSize={text1}  color="primary">
                                Address {index + 1}
                              </CTypography>
                              <CTypography fontSize={text2}>
                                {data.adm.addressline}{" "}
                              </CTypography>
                            </Box>
                            <Box>
                              <Checkbox
                                color="primary"
                                checked={selectedAddressIndex === index}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setSelectedAddressIndex(index);
                                    onSelectDeliveryAddress(data.adm);
                                  } else {
                                    setSelectedAddressIndex(-1);
                                    onSelectDeliveryAddress(null);
                                  }
                                }}
                              />
                              <CButton
                                onClick={() => {
                                  setEditAddress(data);
                                  dispatch(
                                    setDialog({
                                      open: true,
                                      dialogName: "AddEditAddress",
                                    })
                                  );
                                }}
                                sx={{fontSize: text3 }}
                              >
                                Edit
                              </CButton>
                            </Box>
                          </Box>
                        </Grid>
                      ) : (
                        <></>
                      )
                  )
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
          </Grid>
        )}
      </Formik>

      {dialog.AddEditAddress !== undefined && dialog.AddEditAddress ? (
        <CModal
          dialogName="AddEditAddress"
          title={"Add New Address"}
          maxWidth="md"
          showSaveButton={true}
          handleSaveButtonText="Add/Edit"
          actionButtons={actionButtonsAddEditAddressDialog}
          handleCancel={() => {
            setEditAddress(null);
          }}
          handleSave={() => {
            console.log("editAddress", editAddress);
          }}
          open={
            dialog.AddEditAddress === undefined ? false : dialog.AddEditAddress
          }
          content={
            <AddEditAddressDialog
              dialogName="AddEditAddress"
              selectedAddress={editAddress}
              actionButtons={actionButtonsAddEditAddressDialog}
              setActionButtons={setActionButtonsAddEditAddressDialog}
            />
          }
        />
      ) : null}
    </>
  );
};

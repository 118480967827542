import React, { useState, useCallback, useEffect } from "react";
import { GoogleMap, MarkerF, useJsApiLoader } from "@react-google-maps/api";
import MapProps from "src/types/map";

export default function Map({
  width,
  height,
  coordinates,
  zoomLevel = 15,
}: MapProps) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  });

  const [map, setMap] = React.useState(null);
  const [markerPosition, setMarkerPosition] = useState(coordinates);

  const [geolocation, setGeolocation] = useState(coordinates);

  const onLoad = useCallback((map) => {
    setMap(map);
  }, []);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  useEffect(() => {
    if (map) {
      setMarkerPosition(geolocation);
    }
  }, [geolocation, map]);

  return isLoaded ? (
    <GoogleMap
      onLoad={onLoad}
      center={geolocation}
      zoom={zoomLevel}
      mapContainerStyle={{ width, height }}
      onUnmount={onUnmount}
    >
      <MarkerF position={markerPosition} />
    </GoogleMap>
  ) : null;
}

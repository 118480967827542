import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDisplaySettingsContext } from "src/contexts/DisplaySettings";
import {
  selectEntityId,
  selectSelectedAtypeCategory,
  selectLsm,
} from "src/slices/landing-page/selectors";
import { fetchShopProductFilterResult } from "src/slices/landing-page/thunks";
import { AppDispatch } from "src/store";

export const SubCategoryButton = ({
  name,
  selectedCategory,
  selectedSubCategory,
  setSelectedSubCategory,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  
  const { text2, foregroundColor } = useDisplaySettingsContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const isSelected = name === selectedSubCategory;

  const entityId = useSelector(selectEntityId);
  const atype = useSelector(selectSelectedAtypeCategory);
  const lsm = useSelector(selectLsm);

  const getCategoryName = useCallback(
    (name, subName) => {
      if (atype === "PET") {
        return `PET/${name}/${subName}`;
      }
      if (atype === "REALESTATE") return `REALESTATE/${name}/${subName}`;
    },
    [atype]
  );

  const getCategoryData = useCallback(
    (name) => {
      dispatch(
        fetchShopProductFilterResult({
          dynamicProperty: {
            atyp: atype,
            offset: 0,
            categorytype: name, //'VEHICLE/BUY/Motorcycle',
            psc: {
              atyp: atype,
              isshop: true,
              lsm: lsm,
            },
            termquery: "",
            serviceproviderid: entityId,
            isservice: false,
          },
        })
      );
    },
    [atype, entityId, lsm]
  );

  return (
    <>
      <Box
        onClick={() => {
          getCategoryData(getCategoryName(selectedCategory, name));
          setSelectedSubCategory(name);
        }}
        sx={{
          minWidth: isMobile ? '100px' : "150px",
          padding: '4px',
          borderRadius: "16px",
          gap: "4px",
          backgroundColor: isSelected ? foregroundColor : "white",
          border: "1px solid #65656566",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <Typography
          sx={{
            fontSize: text2,
            fontWeight: 600,
            color: isSelected ? "white" : foregroundColor,
          }}
        >
          {name}
        </Typography>
      </Box>
    </>
  );
};

import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`customer-details-tabpanel-${index}`}
      aria-labelledby={`customer-details-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ py: 2 }}>{children}</Box>}
    </div>
  );
}

function tabProps(index: number) {
  return {
    id: `customer-details-tab-${index}`,
    "aria-controls": `customer-details-tabpanel-${index}`,
  };
}

interface CTabProps {
  label: string;
  component: any;
}

interface CTabViewProps {
  data: CTabProps[];
}

export const CTabView: React.FC<CTabViewProps> = ({ data }) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleChange}>
          {data.map((tabData, index) => (
            <Tab label={tabData.label} {...tabProps(index)} />
          ))}
        </Tabs>
      </Box>
      {data.map((tabData, index) => (
        <TabPanel value={value} index={index}>
          {tabData.component}
        </TabPanel>
      ))}
    </Box>
  );
};

import { FC } from "react";
import PropTypes from "prop-types";
import { Field, useFormikContext } from "formik";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
  styled,
} from "@mui/material";

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: "300px",
    },
  },
};

const InputLabelStyled = styled(InputLabel)(({ theme, className }) => ({
  transform:
    className === "small"
      ? `translate(14px, ${theme.spacing(1)}) scale(1)`
      : `translate(14px, ${theme.spacing(2)}) scale(1)`,
  "&.MuiInputLabel-shrink.MuiFormLabel-filled": {
    transform:
      className === "small"
        ? `translate(14px, -9px) scale(0.75)`
        : `translate(14px, -9px) scale(0.75)`,
  },
}));

type SelectFieldProps = SelectProps & {
  name: string;
  label: string;
  helperText?: any;
  placeholder?: string;
  fullWidth?: boolean;
  size?: "small" | "medium";
  required?: boolean;
  options: any[];
  addNone?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  handleChange?: (e: any) => void;
  // selectedvalue?: string;
};

const SelectField: FC<SelectFieldProps> = (props) => {
  const { setFieldValue } = useFormikContext();
  const {
    name,
    label,
    helperText,
    placeholder,
    size = "small",
    fullWidth = true,
    variant = "outlined",
    required = false,
    options,
    disabled,
    addNone = false,
    readOnly = false,
    handleChange = (event) => {
      setFieldValue(event.target.name, event.target.value);
    },
  } = props;

  return (
    <Field as="Select" name={name}>
      {({ field, form }) => (
        <FormControl
          fullWidth={fullWidth}
          error={form.errors[name] && form.touched[name]}
          required={required}
        >
          <InputLabelStyled
            className={size}
            id={`select-${name}`}
            disabled={disabled}
          >
            {label}
          </InputLabelStyled>

          <Select
            readOnly={readOnly}
            disabled={disabled}
            labelId={`select-labelId-${name}`}
            id={`select-${name}`}
            {...props}
            {...field}
            name={name}
            autoComplete="off"
            fullWidth={fullWidth}
            size={size}
            variant={variant}
            required={required}
            placeholder={placeholder}
            label={label}
            MenuProps={MenuProps}
            onChange={handleChange}
          >
            {addNone && <MenuItem value="None">None</MenuItem>}

            {options.map((option) => (
              <MenuItem value={option.value}>{option.label}</MenuItem>
            ))}
          </Select>
          <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
      )}
    </Field>
  );
};

SelectField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  helperText: PropTypes.any,
  size: PropTypes.any,
  variant: PropTypes.any,
  placeholder: PropTypes.string,
  fullWidth: PropTypes.bool,
  required: PropTypes.bool,
  options: PropTypes.array,
  addNone: PropTypes.bool,
  handleChange: PropTypes.func,
};
export default SelectField;

import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../store";

const selectSelf = (state: RootState) => state;
const selectCart = createSelector(selectSelf, (state) => state.cart);

export const selectIsCartLoading = createSelector(
  selectCart,
  (cart) => cart.isLoading
);

export const selectCartState = createSelector(
  selectCart,
  (cart) => cart.cartState
);

export const selectCartType = createSelector(
  selectCart,
  (cart) => cart?.cartState?.cart?.rtype
);
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import { useDisplaySettingsContext } from "src/contexts/DisplaySettings";
import { LogoProps } from "src/types/logo";

export default function Logo({
  title,
  href,
  titleSize = 1,
  textAlign = "center",
  image,
  alt,
  imageWidth = "60px",
  imageHeight = "60px",
  padding = null,
}: LogoProps) {
  
  const { heading2 } = useDisplaySettingsContext();

  return (
    <Link
      component={RouterLink}
      to={href}
      color={"inherit"}
      underline="none"
      fontFamily={"inherit"}
      fontWeight={600}
      fontSize={heading2}
      textAlign={textAlign}
      display={"inline-block"}
      padding={padding}
    >
      {title ||
        (image && (
          <img
            src={image}
            alt={alt}
            style={{
              width: imageWidth,
              height: imageHeight,
              objectFit: "contain",
              verticalAlign: "middle",
            }}
          />
        ))}
    </Link>
  );
}

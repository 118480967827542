import axios from "axios";
import { auth } from "src/configs/firebase";
import { BASE_URL_DIGITAL, BASE_URL_UBUNTU, BaseUrl } from "src/constants";

interface ReturnInterface {
  error: boolean;
  result: any;
}

export const sendRequestJSON = async (
  obj = null,
  service,
  method: string,
  baseUrl: BaseUrl,
  customUrl = null,
  clusterInfo = ""
): Promise<ReturnInterface> => {
  let config: any;
  let url = "";
  if (baseUrl === BaseUrl.ubuntu) {
    url = `${BASE_URL_UBUNTU}${service}`;
  } else if (baseUrl === BaseUrl.digital) {
    url = `${BASE_URL_DIGITAL}${service}`;
  } else {
    url = `https://${customUrl}${service}`;
  }
  if (obj) {
    const data = JSON.stringify(obj);
    // const token = localStorage.getItem('AccessToken');

    config = {
      method,
      url: url,
      headers: {
        "Content-Type": "application/json",
        "Clusterinf":  clusterInfo,
        Authorization: `Bearer ${
          (await auth?.currentUser?.getIdToken()) ?? ""
        }`,
      },
      timeout: 2 * 60 * 1000,
      data,
    };
  } else {
    config = {
      method,
      url: url,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          (await auth?.currentUser?.getIdToken()) ?? ""
        }`,
      },
      timeout: 2 * 60 * 1000,
    };
  }

  const returnOBJ: any = { error: false, result: {} };

  await axios(config)
    .then(async (response) => {
      const result: any = response;
      returnOBJ.result = result;
    })
    .catch(async (err) => {
      console.log("result axios", err.response.data);
      returnOBJ.error = true;
      returnOBJ.result = err.response.data;
    });

  try {
    if (returnOBJ.error === true) {
      return returnOBJ;
    } else if (returnOBJ.error === false) {
      return returnOBJ;
    }
  } catch (e) {
    return null;
  }
};

export const sendRequestFiles = async (
  files,
  service,
  baseUrl
): Promise<ReturnInterface> => {
  const formData = new FormData();
  for (let i = 0; i < files.length; i++) {
    formData.append("attachmentFile", files[i]);
  }
  const returnOBJ: any = { error: false, result: {} };
  try {
    let url = "";
    if (baseUrl === BaseUrl.ubuntu) {
      url = `${BASE_URL_UBUNTU}${service}`;
    } else {
      url = `${BASE_URL_DIGITAL}${service}`;
    }
    const response = await axios.post(url, formData, {
      timeout: 1800000,
      maxContentLength: Infinity,
      maxBodyLength: Infinity,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    console.log(response);
    returnOBJ.result = response;
    returnOBJ.error = false;
    return returnOBJ;
  } catch (error) {
    console.error(error);
    returnOBJ.result = error;
    returnOBJ.error = true;
    return returnOBJ;
  }
};

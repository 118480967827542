import 'yet-another-react-lightbox/styles.css';

import { Box, Grid, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import CKeyValueList from 'src/components/CKeyValueList';
import StaticGoogleMap from 'src/components/pages/home/static-google-map';
import { useDisplaySettingsContext } from 'src/contexts/DisplaySettings';
import { selectTripLocation, selectTripState } from 'src/slices/trip/selectors';
import { getAdhocLocation, getAdhocTripById } from 'src/slices/trip/thunks';
import { AppDispatch } from 'src/store';
import Lightbox from 'yet-another-react-lightbox';

export const TripStatusView = () => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const queryParams = new URLSearchParams(location.search);
  const adhocId = queryParams.get("adhocid");

  const { heading2, heading3, text1, text2, text3 } = useDisplaySettingsContext();

  const tripState = useSelector(selectTripState);
  const tripLocation = useSelector(selectTripLocation);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (adhocId) {
      dispatch(getAdhocTripById(adhocId));
    }
  }, [adhocId, dispatch]);

  useEffect(() => {
    if (adhocId && tripState) {
      dispatch(getAdhocLocation(true));
    }
  }, [tripState]);

  const getCompletedAttributes = () => {
    if (tripState.dto?.state === "COMPLETE") {
      return [
        {
          key: "Name",
          value: tripState.dto?.detailsItem.doclist[0].name ?? "",
        },
        {
          key: "Image",
          value: (
            <>
              <Box
                component="img"
                src={tripState.dto?.detailsItem.doclist[0].value ?? ""}
                alt="img"
                sx={{
                  height: 50,
                  width: 50,
                  cursor: "pointer",
                }}
                onClick={() => {
                  setOpen((isOpen) => !isOpen);
                }}
              />
              <Lightbox
                open={open}
                close={() => setOpen(false)}
                slides={[
                  {
                    src: tripState.dto?.detailsItem.doclist[0].value ?? "",
                  },
                ]}
              />
            </>
          ),
        },
      ];
    } else {
      return [];
    }
  };

  const latlnglist = useCallback(() => {
    const list = [
      {
        lat: tripState.dto.detailsItem?.receiver?.address?.latitude ?? 0,
        lng: tripState.dto.detailsItem?.receiver?.address?.longitude ?? 0,
        icon: "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
      },
      {
        lat: tripState.dto.detailsItem?.sender?.address?.latitude ?? 0,
        lng: tripState.dto.detailsItem?.sender?.address?.longitude ?? 0,
        icon: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
      },
    ];
    if (tripState.dto?.state === "START" && tripLocation) {
      list.push({
        lat: tripLocation.lati ?? 0,
        lng: tripLocation.longi ?? 0,
        icon: "http://maps.google.com/mapfiles/ms/icons/purple-dot.png",
      });
    }
    return list;
  }, [tripState, tripLocation]);

  return tripState && tripState.dto ? (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <CKeyValueList
          labelWidth={isMobile ? 100 : 200}
          size="medium"
          list={[
            {
              key: "Info",
              value: (
                <Stack direction="column" gap={1}>
                  <Stack direction="row" gap={1}>
                    <Typography fontSize={text3}>Receiver</Typography>
                    <Box
                      component="img"
                      src="http://maps.google.com/mapfiles/ms/icons/red-dot.png"
                      sx={{
                        height: 20,
                        width: 20,
                      }}
                    />
                  </Stack>
                  <Stack direction="row" gap={1}>
                    <Typography fontSize={text3}>Sender</Typography>
                    <Box
                      component="img"
                      src="http://maps.google.com/mapfiles/ms/icons/blue-dot.png"
                      sx={{
                        height: 20,
                        width: 20,
                      }}
                    />
                  </Stack>
                  {tripState.dto?.state === "START" && (
                    <Stack direction="row" gap={1}>
                      <Typography fontSize={text3}>Driver</Typography>
                      <Box
                        component="img"
                        src="http://maps.google.com/mapfiles/ms/icons/purple-dot.png"
                        sx={{
                          height: 20,
                          width: 20,
                        }}
                      />
                    </Stack>
                  )}
                </Stack>
              ),
            },
            {
              key: "Service Provided by",
              value: tripState.dto.dri.spname,
            },
            {
              key: "Vehicle Number",
              value: tripState.dto.dri.vehnum,
            },
            {
              key: "Driver Name",
              value: tripState.dto.dri.username,
            },
            {
              key: "Sender Name",
              value: tripState.dto.detailsItem.sender?.name ?? "",
            },
            {
              key: "Sender Address",
              value:
                tripState.dto.detailsItem.sender?.address?.addressline ?? "",
            },
            {
              key: "Receiver Name",
              value: tripState.dto.detailsItem.receiver?.name ?? "",
            },
            {
              key: "Receiver Address",
              value:
                tripState.dto.detailsItem.receiver?.address?.addressline ?? "",
            },
            {
              key: "Status",
              value: tripState.dto?.state ?? "",
            },
            ...getCompletedAttributes(),
          ]}
        />
      </Grid>
      {tripState.dto.detailsItem?.receiver?.address && (
        <Grid item xs={12} md={8}>
          <StaticGoogleMap
            latlngs={latlnglist()}
            height={isMobile ? "50vh" : "80vh"}
            width="100%"
            zoom={15}
          />
        </Grid>
      )}
    </Grid>
  ) : (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <Typography fontSize={heading3}>No Data</Typography>
    </Box>
  );
};

import React, { FC } from "react";
import { ListChildComponentProps, VariableSizeList } from "react-window";
import { useMediaQuery, useTheme } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import ListSubheader from "@mui/material/ListSubheader";
import TextField from "@mui/material/TextField";
import { Field, getIn, useFormikContext } from "formik";

import CTypography from "../CTypography";

interface IVirtualizedSearchableSelect {
  name?: string;
  label?: string;
  options?: any[];
  error?: boolean;
  helperText: string;
  disabled?: boolean;
  required?: boolean;
  reflectChange?: (e: any) => void;
}

type FieldProp = {
  field: any;
};

const VirtualizedSearchableSelect: FC<IVirtualizedSearchableSelect> = (
  props: any
) => {
  // const [val, setVal] = useState<any>();

  const { setFieldValue, handleChange, setTouched } = useFormikContext();
  const {
    name,
    label,
    options,
    error,
    helperText,
    reflectChange,
    disabled,
    required,
  } = props;

  const { values } = useFormikContext();

  const fieldValue = getIn(values, name);

  const LISTBOX_PADDING = 8;

  function renderRow(propss: ListChildComponentProps) {
    const { data, index, style } = propss;
    const dataSet = data[index];
    const inlineStyle = {
      ...style,
      top: (style.top as number) + LISTBOX_PADDING,
    };

    // eslint-disable-next-line no-prototype-builtins
    if (dataSet.hasOwnProperty("group")) {
      return (
        <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
          {dataSet.group}
        </ListSubheader>
      );
    }

    return (
      <CTypography component="li" {...dataSet[0]} noWrap style={inlineStyle}>
        {dataSet[1]}
      </CTypography>
    );
  }

  const OuterElementContext = React.createContext({});

  const OuterElementType = React.forwardRef<HTMLDivElement>((propss, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...propss} {...outerProps} />;
  });

  function useResetCache(data: any) {
    const ref = React.useRef<VariableSizeList>(null);
    React.useEffect(() => {
      if (ref.current != null) {
        ref.current.resetAfterIndex(0, true);
      }
    }, [data]);
    return ref;
  }

  // Adapter for react-window
  const ListboxComponent = React.forwardRef<
    HTMLDivElement,
    React.HTMLAttributes<HTMLElement>
  >((propss, ref) => {
    const { children, ...other } = propss;
    const itemData: React.ReactChild[] = [];
    (children as React.ReactChild[]).forEach(
      (item: React.ReactChild & { children?: React.ReactChild[] }) => {
        itemData.push(item);
        itemData.push(...(item.children || []));
      }
    );

    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up("sm"), {
      noSsr: true,
    });
    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;

    const getChildSize = (child: any) => {
      // eslint-disable-next-line no-prototype-builtins
      if (child.hasOwnProperty("group")) {
        return 48;
      }

      return itemSize;
    };

    const getHeight = () => {
      if (itemCount > 8) {
        return 8 * itemSize;
      }
      return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
      <div ref={ref}>
        <OuterElementContext.Provider value={other}>
          <VariableSizeList
            itemData={itemData}
            height={getHeight() + 2 * LISTBOX_PADDING}
            width="100%"
            ref={gridRef}
            outerElementType={OuterElementType}
            innerElementType="ul"
            itemSize={(index: number) => getChildSize(itemData[index])}
            overscanCount={5}
            itemCount={itemCount}
          >
            {renderRow}
          </VariableSizeList>
        </OuterElementContext.Provider>
      </div>
    );
  });

  return (
    <Field>
      {({ field }: FieldProp) => (
        <Autocomplete
          id={name}
          options={options}
          clearOnBlur
          ListboxComponent={ListboxComponent}
          disableListWrap
          onBlur={() => setTouched({ [name]: true })}
          value={fieldValue}
          disabled={disabled}
          onChange={(event: any, value: any, reason) => {
            if (reason === "clear") {
              setFieldValue(name, "");
            } else {
              setFieldValue(name, value);
              if (reflectChange) reflectChange(value);
            }
          }}
          renderOption={(propss, option) => [propss, option]}
          renderInput={(params) => (
            <TextField
              {...params}
              {...field}
              onChange={handleChange}
              error={error}
              helperText={helperText}
              size="small"
              label={label}
              required={required}
              fullWidth
            />
          )}
        />
      )}
    </Field>
  );
};

VirtualizedSearchableSelect.propTypes = {};

export default VirtualizedSearchableSelect;

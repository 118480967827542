import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../store";

const selectSelf = (state: RootState) => state;
const selectProduct = createSelector(selectSelf, (state) => state.product);

export const selectIsProductLoading = createSelector(
  selectProduct,
  (product) => product.isLoading
);

export const selectProductState = createSelector(
  selectProduct,
  (product) => product.productState
);
import { FC, useState } from "react";
import PropTypes from "prop-types";
import { Field, useFormikContext } from "formik";
import {
  BaseTextFieldProps,
  FormControl,
  InputAdornment,
  TextField,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

interface InputFieldProps extends BaseTextFieldProps {
  name: string;
  label: string;
  helperText?: any;
  placeholder?: string;
  fullWidth?: boolean;
  size?: "small" | "medium";
  required?: boolean;
  readOnly?: boolean;
  customOnBlur?: any;
}

const PasswordInputField: FC<InputFieldProps> = (props) => {
  const {
    name,
    label,
    helperText,
    placeholder,
    size = "small",
    fullWidth = true,
    variant = "outlined",
    required = false,
    readOnly = false,
    customOnBlur,
  } = props;
  const { handleBlur } = useFormikContext();

  const [showPassword, setShowPassword] = useState(false);

  return (
    <Field name={name}>
      {({ field }) => (
        <FormControl fullWidth={fullWidth}>
          <TextField
            {...props}
            {...field}
            type={showPassword ? "text" : "password"}
            autoComplete="off"
            name={name}
            fullWidth={fullWidth}
            size={size}
            variant={variant}
            required={required}
            placeholder={placeholder}
            helperText={helperText}
            label={label}
            InputProps={{
              readOnly,
              endAdornment: (
                <InputAdornment
                  position="end"
                  onClick={() => {
                    setShowPassword((value) => !value);
                  }}
                  sx={{
                    cursor: "pointer",
                  }}
                >
                  {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </InputAdornment>
              ),
            }}
            onBlur={(event) => {
              if (customOnBlur) {
                customOnBlur();
              }
              handleBlur(event);
            }}
          />
        </FormControl>
      )}
    </Field>
  );
};

PasswordInputField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  size: PropTypes.any,
  variant: PropTypes.any,
  helperText: PropTypes.any,
  placeholder: PropTypes.string,
  fullWidth: PropTypes.bool,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
};

export default PasswordInputField;

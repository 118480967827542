import { useSelector } from "react-redux";
import { selectShopHeroContent } from "src/slices/landing-page/selectors";
import { getBestFitImage } from "src/lib/utils";
import ReviewCardSlider from "src/components/pages/landing-page/review-card-slider";

export default function ReviewHero({ deviceType }) {
  // const heroData = useSelector(selectShopHeroContent);

  // if (!heroData) {
  //   return null;
  // }

  const slides = [
    {
      name: "Name here 1",
      review:
        "Lorem ipsum dolor sit amet consectetur. Ac morbi bibendum tempus feugiat et et magna. Venenatis amet pharetra aenean elit neque ut ut adipiscing.",
      rating: 3,
      subTitle: "Lorem ipsum",
    },
    {
      name: "Name here 2",
      review:
        "Lorem ipsum dolor sit amet consectetur. Ac morbi bibendum tempus feugiat et et magna. Venenatis amet pharetra aenean elit neque ut ut adipiscing.",
      rating: 4,
      subTitle: "Lorem ipsum",
    },
    {
      name: "Name here 3",
      review:
        "Lorem ipsum dolor sit amet consectetur. Ac morbi bibendum tempus feugiat et et magna. Venenatis amet pharetra aenean elit neque ut ut adipiscing.",
      rating: 2,
      subTitle: "Lorem ipsum",
    },
    {
      name: "Name here 4",
      review:
        "Lorem ipsum dolor sit amet consectetur. Ac morbi bibendum tempus feugiat et et magna. Venenatis amet pharetra aenean elit neque ut ut adipiscing.",
      rating: 5,
      subTitle: "Lorem ipsum",
    },
  ];
  // .map((review) => {
  //   return {
  //     image: bestFitImage.value,
  //     alt: bestFitImage.imgname,
  //     title: image?.title,
  //     subtitle: image?.info1 || image?.info2,
  //   };
  // });

  return (
    <ReviewCardSlider
      containerHeight="368px"
      reviews={slides}
      deviceType={deviceType}
    />
  );
}

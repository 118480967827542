import { Button, Grid } from "@mui/material";
import { Form, Formik } from "formik";
import MaterialReactTable from "material-react-table";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ContainedButton } from "src/components/button-group";
import FormikControl from "src/components/formik/FormikControl";
import { getUserById } from "src/slices/auth/thunks";
import { selectLsm } from "src/slices/landing-page/selectors";
import { AppDispatch } from "src/store";
import { loadSubjects } from "src/slices/user-panel/thunks";
import { loadAssignments } from "src/slices/user-panel/thunks";
import { selectAssignments } from "src/slices/user-panel/selectors";
import CModal from "src/components/CModal";
import { ViewResultDialog } from "./view-result-dialog";
import { setDialog } from "src/slices/uiSettingsSlice";
import { PracticeDialog } from "./practice-dialog";
import * as CONSTANTS from "src/constants";
import { ExecuteSurveyDialog } from "./excute-survey-dialog";
import { ViewElectionResultDialog } from "./view-election-result-dialog";
import { SubmitElectionResultDialog } from "./submit-election-result-dialog";
import { PollDialog } from "./poll-dialog";
import { clearAssignments } from "src/slices/user-panel";

function toJsonTimeStamp(time) {
  if (time !== null && time instanceof Date) {
    const utcTime = time.toISOString(); // Convert to UTC string
    const millisecondsSinceEpoch = new Date(utcTime).getTime(); // Get milliseconds since epoch
    return Math.round(millisecondsSinceEpoch / 1000);
  }
  return null;
}

interface IformInitialValue {
  type: string;
  subject: string;
  assignment: string;
  virtualRoom: string;
}

export const AssignmentPanel = () => {
  const dispatch = useDispatch<AppDispatch>();

  const lsm: any = useSelector(selectLsm);
  const assignments = useSelector(selectAssignments);
  const formikRef = useRef(null);

  const { dialog } = useSelector((state: any) => state.uiSettings);

  const [user, setUser] = useState<any>(null);
  const [subjects, setSubjects] = useState<any>([]);
  const [viewResultData, setViewResultData] = useState<any>(null);

  const [actionButtonsPracticesDialog, setActionButtonsPracticesDialog] =
    useState([]);

  // Form Inital Values
  const formInitialValue: IformInitialValue = {
    subject: "",
    type: "KNOWLEDGE",
    assignment: "",
    virtualRoom: "",
  };

  useEffect(() => {
    dispatch(getUserById()).then((response) => {
      if (response?.payload?.data?.um) {
        setUser(response?.payload?.data?.um);
      }
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (user?.services) {
      dispatch(
        loadSubjects({
          class:
            user?.services
              ?.filter((service) => service?.serviceid === lsm.serviceID)[0]
              ?.stulist[0]?.vr?.split("-")[0] ?? "",
        })
      ).then((response) => {
        if (response?.payload?.data?.subjectlist) {
          setSubjects(response?.payload?.data?.subjectlist);
        }
      });
    }
  }, [user]);

  return (
    <>
      <Formik
        initialValues={formInitialValue}
        innerRef={formikRef}
        onSubmit={(values: IformInitialValue) => {
          let payload = {
            ...values,
          };
          dispatch(loadAssignments(payload));
        }}
      >
        {({ values, touched, errors, getFieldProps }) => (
          <Form id="Assignment">
            <Grid container spacing={2} sx={{ p: 2 }}>
              <Grid item xs={12}>
                <Grid container spacing={2} pb={3} alignItems="center">
                  <Grid item xs={12}>
                    <FormikControl
                      control="Radio"
                      showInRow
                      required
                      name="type"
                      handleChange={(_) => dispatch(clearAssignments())}
                      error={Boolean(touched.type && errors.type)}
                      helperText={touched.type && errors.type}
                      {...getFieldProps("type")}
                      options={[
                        {
                          value: "KNOWLEDGE",
                          label: "KNOWLEDGE",
                        },
                        {
                          value: "SURVEY",
                          label: "SURVEY",
                        },
                        {
                          value: "POLL",
                          label: "POLL",
                        },
                        {
                          value: "ELECTION",
                          label: "ELECTION",
                        },
                      ]}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <FormikControl
                      control="InputField"
                      name="assignment"
                      label={"Search Assignment"}
                      required
                      error={Boolean(touched.assignment && errors.assignment)}
                      helperText={touched.assignment && errors.assignment}
                      {...getFieldProps("assignment")}
                    />
                  </Grid>
                  {values.type !== "ELECTION" && (
                    <Grid item xs={12} sm={6} md={4}>
                      <FormikControl
                        control="SelectField"
                        name="subject"
                        label={"Search Subject"}
                        required
                        options={subjects?.map(
                          (sbj) =>
                            ({
                              value: sbj,
                              label: sbj,
                            } ?? [])
                        )}
                        error={Boolean(touched.subject && errors.subject)}
                        helperText={touched.subject && errors.subject}
                        {...getFieldProps("subject")}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} sm={6} md={4}>
                    <FormikControl
                      control="SelectField"
                      name="virtualRoom"
                      label={"Search Virtual Room"}
                      required
                      options={(
                        user?.services?.filter(
                          (service) => service?.serviceid === lsm.serviceID
                        )[0]?.stulist ?? []
                      )?.map(
                        (st) =>
                          ({
                            value: st.vr,
                            label: st.vr,
                          } ?? [])
                      )}
                      error={Boolean(touched.virtualRoom && errors.virtualRoom)}
                      helperText={touched.virtualRoom && errors.virtualRoom}
                      {...getFieldProps("virtualRoom")}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <ContainedButton size="medium" type="submit">
                      Load Data
                    </ContainedButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    {values.type === "KNOWLEDGE" && (
                      <MaterialReactTable
                        columns={[
                          {
                            accessorKey: "id",
                            header: "Id",
                            size: 100,
                          },
                          {
                            accessorKey: "name",
                            header: "Name",
                            size: 100,
                          },
                          {
                            accessorKey: "topic",
                            header: "Topic",
                            size: 100,
                          },
                          {
                            accessorKey: "subTopic",
                            header: "Sub Topic",
                            size: 100,
                          },
                          {
                            accessorKey: "result",
                            header: "Result",
                            size: 100,
                            Cell: ({ cell }: any) => (
                              <Button
                                variant="outlined"
                                onClick={() => {
                                  dispatch(
                                    setDialog({
                                      open: true,
                                      dialogName: "ViewResult",
                                    })
                                  );
                                  const selectedUser =
                                    user?.services?.filter(
                                      (service) =>
                                        service?.serviceid === lsm?.serviceID
                                    )[0]?.stulist[0] ?? null;
                                  setViewResultData({
                                    id: cell?.getValue(),
                                    cnic: selectedUser?.id ?? "",
                                  });
                                }}
                              >
                                View Results
                              </Button>
                            ),
                          },
                          {
                            accessorKey: "practice",
                            header: "Practice",
                            size: 100,
                            Cell: ({ cell }: any) => (
                              <Button
                                variant="outlined"
                                onClick={() => {
                                  dispatch(
                                    setDialog({
                                      open: true,
                                      dialogName: "Practice",
                                    })
                                  );
                                  const selectedUser =
                                    user?.services?.filter(
                                      (service) =>
                                        service?.serviceid === lsm?.serviceID
                                    )[0]?.stulist[0] ?? null;
                                  setViewResultData({
                                    id: cell?.getValue(),
                                    cnic: selectedUser?.id ?? "",
                                    name: selectedUser?.na ?? "",
                                    date: toJsonTimeStamp(
                                      CONSTANTS.getJustDatePart(
                                        new Date(Date.now())
                                      )
                                    ),
                                  });
                                }}
                              >
                                Practice
                              </Button>
                            ),
                          },
                        ]}
                        data={
                          assignments?.map((assignment, index) => ({
                            id: index + 1,
                            name: assignment?.name,
                            topic: assignment?.info1?.filter(
                              (info) => info.name === "topic"
                            )[0].value,
                            subTopic: assignment?.info1?.filter(
                              (info) => info.name === "subtopic"
                            )[0].value,
                            result: assignment?.value,
                            practice: assignment?.value,
                          })) ?? []
                        }
                        initialState={{
                          density: "compact",
                          pagination: { pageSize: 5, pageIndex: 0 },
                        }}
                      />
                    )}
                    {values.type !== "KNOWLEDGE" && (
                      <MaterialReactTable
                        columns={[
                          {
                            accessorKey: "id",
                            header: "Id",
                            size: 100,
                          },
                          {
                            accessorKey: "name",
                            header: "Name",
                            size: 100,
                          },
                          {
                            accessorKey: "topic",
                            header: "Topic",
                            size: 100,
                          },
                          {
                            accessorKey: "subTopic",
                            header: "Sub Topic",
                            size: 100,
                          },
                          {
                            accessorKey: "result",
                            header: "Result",
                            size: 100,
                            Cell: ({ cell }: any) => (
                              <>
                                {cell?.getValue().publish === "T" && (
                                  <Button
                                    variant="outlined"
                                    onClick={() => {
                                      switch (formikRef.current.values.type) {
                                        case "ELECTION":
                                          dispatch(
                                            setDialog({
                                              open: true,
                                              dialogName: "ViewElectionResult",
                                            })
                                          );
                                          break;

                                        default:
                                          break;
                                      }
                                      const selectedUser =
                                        user?.services?.filter(
                                          (service) =>
                                            service?.serviceid ===
                                            lsm?.serviceID
                                        )[0]?.stulist[0] ?? null;
                                      setViewResultData({
                                        id: cell?.getValue().value,
                                        cnic: selectedUser?.id ?? "",
                                      });
                                    }}
                                  >
                                    View Results
                                  </Button>
                                )}
                              </>
                            ),
                          },
                          {
                            accessorKey: "execute",
                            header: "Execute",
                            size: 100,
                            Cell: ({ cell }: any) => (
                              <>
                                {cell?.getValue().publish === "F" && (
                                  <Button
                                    variant="outlined"
                                    onClick={() => {
                                      switch (formikRef.current.values.type) {
                                        case "SURVEY":
                                          dispatch(
                                            setDialog({
                                              open: true,
                                              dialogName: "Execute",
                                            })
                                          );
                                          break;
                                        case "ELECTION":
                                          dispatch(
                                            setDialog({
                                              open: true,
                                              dialogName:
                                                "SubmitElectionResult",
                                            })
                                          );
                                          break;
                                        case "POLL":
                                          dispatch(
                                            setDialog({
                                              open: true,
                                              dialogName: "Poll",
                                            })
                                          );
                                          break;

                                        default:
                                          break;
                                      }
                                      const selectedUser =
                                        user?.services?.filter(
                                          (service) =>
                                            service?.serviceid ===
                                            lsm?.serviceID
                                        )[0]?.stulist[0] ?? null;
                                      setViewResultData({
                                        id: cell?.getValue().value,
                                        cnic: selectedUser?.id ?? "",
                                        name: selectedUser?.na ?? "",
                                        date: toJsonTimeStamp(
                                          CONSTANTS.getJustDatePart(
                                            new Date(Date.now())
                                          )
                                        ),
                                      });
                                    }}
                                  >
                                    Execute
                                  </Button>
                                )}
                              </>
                            ),
                          },
                        ]}
                        data={
                          assignments?.map((assignment, index) => ({
                            id: index + 1,
                            name: assignment?.name,
                            topic: assignment?.info1?.filter(
                              (info) => info.name === "topic"
                            )[0].value,
                            subTopic: assignment?.info1?.filter(
                              (info) => info.name === "subtopic"
                            )[0].value,
                            result: {
                              value: assignment?.value,
                              publish: assignment?.info1?.filter(
                                (info) => info.name === "publish"
                              )[0].value,
                            },
                            execute: {
                              value: assignment?.value,
                              publish: assignment?.info1?.filter(
                                (info) => info.name === "publish"
                              )[0].value,
                            },
                          })) ?? []
                        }
                        initialState={{
                          density: "compact",
                          pagination: { pageSize: 5, pageIndex: 0 },
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      {dialog.ViewResult !== undefined && dialog.ViewResult ? (
        <CModal
          dialogName="ViewResult"
          title={"Result"}
          maxWidth="sm"
          showSaveButton
          handleCancel={() => {
            setViewResultData(null);
          }}
          handleSave={() => {
            setViewResultData(null);
          }}
          open={dialog.ViewResult === undefined ? false : dialog.ViewResult}
          content={
            <ViewResultDialog
              dialogName="ViewResult"
              resultData={viewResultData}
            />
          }
        />
      ) : null}
      {dialog.Practice !== undefined && dialog.Practice ? (
        <CModal
          dialogName="Practice"
          title={"Practice"}
          maxWidth="sm"
          actionButtons={actionButtonsPracticesDialog}
          open={dialog.Practice === undefined ? false : dialog.Practice}
          content={
            <PracticeDialog
              dialogName="Practice"
              resultData={viewResultData}
              setActionButton={setActionButtonsPracticesDialog}
            />
          }
        />
      ) : null}
      {dialog.Execute !== undefined && dialog.Execute ? (
        <CModal
          dialogName="Execute"
          title={"Execute"}
          maxWidth="xs"
          showSaveButton={false}
          open={dialog.Execute === undefined ? false : dialog.Execute}
          content={
            <ExecuteSurveyDialog
              dialogName="Execute"
              resultData={viewResultData}
              setActionButton={setActionButtonsPracticesDialog}
            />
          }
        />
      ) : null}
      {dialog.ViewElectionResult !== undefined && dialog.ViewElectionResult ? (
        <CModal
          dialogName="ViewElectionResult"
          title={"Election Result"}
          maxWidth="sm"
          showSaveButton={false}
          handleCancel={() => {
            setViewResultData(null);
          }}
          handleSave={() => {
            setViewResultData(null);
          }}
          open={
            dialog.ViewElectionResult === undefined
              ? false
              : dialog.ViewElectionResult
          }
          content={
            <ViewElectionResultDialog
              dialogName="ViewElectionResult"
              resultData={viewResultData}
            />
          }
        />
      ) : null}
      {dialog.SubmitElectionResult !== undefined &&
      dialog.SubmitElectionResult ? (
        <CModal
          dialogName="SubmitElectionResult"
          title={"Election"}
          maxWidth="sm"
          actionButtons={actionButtonsPracticesDialog}
          showSaveButton={false}
          handleCancel={() => {
            setViewResultData(null);
          }}
          handleSave={() => {
            setViewResultData(null);
          }}
          open={
            dialog.SubmitElectionResult === undefined
              ? false
              : dialog.SubmitElectionResult
          }
          content={
            <SubmitElectionResultDialog
              dialogName="SubmitElectionResult"
              resultData={viewResultData}
              setActionButton={setActionButtonsPracticesDialog}
            />
          }
        />
      ) : null}
      {dialog.Poll !== undefined && dialog.Poll ? (
        <CModal
          dialogName="Poll"
          title={"Poll"}
          maxWidth="sm"
          actionButtons={actionButtonsPracticesDialog}
          open={dialog.Poll === undefined ? false : dialog.Poll}
          content={
            <PollDialog
              dialogName="Poll"
              resultData={viewResultData}
              setActionButton={setActionButtonsPracticesDialog}
            />
          }
        />
      ) : null}
    </>
  );
};

import { Box, Chip, Grid, IconButton, Typography } from "@mui/material";
import { User } from "firebase/auth";
import { Form, Formik } from "formik";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ContainedButton } from "src/components/button-group";
import FormikControl from "src/components/formik/FormikControl";
import { Slices } from "src/constants";
import logo from "src/icons/logo.png";
import { toastMessage } from "src/slices/commonSlice";
import { AppDispatch } from "src/store";
import * as Yup from "yup";
import {
  getUserById,
  getVerificationCode,
  registerMember,
  registerStaff,
  registerStudent,
  registerStudentGetList,
  registerVendor,
} from "src/slices/auth/thunks";
import * as CONSTANTS from "src/constants";
import { selectLsm } from "src/slices/landing-page/selectors";
import AddIcon from "@mui/icons-material/Add";
import CButton from "src/components/button-group/CButton";
import CModal from "src/components/CModal";
import { setDialog } from "src/slices/uiSettingsSlice";
import { DataGrid } from "@mui/x-data-grid";

export const Register = () => {
  const formikRef = useRef(null);
  const formikDialogRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const [createdUser, setCreatedUser] = useState<User>(null);
  const [isSubmitted, setSubmitted] = useState(false);
  const [selectedCountryCode, setSelectedCountryCode] = useState({
    countryCode: "+91",
    countryName: "IN",
  });
  const [sessionTerms, setSessionTerms] = useState<string[]>([]);
  const [studentIdsList, setStudentIdsList] = useState<string[]>([]);
  const [verificationCode, setVerificationCode] = useState<string>("");
  const [user, setUser] = useState<any>(null);
  const [dependentList, setDependentList] = useState<any>([]);
  const columns = [
    {
      field: "name",
      headerName: "Name",
      width: 225,
    },
    {
      field: "gender",
      headerName: "Gender",
      width: 50,
    },
    {
      field: "dob",
      headerName: "Date of Birth",
      width: 125,
    },
  ];
  // const userProfileCreationResponse = useSelector(
  //   userProfileCreationResponseSelector
  // );

  const { dialog } = useSelector((state: any) => state.uiSettings);
  const { isLoggedIn } = useSelector(
    (state: any) => state[Slices.LOGIN_INFORMATION]
  );
  const lsm = useSelector(selectLsm);

  const validationSchemaModel = Yup.object({});

  const getName = (values) => {
    if (values.middleName) {
      return [values.firstName, values.middleName, values.lastName].join(" ");
    }
    return [values.firstName, values.lastName].join(" ");
  };
  const registerAPI = async (values) => {
    try {
      let registerRequest: any = {
        // email: values.email,
        // username: values.email,
        // phonenum: values.phone.split(selectedCountryCode.countryCode)[1],
        // password: values.password,
        // countrycode: selectedCountryCode.countryCode,
        // requesttype: "PROFILE",
        // country: selectedCountryCode.countryName,
      };
      if (values.registerAs === "MEMBER") {
        registerRequest = {
          cattype: "MEMBER",
          dob: CONSTANTS.toJsonTimeStamp(CONSTANTS.getJustDatePart(values.dob)),
          phone: user?.phn,
          ...{
            dependents: dependentList?.map((dependent) => ({
              name: dependent.name,
              sex: dependent.gender,
              dob: CONSTANTS.toJsonTimeStamp(
                CONSTANTS.getJustDatePart(dependent.dob)
              ),
            })),
          },
        };
        setSubmitted(true);
        dispatch(registerMember(registerRequest)).then(onResponse);
      } else if (values.registerAs === "STAFF") {
        if (values.verificationCode !== verificationCode) {
          dispatch(
            toastMessage({
              error: true,
              message: "verification code does not match!",
            })
          );
          return;
        }
        registerRequest = {
          cattype: "STAFF",
          dob: CONSTANTS.toJsonTimeStamp(CONSTANTS.getJustDatePart(values.dob)),
          phone: user?.phn,
        };
        setSubmitted(true);
        dispatch(registerStaff(registerRequest)).then(onResponse);
      } else if (values.registerAs === "STUDENT/GAURDIAN") {
        if (studentIdsList.length === 0) {
          dispatch(
            toastMessage({
              error: true,
              message: "Student Ids must not be empty.",
            })
          );
          return;
        }

        if (!values?.sessionTerm) {
          dispatch(
            toastMessage({
              error: true,
              message: "Session term must not be empty.",
            })
          );
          return;
        }

        if (values.verificationCode !== verificationCode) {
          dispatch(
            toastMessage({
              error: true,
              message: "verification code does not match!",
            })
          );
          return;
        }
        registerRequest = {
          studentIds: studentIdsList,
          sessionTerm: values.sessionTerm,
          cattype: "STUDENT/GAURDIAN",
          // dob: CONSTANTS.toJsonTimeStamp(CONSTANTS.getJustDatePart(values.dob)),
          phone: user?.phn,
        };
        setSubmitted(true);
        dispatch(registerStudent(registerRequest)).then(onResponse);
      } else if (values.registerAs === "VENDOR") {
        if (!values?.vendor) {
          dispatch(
            toastMessage({
              error: true,
              message: "Vendor must be selected",
            })
          );
          return;
        }
        if (values.verificationCode !== verificationCode) {
          dispatch(
            toastMessage({
              error: true,
              message: "verification code does not match!",
            })
          );
          return;
        }
        const selectedVendor = values?.vendor?.split(",");
        registerRequest = {
          entityid: selectedVendor[0],
          entityname: selectedVendor[1],
        };
        setSubmitted(true);
        dispatch(registerVendor(registerRequest)).then(onResponse);
      }
    } catch (error) {
      setSubmitted(false);
      dispatch(
        toastMessage({
          error: true,
          message: error,
        })
      );
    }
  };

  const onResponse = (response) => {
    if (response.payload?.data?.errorid === -1) {
      dispatch(
        toastMessage({
          message: "User Registered Successfully",
        })
      );
      navigate("/");
    } else if (response.payload?.data?.errorid === -2) {
      dispatch(
        toastMessage({
          error: true,
          message: response.payload?.data?.errormsg,
        })
      );
      navigate("/");
    }
    setSubmitted(false);
  };

  useEffect(() => {
    // getUserById
    dispatch(getUserById()).then((response) => {
      if (response?.payload?.data?.um) {
        setUser(response?.payload?.data?.um);
      }
    });

    // getVerificationCode
    dispatch(getVerificationCode()).then((response) => {
      if (response?.payload?.data?.id) {
        setVerificationCode(response?.payload?.data?.id);
      }
    });

    // registerStudentGetList
    dispatch(registerStudentGetList()).then((response) => {
      const listData = JSON.parse(response.payload.data);
      setSessionTerms(listData?.lookuplist);
    });
  }, []);

  const getCatTypeList = () => {
    const catTypeList = [
      {
        value: "VENDOR",
        label: "VENDOR",
      },
    ];
    if ("School,Tutoring And Academy" === lsm?.servicecat) {
      catTypeList.push(
        ...[
          {
            value: "STAFF",
            label: "STAFF",
          },
          {
            value: "STUDENT/GAURDIAN",
            label: "STUDENT/GAURDIAN",
          },
        ]
      );
    } else if (lsm?.servicetype[0] === "COMPLEX") {
      catTypeList.push(
        ...[
          {
            value: "STAFF",
            label: "STAFF",
          },
          {
            value: "MEMBER",
            label: "MEMBER",
          },
          {
            value: "RESIDENT",
            label: "RESIDENT",
          },
        ]
      );
    } else {
      catTypeList.push(
        ...[
          {
            value: "STAFF",
            label: "STAFF",
          },
          {
            value: "MEMBER",
            label: "MEMBER",
          },
        ]
      );
    }
    return catTypeList;
  };

  const isSpecialMember =
    lsm?.servicecat === "Medical Related -Doctor, Lab , Medicine";

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{ p: 0, m: 0, height: "95vh", backgroundColor: "white" }}
    >
      <Grid item xs={8} md={6} lg={4}>
        <Formik
          initialValues={{
            sessionTerm: "",
            studentId: "",

            buildingName: "",
            floorNumber: "",
            unitAddress: "",

            gender: "M",
            dob: new Date(Date.now()),

            vendor: "",

            registerAs: "VENDOR",
            verificationCode: "",
          }}
          validationSchema={validationSchemaModel}
          innerRef={formikRef}
          onSubmit={async (values) => {
            registerAPI(values);
          }}
        >
          {({ values, touched, errors, setFieldValue, getFieldProps }) => (
            <Form id="Register">
              <Grid
                container
                spacing={1}
                justifyContent="center"
                alignItems="center"
                textAlign="center"
              >
                <Grid item xs={12}>
                  <Box
                    component="img"
                    src={logo}
                    alt="logo"
                    height={100}
                    justifySelf="center"
                    sx={{
                      backgroundColor: "primary.main",
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography fontSize={30}>Register As</Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormikControl
                    control="Radio"
                    showInRow
                    required
                    name="registerAs"
                    error={Boolean(touched.registerAs && errors.registerAs)}
                    helperText={touched.registerAs && errors.registerAs}
                    {...getFieldProps("registerAs")}
                    options={getCatTypeList()}
                  />
                </Grid>
                {values.registerAs === "VENDOR" && (
                  <>
                    <Grid item xs={12}>
                      <FormikControl
                        control="SelectField"
                        label="Vendor"
                        name="vendor"
                        options={(user?.services ?? [])
                          ?.filter(
                            (vendor) =>
                              vendor?.isemployee &&
                              vendor?.roles?.includes("MANAGER")
                          )
                          ?.map((vendor) => ({
                            label: vendor.name,
                            value: `${vendor.serviceid},${vendor.name}`,
                          }))}
                        error={touched.vendor && Boolean(errors.vendor)}
                        helperText={touched.vendor && errors.vendor}
                        {...getFieldProps("vendor")}
                      />
                    </Grid>
                  </>
                )}
                {values.registerAs === "STUDENT/GAURDIAN" && (
                  <>
                    <Grid item xs={12}>
                      <FormikControl
                        control="SelectField"
                        label="Session Term"
                        name="sessionTerm"
                        options={sessionTerms?.map((session) => ({
                          label: session,
                          value: session,
                        }))}
                        error={
                          touched.sessionTerm && Boolean(errors.sessionTerm)
                        }
                        helperText={touched.sessionTerm && errors.sessionTerm}
                        {...getFieldProps("sessionTerm")}
                      />
                    </Grid>
                    <Grid item xs={10}>
                      <FormikControl
                        control="InputField"
                        name="studentId"
                        label="Student Id"
                        error={touched.studentId && Boolean(errors.studentId)}
                        helperText={touched.studentId && errors.studentId}
                        {...getFieldProps("studentId")}
                      />
                    </Grid>
                    <Grid item xs>
                      <IconButton
                        onClick={() => {
                          if (!studentIdsList.includes(values.studentId)) {
                            setStudentIdsList((plist) => [
                              ...plist,
                              values.studentId,
                            ]);
                            setFieldValue("studentId", "");
                          } else {
                            dispatch(
                              toastMessage({
                                error: true,
                                message: "Already exists",
                              })
                            );
                          }
                        }}
                      >
                        <AddIcon />
                      </IconButton>
                    </Grid>
                    {studentIdsList?.map((studentId) => (
                      <Grid item>
                        <Chip
                          label={studentId}
                          onDelete={() => {
                            setStudentIdsList((plist) =>
                              plist.filter((sId) => sId !== studentId)
                            );
                          }}
                        />
                      </Grid>
                    ))}
                  </>
                )}

                {values.registerAs === "RESIDENT" && (
                  <>
                    <Grid item xs={12}>
                      <FormikControl
                        control="InputField"
                        name="buildingName"
                        label="Building Name"
                        error={
                          touched.buildingName && Boolean(errors.buildingName)
                        }
                        helperText={touched.buildingName && errors.buildingName}
                        {...getFieldProps("buildingName")}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormikControl
                        control="InputField"
                        name="floorNumber"
                        label="Floor Number"
                        error={
                          touched.floorNumber && Boolean(errors.floorNumber)
                        }
                        helperText={touched.floorNumber && errors.floorNumber}
                        {...getFieldProps("floorNumber")}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormikControl
                        control="InputField"
                        name="unitAddress"
                        label="Unit Address"
                        error={
                          touched.unitAddress && Boolean(errors.unitAddress)
                        }
                        helperText={touched.unitAddress && errors.unitAddress}
                        {...getFieldProps("unitAddress")}
                      />
                    </Grid>
                  </>
                )}

                {["MEMBER", "STAFF"].includes(values.registerAs) && (
                  <>
                    {values.registerAs === "MEMBER" && isSpecialMember && (
                      <>
                        <Grid item xs={12}>
                          <FormikControl
                            control="SelectField"
                            label="Gender"
                            name="gender"
                            options={[
                              {
                                label: "Male",
                                value: "M",
                              },
                              {
                                label: "Female",
                                value: "F",
                              },
                            ]}
                            error={Boolean(touched.gender && errors.gender)}
                            helperText={touched.gender && errors.gender}
                            {...getFieldProps("gender")}
                          />
                        </Grid>
                      </>
                    )}
                    <Grid item xs={12}>
                      <FormikControl
                        control="CalendarTime"
                        label="Date of Birth"
                        dateOrTimeOnly="date"
                        name="dob"
                        error={Boolean(touched.dob && errors.dob)}
                        helperText={touched.dob && errors.dob}
                        {...getFieldProps("dob")}
                      />
                    </Grid>
                    {isSpecialMember && (
                      <>
                        <Grid item xs={12}>
                          <CButton
                            onClick={() => {
                              dispatch(
                                setDialog({
                                  open: true,
                                  dialogName: "DependentDialog",
                                })
                              );
                            }}
                          >
                            Add Dependent
                          </CButton>
                        </Grid>

                        <DataGrid
                          rows={dependentList}
                          columns={columns}
                          hideFooter
                          headerHeight={20}
                          rowHeight={20}
                          sx={{
                            height: "100px",
                          }}
                        />
                      </>
                    )}
                  </>
                )}
                {!["MEMBER"].includes(values.registerAs) && (
                  <Grid item xs={12}>
                    <FormikControl
                      control="InputField"
                      name="verificationCode"
                      label="Verification Code"
                      error={
                        touched.verificationCode &&
                        Boolean(errors.verificationCode)
                      }
                      helperText={
                        touched.verificationCode && errors.verificationCode
                      }
                      {...getFieldProps("verificationCode")}
                    />
                  </Grid>
                )}
                <Grid item xs={12} sx={{ mt: 2 }}>
                  <ContainedButton
                    fullWidth
                    type="submit"
                    sx={{ color: "white" }}
                    disabled={isSubmitted}
                  >
                    Register
                  </ContainedButton>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
      {dialog.DependentDialog !== undefined && dialog.DependentDialog ? (
        <CModal
          dialogName="DependentDialog"
          title={"Add Dependents"}
          maxWidth="xs"
          handleSaveButtonText="Add"
          handleCancelButtonText="Close"
          handleCancel={() => {
            dispatch(setDialog({ open: false, dialogName: "DependentDialog" }));
          }}
          cfooter={<></>}
          open={
            dialog.DependentDialog === undefined
              ? false
              : dialog.DependentDialog
          }
          content={
            <Formik
              initialValues={{
                name: "",
                gender: "",
                dob: new Date(Date.now()),
              }}
              innerRef={formikDialogRef}
              onSubmit={(values) => {
                setDependentList((lst) => [
                  ...lst,
                  {
                    id: dependentList.length,
                    name: values.name,
                    gender: values.gender,
                    dob: values.dob,
                  },
                ]);
                dispatch(
                  setDialog({ open: false, dialogName: "DependentDialog" })
                );
              }}
            >
              {({ touched, errors, getFieldProps }) => (
                <Form id="DependentDialogForm">
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <FormikControl
                        control="InputField"
                        label="Name"
                        name="name"
                        size="small"
                        error={Boolean(touched.name && errors.name)}
                        helperText={touched.name && errors.name}
                        {...getFieldProps("name")}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormikControl
                        control="SelectField"
                        label="Gender"
                        name="gender"
                        options={[
                          {
                            label: "Male",
                            value: "M",
                          },
                          {
                            label: "Female",
                            value: "F",
                          },
                        ]}
                        error={Boolean(touched.gender && errors.gender)}
                        helperText={touched.gender && errors.gender}
                        {...getFieldProps("gender")}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormikControl
                        control="CalendarTime"
                        label="Date of Birth"
                        dateOrTimeOnly="date"
                        name="dob"
                        error={Boolean(touched.dob && errors.dob)}
                        helperText={touched.dob && errors.dob}
                        {...getFieldProps("dob")}
                      />
                    </Grid>
                    <Grid item xs={12} textAlign="end">
                      <CButton type="submit">Add</CButton>
                      <CButton
                        onClick={() => {
                          dispatch(
                            setDialog({
                              open: false,
                              dialogName: "DependentDialog",
                            })
                          );
                        }}
                      >
                        Close
                      </CButton>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          }
        />
      ) : null}
    </Grid>
  );
};

const useSubdomainCheck = () => {
  const currentHost = window.location.host;
  const hostParts = currentHost
    .split(".")
    .slice(0, currentHost.includes("localhost") ? -1 : -2);
  const subdomain = hostParts[0] || null;

  return { hasSubdomain: !!subdomain, subdomain };
};

export default useSubdomainCheck;

import { Box, useMediaQuery, useTheme } from "@mui/material";
import { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "src/store";
import { selectIsConsolidateMarket } from "src/slices/landing-page/selectors";
import { useDisplaySettingsContext } from "src/contexts/DisplaySettings";
import { hexToRGBA } from "src/lib/utils";
import { PopoverMenu } from "./CNestedMenu";

interface Category1Props {
  subdomain: string;
  selectedCat: any;
  setSelectedCat: any;
}

export const DBLevelCategory: FC<Category1Props> = ({ subdomain, selectedCat, setSelectedCat }) => {
  const dispatch = useDispatch<AppDispatch>();
  const consolidateMarket = useSelector(selectIsConsolidateMarket);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { foregroundColor } = useDisplaySettingsContext();

  //   const getCategoryDataFromSearch = useCallback(
  //     (text) => {
  //       dispatch(
  //         fetchShopProductFilterResult({
  //           dynamicProperty: {
  //             atyp: selectedAtype,
  //             categorytype: null,
  //             psc: {
  //               atyp: selectedAtype,
  //               isshop: true,
  //               lsm: lsm,
  //             },
  //             termquery: text,
  //             serviceproviderid: entityId,
  //             isservice: false,
  //           },
  //         })
  //       );
  //     },
  //     [selectedAtype, entityId, lsm]
  //   );

  return (
    <>
      {consolidateMarket?.dbllevel && (
        <Box
          sx={{
            backgroundColor: hexToRGBA(foregroundColor, 0.1),
            padding: isMobile ? "24px" : "24px 72px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              overflow: "scroll",
              gap: "32px",
              "&::-webkit-scrollbar": {
                display: "none",
              },
              "-ms-overflow-style": "none",
              "scrollbar-width": "none",
            }}
          >
            {Object.keys(consolidateMarket?.dbllevel)?.map((category) => {
              const data = consolidateMarket?.dbllevel[category];
              return data?.map((cat) => (
                <PopoverMenu
                  id={cat.id}
                  itemKey={category}
                  name={cat.name}
                  hc={cat.hc}
                  subdomain={subdomain}
                  selected={selectedCat}
                  setSelected={setSelectedCat}
                />
              ));
            })}
          </Box>
        </Box>
      )}
    </>
  );
};

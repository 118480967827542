import { ArrowDownward } from '@mui/icons-material';
import { Badge, Box, Fab, Grid, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ServiceListItem } from 'src/components/pages/home/service-list-item';
import { resetCartState } from 'src/slices/cart';
import { LimitedServiceModel } from 'src/slices/cart/types';
import { selectHomeProviders, selectServiceProviderLastCall } from 'src/slices/home/selectors';
import { getMoreServiceProviders } from 'src/slices/home/thunks';
import { IGetServiceProvidersPayload, IServiceProvider } from 'src/slices/home/types';
import { AppDispatch } from 'src/store';

export function ProvidersPage() {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const providers: IServiceProvider = useSelector(selectHomeProviders);
  const serviceProviderLastCall: IGetServiceProvidersPayload = useSelector(
    selectServiceProviderLastCall
  );
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    dispatch(resetCartState());
  }, []);

  return (
    <>
      <Badge
        badgeContent={0}
        color="secondary"
        overlap="circular"
        sx={{
          position: "absolute",
          bottom: 20,
          right: 20,
          zIndex: 1,
        }}
      >
        <Fab
          size="large"
          color="primary"
          aria-label="add"
          sx={{
            zIndex: 0,
            color: 'white'
          }}
          onClick={() => {
            dispatch(
              getMoreServiceProviders({
                dynamicProperty: {
                  servicetype:
                    serviceProviderLastCall.dynamicProperty.servicetype,
                  grouptype: serviceProviderLastCall.dynamicProperty.grouptype,
                  isshop: serviceProviderLastCall.dynamicProperty.isshop,
                  sp: serviceProviderLastCall.dynamicProperty.sp,
                  cust: serviceProviderLastCall.dynamicProperty.cust,
                  spoffset: providers?.smlist?.length ?? 0,
                },
                Query: serviceProviderLastCall.Query,
              } as IGetServiceProvidersPayload)
            );
          }}
        >
          {/* <Typography sx={{
          position: 'absolute',
          height: '20px',
          width: '20px',
          bottom: 35,
          right: 35,
          // padding:1,
          background: 'red',
          borderRadius: 20/2,
        }}>1</Typography> */}
          <ArrowDownward />
        </Fab>
      </Badge>
      <Box
        sx={{
          background: "white",
          padding: "10px",
          borderRadius: "5px",
          marginBottom: "10px",
        }}
      >
        <TextField
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
          id="search"
          label="Search Services"
          variant="outlined"
          sx={{ width: "100%" }}
        />
      </Box>

      <Grid container spacing={1}>
        {providers?.smlist
          ?.filter((data: LimitedServiceModel) =>
            data.servicename.toLowerCase().includes(searchText.toLowerCase())
          )
          ?.map((data: LimitedServiceModel) => (
            <Grid item xs={12} sm={6}>
              <ServiceListItem key={data.serviceID} sm={data} />
            </Grid>
          )) ?? <></>}
      </Grid>
    </>
  );
}

import { createAsyncThunk } from "@reduxjs/toolkit";
import { addDays, differenceInDays, endOfDay, startOfDay } from "date-fns";
import { BaseUrl } from "src/constants";
import * as CONSTANTS from "src/constants";
import { sendRequestJSON } from "src/lib/axios";

import { setAppointmentServiceModel, updateAppointmentState } from ".";
import { toastMessage } from "../commonSlice";
import { IAppointmentSlice } from "./types";
import { fetchProductFilterResultP } from "../product/thunks";
import { selectLsm } from "../landing-page/selectors";

export const selectedAppointmentType = createAsyncThunk(
  "appointment/selectedAppointmentType",
  async (payload: any, { dispatch, getState, rejectWithValue }) => {
    const state: IAppointmentSlice = (getState() as any).appointment;
    const sm = selectLsm(getState());
    try {
      dispatch(
        updateAppointmentState({
          selectedType: payload.selectedType,
          aptother: payload.aptother,
          newcustmember: payload.newcustmember,
        })
      );
      if (state.appointmentState?.serviceModel?.apttype === "PerShop") {
        const stype = payload.selectedType.toString().split(".");
        const response = await sendRequestJSON(
          {
            data: {
              aptreq: {
                entitytype: "SERVICEPROVIDERINFO",
                entityid: state.appointmentState.serviceModel!.serviceID,
                dt: CONSTANTS.toTimeStamp(
                  CONSTANTS.getJustDatePart(new Date(Date.now()))
                ),
                stype: stype[stype.length - 1],
                staffid: "SP",
                action: "getslotconfigbydatetypeandapttype",
                prodtype: state.appointmentState.prodtype,
              },
              qrytype: "APPOINTMENTSLOTQRYACTIONS",
            },
          },
          "",
          "post",
          BaseUrl.digital
        );
        return response.result.data;
      } else if (
        state.appointmentState?.serviceModel?.apttype === "PerEmployee"
      ) {
        dispatch(
          fetchProductFilterResultP({
            serviceModel: sm,
            isService: sm.hasapt === null ? false : sm.hasapt,
            prodtype: 'sps',
          })
        ).then(() => {
          dispatch(
            setAppointmentServiceModel({
              selectedType: payload.selectedType,
              serviceModel: sm,
              isfromowner: false,
              fromentitypage: false,
              prodtype: 'sps',
            })
          );
        });
        return false;
      } else {
        return rejectWithValue("no api called");
      }
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return false;
    }
  }
);

export const getBusySlotOccupancy = createAsyncThunk(
  "appointment/getBusySlotOccupancy",
  async (payload: any, { dispatch, getState }) => {
    const generateMList = () => {
      const daysDifference = differenceInDays(
        endOfDay(payload.enddt),
        startOfDay(payload.startdt)
      );

      const newList = {};

      for (let i = 0; i <= daysDifference; i++) {
        const currentDate = addDays(startOfDay(payload.startdt), i);
        const key = currentDate.getTime();
        newList[key] = {};
      }

      return newList;
    };

    let mlist = generateMList();

    try {
      const response = await sendRequestJSON(
        {
          data: {
            aptreq: {
              dt: CONSTANTS.toTimeStamp(
                CONSTANTS.getJustDatePart(payload.startdt)
              ),
              odt: CONSTANTS.toTimeStamp(
                CONSTANTS.getJustDatePart(payload.enddt)
              ),
              entitytype: "SERVICEPROVIDERINFO",
              entityid: payload.entityid,
              action: "getoccupancymodel",
              staffid: payload.staffid,
              toffset: Math.abs(new Date().getTimezoneOffset() * 60),
            },
            qrytype: "APPOINTMENT",
          },
        },
        "",
        "post",
        BaseUrl.digital
      );

      const aar = JSON.parse(response.result.data.data);
      if (aar.ocupancylist !== null) {
        for (const po of aar.ocupancylist) {
          if (po.date) {
            const key = po.date.millisecondsSinceEpoch;

            // Create a copy of mlist
            const updatedMList = { ...mlist };

            if (updatedMList[key]) {
              updatedMList[key][po.slotname] = po;
              mlist = updatedMList; // Update state to trigger a re-render
            }
          }
        }
      }
      return mlist;
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return false;
    }
  }
);

export const completeAppointmentCreation = createAsyncThunk(
  "appointment/completeAppointmentCreation",
  async (payload: any, { dispatch, getState }) => {
    const state: IAppointmentSlice = (getState() as any).appointment;
    try {
      if (
        [null, undefined].includes(
          state.appointmentState.onCreationAppointment!.id
        ) ||
        [null, undefined].includes(
          state.appointmentState!.onCreationAppointment!.queuerunningnumber
        )
      ) {
        const onCartAppoint = {
          ...state.appointmentState.onCreationAppointment,
          mycartprods: payload.order,
        };

        const response = await sendRequestJSON(
          {
            data: {
              aptreq: {
                action: "add",
                aptmodel: {
                  ...onCartAppoint,
                  startdatetimestamp: CONSTANTS.toTimeStamp(
                    onCartAppoint?.startdatetimestamp
                  ),
                  mycartprods: {
                    ...onCartAppoint!.mycartprods,
                    sdate:
                      CONSTANTS.toTimeStamp(
                        onCartAppoint?.mycartprods?.sdate
                      ) ?? null,
                    edate: CONSTANTS.toTimeStamp(
                      onCartAppoint?.mycartprods?.edate
                    ),
                  },
                },
                entityid: state.appointmentState.serviceModel!.serviceID,
                entitytype: "SERVICEPROVIDERINFO",
                toffset: Math.abs(new Date().getTimezoneOffset() * 60),
              },
              qrytype: "APPOINTMENT",
            },
          },

          "",
          "post",
          BaseUrl.digital
        );
        return response.result.data;
      }
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return false;
    }
  }
);

export const fetchAppointmentEmployee = createAsyncThunk(
  "appointment/fetchAppointmentEmployee",
  async (_, { dispatch, getState }) => {
    try {
      const state: IAppointmentSlice = (getState() as any).appointment;
      const response = await sendRequestJSON(
        {
          data: {
            gm: {
              entitytype: "SERVICEPROVIDERINFO",
              entityid: state.appointmentState.serviceModel!.serviceID,
              action: "getaptstafflist",
              rtype: state.appointmentState.prodtype,
            },
            qrytype: "STAFFDBA",
          },
        },
        "",
        "post",
        BaseUrl.digital
      );

      return response.result.data;
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return false;
    }
  }
);

export const selectedAppointmentEmployee = createAsyncThunk(
  "appointment/selectedAppointmentEmployee",
  async (payload: any, { dispatch, getState }) => {
    try {
      const state: IAppointmentSlice = (getState() as any).appointment;

      dispatch(
        updateAppointmentState({
          selectedEmployee: payload.selectedEmployee,
        })
      );
      const type = state.appointmentState.selectedType.toString().split(".");
      const response = await sendRequestJSON(
        {
          data: {
            aptreq: {
              dt: CONSTANTS.toTimeStamp(
                CONSTANTS.getJustDatePart(new Date(Date.now()))
              ),
              entitytype: "SERVICEPROVIDERINFO",
              entityid: state.appointmentState.serviceModel!.serviceID,
              action: "getaptbytypeandstaff",
              staffid: payload.selectedEmployee!.staffid,
              stype: type[type.length - 1],
              prodtype: state.appointmentState.prodtype,
            },
            qrytype: "APPOINTMENTSLOTQRYACTIONS",
          },
        },
        "",
        "post",
        BaseUrl.digital
      );

      const data = JSON.parse(response.result.data.data);
      return data;
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return false;
    }
  }
);

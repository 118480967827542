import { Stack, Typography } from "@mui/material";
import BannerProps from "src/types/banner";
import CtaLink from "../CtaLink";
import { useDisplaySettingsContext } from "src/contexts/DisplaySettings";

export default function Index({
  title,
  subtitle,
  image,
  alt,
  ctaLink,
  height = window.innerHeight || document.documentElement.clientHeight,
}: BannerProps) {

  const { heading2, text1 } = useDisplaySettingsContext();
  
  return (
    <Stack
      alignItems={"center"}
      justifyContent={"center"}
      position={"relative"}
      height={height}
      sx={{
        "&::before": {
          content: "''",
          width: "100%",
          height: "50%",
          backgroundColor: "#24321099",
          position: "absolute",
          inset: "0px",
        },
      }}
    >
      {image && (
        <img
          loading="lazy"
          src={image}
          alt={alt}
          width={"100%"}
          height={"50%"}
          style={{ objectFit: "contain" }}
        />
      )}
      {(title || subtitle) && (
        <Stack
          position={"absolute"}
          top={"50%"}
          left={"50%"}
          alignItems={"center"}
          textAlign={"center"}
          color={"#ffffff"}
          justifyContent={"center"}
          width={"70%"}
          gap={3}
          sx={{ transform: "translate(-50%, -50%)" }}
        >
          {title && (
            <Typography
              fontSize={heading2}
              fontWeight={700}
              fontFamily={"inherit"}
            >
              {title}
            </Typography>
          )}
          {subtitle && (
            <Typography
              fontSize={text1}
              component={"p"}
              fontWeight={"400"}
              fontFamily={"inherit"}
            >
              {subtitle}
            </Typography>
          )}
          {ctaLink && (
            <CtaLink
              variant={ctaLink.variant}
              title={ctaLink.title}
              href={ctaLink.href}
              startIcon={ctaLink.startIcon}
              endIcon={ctaLink.endIcon}
              backgroundColor={ctaLink.backgroundColor}
              color={ctaLink.color}
              fontFamily={"inherit"}
              sx={{ paddingX: 7, paddingY: 1.5 }}
            />
          )}
        </Stack>
      )}
    </Stack>
  );
}

import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import CtaLinkProps from "src/types/ctaLink";

export default function CtaLink({
  title,
  href,
  variant = "contained",
  size = "large",
  startIcon,
  endIcon,
  elevate = false,
  disabled = false,
  backgroundColor,
  color,
  fontFamily = "inherit",
  sx,
}: CtaLinkProps) {
  return (
    <Button
      variant={variant}
      component={Link}
      to={href}
      size={size}
      disabled={disabled}
      disableElevation={!elevate}
      startIcon={startIcon}
      endIcon={endIcon}
      sx={{
        fontFamily: fontFamily,
        borderWidth: variant === "outlined" ? 2 : 1,
        borderColor: variant === "outlined" ? "currentcolor" : "transparent",
        backgroundColor:
          typeof backgroundColor === "string"
            ? backgroundColor
            : backgroundColor.default,
        color: typeof color === "string" ? color : color.default,
        "&:hover": {
          backgroundColor:
            typeof backgroundColor === "string"
              ? backgroundColor
              : backgroundColor.alternate ?? "inherit",
          color:
            typeof color === "string" ? color : color.alternate ?? "inherit",
        },
        ...sx,
      }}
    >
      {title}
    </Button>
  );
}

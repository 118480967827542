import React from "react";
import { Typography, TextField, Box } from "@mui/material"; // Import MUI components
import { useDisplaySettingsContext } from "src/contexts/DisplaySettings";

interface OrderSummaryProps {
  state: any;
  reqdelivery?: boolean;
}

export const OrderSummary: React.FC<OrderSummaryProps> = ({
  state,
  reqdelivery,
}) => {
  //   const intl = useIntl(); // Initialize localization/internationalization library
  const { text1, text2 } = useDisplaySettingsContext();
  
  return (
    <Box margin="0 16px">
      <Box padding="8px">
        <Box display="flex" justifyContent="space-between">
          <Typography fontSize={text2} fontWeight="600">
            Amount:
          </Typography>
          <Typography fontSize={text2} fontWeight="600">
            {/* {HelpUtil.getCurrency} */}
            {state.cart?.askedprice?.toFixed(2) ?? "0.00"}
          </Typography>
        </Box>
      </Box>
      <Box padding="8px">
        <Box display="flex" justifyContent="space-between">
          <Typography fontSize={text2} fontWeight="600">
            Delivery Charge:
          </Typography>
          {reqdelivery ? (
            <TextField variant="outlined" />
          ) : (
            <Typography fontSize={text2} fontWeight="600">
              {/* {HelpUtil.getCurrency} */}
              {state.cart?.advanceamount?.toFixed(2) ?? "0.00"}
            </Typography>
          )}
        </Box>
      </Box>
      <Box padding="8px">
        <Box display="flex" justifyContent="space-between">
          <Typography fontSize={text2} fontWeight="600">
            Tax:
          </Typography>
          <Typography fontSize={text2} fontWeight="600">
            {/* {HelpUtil.getCurrency} */}
            {state.cart?.totaltax?.toFixed(2) ?? "0.00"}
          </Typography>
        </Box>
      </Box>
      <Box padding="8px">
        <Box display="flex" justifyContent="space-between">
          <Typography fontSize={text1} fontWeight="bold" color="primary">
            Total:
          </Typography>
          <Typography fontSize={text1} fontWeight="bold" color="primary">
            {/* {HelpUtil.getCurrency} */}
            {state.cart?.totalcalculatedprice?.toFixed(2) ?? "0.00"}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

import { Box, useMediaQuery, useTheme } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDisplaySettingsContext } from "src/contexts/DisplaySettings";
import { setAtypeCategory, setAtypeData } from "src/slices/landing-page";
import {
  selectEntityId,
  selectSelectedAtypeCategory,
  selectSelectedAtypeData,
} from "src/slices/landing-page/selectors";
import { getSubcategories } from "src/slices/landing-page/thunks";
import { AppDispatch } from "src/store";

export const AtypeTabButton = ({ subdomain, data, isSelected }) => {
  const dispatch = useDispatch<AppDispatch>();
  const entityId = useSelector(selectEntityId);
  const selectedAtypeCategory = useSelector(selectSelectedAtypeCategory);
  const atypeData = useSelector((state) =>
    selectSelectedAtypeData(state, data.id)
  );
  
  const { text2, foregroundColor } = useDisplaySettingsContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));


  useEffect(() => {
    if (selectedAtypeCategory === data.id) {
      if (!atypeData) {
        dispatch(
          getSubcategories({
            entityId: entityId,
            websitename: subdomain,
            cat: "root",
            atype: data.id,
          })
        );
      }
    }
  }, [entityId, selectedAtypeCategory, atypeData]);
  return (
    <>
      <Box
        onClick={() => {
          dispatch(setAtypeCategory(data.id));
          if (atypeData) {
            dispatch(setAtypeData(atypeData));
          }
        }}
        key={data.id}
        sx={{
          minWidth: isMobile ? '100px' : "150px",
          padding: "10px",
          gap: "4px",
          border: "1px solid #65656533",
          borderRadius: '8px',
          textAlign: "center",
          backgroundColor: isSelected ? "#00487A" : "transparent",
          color: isSelected ? "white" : foregroundColor,
          fontSize: text2,
          cursor: "pointer",
        }}
      >
        {data.name}
      </Box>
    </>
  );
};

import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../store";

const selectSelf = (state: RootState) => state;
const selectWebpageDataState = createSelector(
  selectSelf,
  (state) => state.webpageData
);

export const selectIsWebpageDataLoading = createSelector(
  selectWebpageDataState,
  (webpageData) => webpageData.isLoading
);

export const selectIsCategoryProductsLoading = createSelector(
  selectSelf,
  (state) => state.categoryProducts.isLoading
);

export const selectWebpageData = createSelector(
  selectWebpageDataState,
  (webpageDataState) => {
    const webpageData = webpageDataState.data;
    if (!webpageData) {
      return null;
    }

    return webpageData.wpd;
  }
);

export const selectShopData = createSelector(
  selectWebpageDataState,
  (webpageDataState) => {
    const webpageData = webpageDataState.data;
    if (!webpageData) {
      return null;
    }

    return webpageData.shopProducts;
  }
);

export const selectShops = createSelector(
  selectWebpageDataState,
  (webpageDataState) => {
    const webpageData = webpageDataState.data;
    if (!webpageData) {
      return null;
    }

    return webpageData.shops;
  }
);

export const selectLastPayloadForProductCall = createSelector(
  selectWebpageDataState,
  (webpageDataState) => {
    const webpageData = webpageDataState.data;
    if (!webpageData) {
      return null;
    }

    return webpageData.payload;
  }
);

export const selectEntityId = createSelector(
  selectWebpageDataState,
  (webpageDataState) => {
    const webpageData = webpageDataState.data;
    if (!webpageData) {
      return null;
    }

    return webpageData.wpd.mkey;
  }
);

export const selectSpcatlst = createSelector(
  selectWebpageData,
  (webpageData) => {
    if (!webpageData) {
      return null;
    }

    const data = webpageData.spcatlst;

    return data;
  }
);

export const selectIsConsolidateMarket = createSelector(
  selectWebpageData,
  (webpageData) => {
    if (!webpageData) {
      return null;
    }

    const data = webpageData?.consolidatemarkinfo;

    return data;
  }
);

export const selectEusc = createSelector(selectWebpageData, (webpageData) => {
  if (!webpageData) {
    return null;
  }

  const data = webpageData.eusc;

  return data;
});

export const selectLsm = createSelector(selectWebpageData, (webpageData) => {
  if (!webpageData) {
    return null;
  }

  return webpageData.lsm;
});

// product info is for categories and deals
export const selectProdatypeinfo = createSelector(
  selectWebpageData,
  (webpageData) => {
    if (!webpageData) {
      return null;
    }

    const data = webpageData?.prodatypeinfo;

    return data;
  }
);

export const selectMenuItems = createSelector(
  [selectWebpageData, (_: RootState, category: string) => category],
  (webpageData, category) => {
    if (!webpageData) {
      return null;
    }

    const data = webpageData?.prodatypeinfo[category]?.catlst ?? null;

    return data;
  }
);

export const selectCategoryMenuItems = createSelector(
  [selectWebpageData, (_: RootState, category: string) => category],
  (webpageData, category) => {
    if (!webpageData) {
      return null;
    }

    const data = webpageData?.prodatypeinfo[category] ?? null;

    return data ? { [category]: data } : data;
  }
);

export const selectAtypelst = createSelector(
  selectWebpageData,
  (webpageData) => {
    if (!webpageData) {
      return null;
    }

    const data = webpageData?.prodatypeinfo?.atypelst ?? null;

    return data;
  }
);

export const selectSelectedAtypeCategory = createSelector(
  selectWebpageData,
  (webpageData) => {
    if (!webpageData) {
      return null;
    }

    const data =
      webpageData?.prodatypeinfo?.selected ||
      webpageData?.prodatypeinfo?.catlst?.atype;
    return data;
  }
);

// export const selectSelectedAtypeData = createSelector(
//   selectWebpageData,
//   selectSelectedAtypeCategory,
//   (webpageData, selectedAtype) => {
//     if (!webpageData) {
//       return null;
//     }

//     const data = webpageData?.prodatypeinfo[selectedAtype] ?? null;
//     return data;
//   }
// );

export const selectSelectedAtypeData = createSelector(
  [selectWebpageData, (_, atype) => atype],
  (webpageData, atype) => {
    if (!webpageData) {
      return null;
    }

    const data = webpageData?.prodatypeinfo[atype] ?? null;
    return data;
  }
);

export const selectSplitCategory = createSelector(
  selectWebpageData,
  (webpageData) => {
    if (!webpageData) {
      return null;
    }

    const splitcatlst = webpageData?.prodatypeinfo?.catlst?.splitcatlst ?? null;
    if (splitcatlst) {
      const categories = Object.keys(splitcatlst);
      return {
        atype: webpageData?.prodatypeinfo?.catlst?.atype,
        categories: categories,
        splitcatlst: splitcatlst,
      };
    }
    return null;
  }
);

export const selectBookingAndRentalData = createSelector(
  selectWebpageData,
  (webpageData) => {
    if (!webpageData) {
      return null;
    }

    return webpageData?.prodatypeinfo?.catlst?.rspinfo ?? null;
  }
);

export const selectCatlst = createSelector(selectWebpageData, (webpageData) => {
  if (!webpageData) {
    return null;
  }

  const data = webpageData?.prodatypeinfo?.catlst?.catlst ?? null;

  return data;
});

export const selectDeals = createSelector(selectWebpageData, (webpageData) => {
  if (!webpageData) {
    return null;
  }

  const data = webpageData?.prodatypeinfo?.catlst?.dealsdata ?? null;

  return data;
});

export const selectShopAbout = createSelector(
  selectWebpageData,
  (webpageData) => {
    if (!webpageData) {
      return null;
    }

    const data = webpageData.lsm;

    return {
      id: data.serviceID,
      email: data.email,
      name: data.servicename,
      phoneNo: data.phone,
      address: data.adm,
      logoImage: data.logoimage,
      ratingCount: data.ratingcount,
      totalRatings: data.totalratings,
      serviceType: data.servicetype,
      serviceCategory: data.servicecat,
    };
  }
);

export const selectShopHeroContent = createSelector(
  selectWebpageData,
  (webPageData) => {
    if (!webPageData) {
      return null;
    }

    const bannerData = webPageData.heroimage;
    const sliderData = webPageData.heroimagelst;

    if (bannerData || sliderData) {
      return {
        type: bannerData ? "banner" : "slider",
        imageList: bannerData || sliderData,
      };
    }
  }
);

export const selectHasMoreMembers = createSelector(
  selectWebpageData,
  (webPageData) => {
    if (!webPageData) {
      return null;
    }

    const hasMembers =
      webPageData.picustmemberinfo?.hasmembers || // for market ui
      webPageData.pimanagementandcuri?.hasmembers; // for shop ui

    if (!hasMembers) {
      return false;
    }

    const endCursor =
      webPageData.picustmemberinfo?.endcursor ||
      webPageData.pimanagementandcuri?.endcursor;

    return {
      hasMembers: hasMembers,
      endCursor: endCursor,
    };
  }
);

export const selectShopManagement = createSelector(
  selectWebpageData,
  (webPageData) => {
    if (!webPageData) {
      return null;
    }

    const managementList =
      webPageData.picustmemberinfo?.infwithimg ||
      webPageData.pijustmanagement?.infwithimg ||
      webPageData.pimanagementandcuri?.infwithimg;

    if (!managementList) {
      return null;
    }

    return managementList.map((management, index) => {
      return {
        id: `${management.title}${management.info2}${index}`,
        imageList: management.difresimglst,
        title: `${management.title}`,
        info1: management.info1,
        info2: management.info2,
      };
    });
  }
);

export const selectFeatureList = createSelector(
  selectWebpageData,
  (webPageData) => {
    if (!webPageData) {
      return null;
    }

    const featureList = webPageData.featurelist;

    if (!featureList) {
      return null;
    }

    return featureList.map((feature, index) => {
      return {
        id: `${feature.title}${feature.info2}${index}`,
        imageList: feature.difresimglst,
        title: `${feature.title}`,
        info1: feature.info1,
        info2: feature.info2,
      };
    });
  }
);

export const selectTestimonialList = createSelector(
  selectWebpageData,
  (webPageData) => {
    if (!webPageData) {
      return null;
    }

    const testimonialList = webPageData.testimonial;

    if (!testimonialList || testimonialList?.length === 0) {
      return null;
    }

    return testimonialList.map((feature, index) => {
      return {
        id: `${feature.title}${feature.info2}${index}`,
        imageList: feature.difresimglst,
        title: `${feature.title} (${feature.info1})`,
        subtitle: feature.info2,
      };
    });
  }
);

// School Start
export const selectGradeInfo = createSelector(
  selectWebpageData,
  (webPageData) => {
    if (!webPageData) {
      return null;
    }

    const gradeInfo = webPageData.gsit;

    if (!gradeInfo) return null;

    return {
      isSchool: gradeInfo.isschool,
      classList: gradeInfo.curlst,
      selectedClass: gradeInfo?.bci?.grade,
      sectionList: gradeInfo?.bci?.sections,
      mainSubjects: gradeInfo?.bci?.mansub,
      electiveSubjects: gradeInfo?.bci?.electsub,
      openForAdmission: gradeInfo?.bci?.openforadm,
      openForAdmissionInfo: gradeInfo?.bci?.futuresessioninfo,
      sessioninf: gradeInfo?.bci?.sessioninf,
      teachersList: gradeInfo?.bci?.teacherimages?.map((teacher, index) => {
        return {
          id: `${teacher.title}${teacher.info2}${index}`,
          imageList: teacher.difresimglst,
          name: `${teacher.title}`,
          education: teacher.info1,
          info: teacher.info2,
        };
      }),
      classPhotoLst: gradeInfo?.bci?.classphotolst?.map((cls, index) => {
        return {
          id: cls.imgname,
          imageList: cls.difresimglst,
        };
      }),
    };
  }
);
// School End

export const selectShopDeals = createSelector(
  selectWebpageData,
  (webPageData) => {
    if (!webPageData) {
      return null;
    }

    const dealsData = webPageData?.dealsdata;

    return dealsData?.docwithdata1?.map((deal) => ({
      id: deal.productid,
      productid: deal.productid,
      imageList: deal.imagewithsize.difresimglst,
      tileimage: deal.tileimage,
      title: deal.title,
      origprice: deal.origprice,
      category: deal.category,
      packdata: deal.packdata,
      perioddiscountlst: deal.perioddiscountlst,
      taxbracket: deal.taxbracket,
      gsttoken: deal.gsttoken,
      reportid: deal.reportid,
      varianttype: deal.varianttype,
      unitcount: deal.unitcount,
      unitmeasure: deal.unitmeasure,
      discountedprice: deal.discountedprice,
    }));
  }
);

export const selectShopProducts = createSelector(
  selectWebpageData,
  (webPageData) => {
    if (!webPageData) {
      return null;
    }

    const productData = webPageData.proddata;
    const categories = productData?.catlst;

    let productsData = {};
    productData?.dataforcat.forEach((currentCategory) => {
      const productList =
        currentCategory.prodlst.docwithdata1 ||
        currentCategory.prodlst.docwithdata2 ||
        currentCategory.prodlst.docwithdata3 ||
        currentCategory.prodlst.docwithdata4 ||
        currentCategory.prodlst.docwithdata5;

      const products = productList.map((product) => ({
        id: product.productid,
        productid: product.productid,
        imageList: product.imagewithsize.difresimglst,
        tileimage: product.tileimage,
        title: product.title,
        origprice: product.origprice,
        category: product.category,
        packdata: product.packdata,
        perioddiscountlst: product.perioddiscountlst,
        taxbracket: product.taxbracket,
        gsttoken: product.gsttoken,
        reportid: product.reportid,
        varianttype: product.varianttype,
        unitcount: product.unitcount,
        unitmeasure: product.unitmeasure,
        discountedprice: product.discountedprice,
      }));

      const categories = Array.from(
        new Set(products.map((product) => product.category))
      );

      productsData[currentCategory.cat] = { categories, products };
    });

    return (
      productData && {
        categories,
        products: productsData,
      }
    );
  }
);

export const selectProductsForCategory = createSelector(selectSelf, (state) => {
  const categoryProducts = state.categoryProducts.data;

  if (!categoryProducts) {
    return null;
  }

  const productData = categoryProducts.pfrm.docwithdata1;

  return productData.map((product) => ({
    id: product.productid,
    productid: product.productid,
    imageList: product.imagewithsize.difresimglst,
    tileimage: product.tileimage,
    title: product.title,
    origprice: product.origprice,
    category: product.category,
    packdata: product.packdata,
    perioddiscountlst: product.perioddiscountlst,
    taxbracket: product.taxbracket,
    gsttoken: product.gsttoken,
    reportid: product.reportid,
    varianttype: product.varianttype,
    unitcount: product.unitcount,
    unitmeasure: product.unitmeasure,
    discountedprice: product.discountedprice,
  }));
});

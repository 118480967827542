import { createBrowserRouter } from 'react-router-dom';

import App from './App';
import AppLayout from './AppLayout';
import { AppointmentCartPage } from './components/appointment/appointment-cart-view';
import { AppointmentDetailsView } from './components/appointment/appointment-details';
import { BookTime } from './components/appointment/book-time';
import { PersonalDetailsView } from './components/appointment/personal-details';
import { SelectEmployee } from './components/appointment/select-employee';
import { CartPage } from './components/cart/cart-view';
import CToastInfoDialog from './components/CToastInfoDialog';
import { Chat } from './components/pages/chat';
import { RoutePaths } from './constants';
import { AppointmentListView } from './pages/appointment-cart/appointment-list-view';
import { AppointmentFilterView } from './pages/appointment-view';
import { Login, Signup } from './pages/auth';
import { RentalsProductListSPView } from './pages/booking/rental-product-list-sp-view';
import { RentalSpListWithFilter } from './pages/booking/rental-sp-list-with-fliter';
import { ProductListView } from './pages/cart-product/product-list-view';
import ErrorPage from './pages/ErrorPage';
import { ProviderDetailsPage, ProvidersPage } from './pages/home';
import { ProductDetailsPage } from './pages/home/product-details-page';
import { JobDetailsPage } from './pages/job/job-details-page';
import { JobsPage } from './pages/job/jobs-page';
import { OrderHistory } from './pages/order-history';
import { PetDetailsPage } from './pages/pet/pet-details-page';
import { PetsPage } from './pages/pet/pets-page';
import { ProtectedRoute } from './pages/ProtectedRoute';
import { RealEstateDetailsPage } from './pages/real-estate/real-estate-details-page';
import { RealEstatesPage } from './pages/real-estate/real-estates-page';
import { EndUserList } from './pages/trip-search';
import { TripStatusView } from './pages/trip-status-view';
import { TripView } from './pages/trip-view';
import { VehicleDetailsPage } from './pages/vehicle/vehicle-details-page';
import { VehiclesPage } from './pages/vehicle/vehicles-page';
import { OTPPage } from './pages/auth/otp-page';
import { Register } from './pages/auth/register-page';
import { UserPanel } from './pages/user-panel';
import { TestPage } from './pages/test-page';

export const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        index: true,
        element: <AppLayout />,
      },
      {
        path: "/:providersType",
        element: (
          <ProtectedRoute>
            <ProvidersPage />
          </ProtectedRoute>
        ),
      },
      {
        path: "/product-list/product-item",
        element: (
          <ProtectedRoute>
            <ProductListView />
          </ProtectedRoute>
        ),
      },
      {
        path: "/:providersType/:providerId",
        element: <ProviderDetailsPage />,
      },
      {
        path: "/product/product-details",
        element: <ProductDetailsPage />,
      },
      {
        path: "/vehicle",
        element: <VehiclesPage />,
      },
      {
        path: "/vehicle/vehicle-details",
        element: <VehicleDetailsPage />,
      },
      {
        path: "/job",
        element: <JobsPage />,
      },
      {
        path: "/job/job-details",
        element: <JobDetailsPage />,
      },
      {
        path: "/pet",
        element: <PetsPage />,
      },
      {
        path: "/pet/pet-details",
        element: <PetDetailsPage />,
      },
      {
        path: "/real-estate",
        element: <RealEstatesPage />,
      },
      {
        path: "/real-estate/real-estate-details",
        element: <RealEstateDetailsPage />,
      },
      {
        path: "/cart",
        element: (
          <ProtectedRoute>
            <CartPage />
          </ProtectedRoute>
        ),
      },
      {
        path: "/appointment-cart",
        element: (
          <ProtectedRoute>
            <AppointmentCartPage />
          </ProtectedRoute>
        ),
      },
      {
        path: "/appointment-filter",
        element: <AppointmentFilterView />,
      },
      {
        path: "/appointment-list",
        element: <AppointmentListView />,
      },
      {
        path: "/appointment-cart/booktime",
        element: <BookTime />,
      },
      {
        path: "/appointment-cart/select-employee",
        element: <SelectEmployee />,
      },
      {
        path: "/personal-details",
        element: <PersonalDetailsView />,
      },
      {
        path: "/appointment-details",
        element: <AppointmentDetailsView />,
      },
      {
        path: "/rental-sp-list-with-filter",
        element: <RentalSpListWithFilter />,
      },
      {
        path: "/rental-sp-list-with-filter/rentals-product-list-sp-view",
        element: <RentalsProductListSPView />,
      },
      {
        path: "/order-history",
        element: <OrderHistory />,
      },
      {
        path: "/trip",
        element: <TripView />,
      },
      {
        path: "/trip-status",
        element: <TripStatusView />,
      },
      {
        path: "/chat",
        element: <Chat />,
      },
      {
        path: "/end-user",
        element: <EndUserList />,
      },
      {
        path: "/user-panel",
        element: <UserPanel />,
      },
      {
        path: "/test-sizes",
        element: <TestPage />,
      },
    ],
  },
  {
    path: RoutePaths.LOGIN,
    element: (
      <>
        <CToastInfoDialog />
        <Login />
      </>
    ),
  },
  {
    path: RoutePaths.SIGN_UP,
    element: (
      <>
        <CToastInfoDialog />
        <Signup />
      </>
    ),
  },
  {
    path: RoutePaths.REGISTER,
    element: (
      <>
        <CToastInfoDialog />
        <Register />
      </>
    ),
  },
  {
    path: RoutePaths.OTP,
    element: (
      <>
        <CToastInfoDialog />
        <OTPPage />
      </>
    ),
  },
  {
    path: "/*",
    element: <ErrorPage />,
  },
]);

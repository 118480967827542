import { createAsyncThunk } from '@reduxjs/toolkit';
import { addDays } from 'date-fns';
import { auth } from 'src/configs/firebase';
import { BaseUrl } from 'src/constants';
import * as CONSTANTS from 'src/constants';
import { sendRequestJSON } from 'src/lib/axios';

import { calculateupdatedprice, setCheckoutFinalState, setCheckoutInitState, updateCartState } from '.';
import { toastMessage } from '../commonSlice';
import { selectEntityId } from '../landing-page/selectors';
import { CartOrigin, ICartSlice, ICartState, ISaveUserAddress, orderinfomodel } from './types';

function getEntityId(state: ICartState) {
  if (state.lsm) {
    return state.lsm.serviceID;
  } else {
    return state.sm.serviceID;
  }
}

// apis
export const getRentalProductInventoryStatus = createAsyncThunk(
  "cart/getRentalProductInventoryStatus",
  async (payload: any, { dispatch, getState }) => {
    const state: ICartSlice = (getState() as any).cart;

    const entityid = getEntityId(state.cartState);

    try {
      const response = await sendRequestJSON(
        {
          data: {
            orderactionrequest: {
              action: "getstatus",
              entityid: entityid,
              orderid: null,
              version: state.cartState?.cart?.version ?? 0,
              dt: CONSTANTS.toTimeStamp(payload.sdt),
              edt: CONSTANTS.toTimeStamp(payload.edt),
              prd: payload.pd,
              tzoffset: Math.abs(new Date().getTimezoneOffset() * 60),
            },
            qrytype: "RENTALORDERACTION",
          },
        },
        "",
        "post",
        BaseUrl.digital
      );

      let res = JSON.parse(response.result?.data?.data);

      if (res.errorid === -1) {
        dispatch(
          updateCartState({
            inventsta: res.totalcat,
            loading: false,
            hasError: false,
          })
        );
      } else {
        dispatch(
          toastMessage({
            error: true,
            message:
              "Action could not be completed, Please try again -" +
              (res?.errormsg ?? ""),
          })
        );
      }
      return response.result.data;
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return false;
    }
  }
);

export const saveUserAddress = createAsyncThunk(
  "cart/saveUserAddress",
  async (payload: ISaveUserAddress, { dispatch }) => {
    try {
      const response = await sendRequestJSON(
        {
          data: {
            ugm: {
              useraddresslist: [
                {
                  shortname: payload.address.shortname,
                  adm: payload.address.adm,
                },
              ],
              uid: payload.uid,
              action: "saveaddress",
              sid: payload.sid,
              tzoffset: Math.abs(new Date().getTimezoneOffset() * 60),
            },
            qrytype: "USERDBA",
          },
        },
        "",
        "post",
        BaseUrl.digital
      );
      return response.result.data;
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return false;
    }
  }
);
export const deleteUserAddress = createAsyncThunk(
  "cart/deleteUserAddress",
  async (payload: ISaveUserAddress, { dispatch }) => {
    try {
      const response = await sendRequestJSON(
        {
          data: {
            ugm: {
              useraddresslist: [
                {
                  shortname: payload.address.shortname,
                  adm: payload.address.adm,
                },
              ],
              uid: payload.uid,
              action: "removeaddress",
              sid: payload.sid,
              tzoffset: Math.abs(new Date().getTimezoneOffset() * 60),
            },
            qrytype: "USERDBA",
          },
        },
        "",
        "post",
        BaseUrl.digital
      );
      return response.result.data;
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return false;
    }
  }
);

interface CheckoutORderProps {
  cart: orderinfomodel;
  entitytype?: string;
  entityid?: string;
  origin?: CartOrigin;
}

interface SaveOrderCloudFuncProps {
  entitytype?: string;
  entityid?: string;
  oif?: any;
  orderid?: string;
  status?: string;
  action?: string;
  requireof?: boolean;
  origin?: string;
}

const saveOrderCloudFunc = async ({
  action,
  entityid,
  entitytype,
  oif,
  orderid,
  origin,
  requireof,
  status,
}: SaveOrderCloudFuncProps) => {
  let odata = null;
  if (oif !== null && oif.oorigin !== null) {
    if (
      oif.oorigin!.includes("EcomEndUser") ||
      oif.oorigin!.includes("AppointmentEndUser")
    ) {
      let pif = {
        ...oif,
        custuserid: auth.currentUser.uid,
        custname: auth.currentUser.displayName,
        custphonenum: auth.currentUser.phoneNumber,
      };
      odata = pif;
    } else {
      odata = oif;
    }
  } else {
    if (oif !== null && oif.oorigin === null) {
      odata = oif;
    }
  }
  const now = new Date(Date.now());
  const date = new Date(
    `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}`
  );
  let orderAction: any = {
    action: action,
    orderinfo: odata !== null ? odata : null,
    orderid: orderid,
    dt: CONSTANTS.toTimeStamp(date),
    requireof: requireof,
    origin: "NOTIMPLEMENTED",
    entitytype: entitytype,
    entityid: entityid,
    tzoffset: Math.abs(new Date().getTimezoneOffset() * 60),
  };
  if (status !== null) {
    orderAction.status = status;
  }

  const response = await sendRequestJSON(
    {
      data: {
        orderactionrequest: orderAction,
        qrytype: "ORDER",
      },
    },
    "",
    "post",
    BaseUrl.digital
  );
  return response.result.data;
};

const checkoutORder = async ({
  cart,
  entityid,
  entitytype,
  origin,
}: CheckoutORderProps) => {
  const om = {
    ...cart,
    oorigin: origin.toString(),
  };
  let cartID = null;
  try {
    cartID = parseInt(cart.id);
  } catch (e) {}
  if (
    origin === CartOrigin.EcomEndUser ||
    origin === CartOrigin.OrderManagementOwner ||
    (cart.id !== null && cart.id !== "APPOINTMENT" && cartID !== null)
  ) {
    const ordersRaw = await saveOrderCloudFunc({
      entitytype: entitytype,
      entityid: entityid,
      oif: {
        ...cart,
        sdate: CONSTANTS.toTimeStamp(cart?.sdate) ?? null,
        edate: CONSTANTS.toTimeStamp(cart?.edate) ?? null,
      },
      action: "CHECKOUT",
      requireof: true,
    });
    return ordersRaw;
  } else {
    return {
      errorid: -1,
      of: { ...om, id: "APPOINTMENT" },
      orderid: "APPOINTMENT",
    };
  }
};

export const cartCheckout = createAsyncThunk(
  "cart/cartCheckout",
  async (payload: any, { dispatch, getState }) => {
    const state: ICartSlice = (getState() as any).cart;
    dispatch(setCheckoutInitState(payload));
    const entityId = selectEntityId(getState());
    try {
      let res: any = await checkoutORder({
        cart: {
          ...state.cartState.cart!,
          oorigin: state.cartState.calledfrom
            .toString()
            .replaceAll("CartOrigin.", ""),
        },
        entitytype: state.cartState.entitytype,
        entityid: state?.cartState?.entityid || entityId,
        origin: state.cartState.calledfrom,
      });

      res = JSON.parse(res?.data);

      if (res?.errorid === -1) {
        if (
          state.cartState.calledfrom !== null &&
          state.cartState.calledfrom
            .toString()
            .toLowerCase()
            .includes("owner") &&
          res.ordercard !== null
        ) {
          // GlobalBlocAccessor()
          //     .createOrderListBloc()
          //     .add(UpdateOrderItemState(oldi: res.ordercard!, entityid: state.entityid!));
        }

        let newCart = { ...state.cartState.cart };
        newCart.id = res.orderid;

        dispatch(
          setCheckoutFinalState({
            cart: newCart,
            ordersaved: true,
          })
        );
      } else {
        dispatch(
          toastMessage({
            error: true,
            message: res.errormsg ?? "Error has occured please try again",
          })
        );
        dispatch(
          setCheckoutFinalState({
            error: res.errormsg,
          })
        );
      }

      return true;
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return false;
    }
  }
);

// events

export const addedProductCartEvent = createAsyncThunk(
  "cart/addedProductCartEvent",
  async (payload: any, { dispatch, getState }) => {
    const state: ICartSlice = (getState() as any).cart;
    try {
      let products = [...state.cartState.cart!.productlist!];
      let filteredProds = products.filter(
        (product) => product.itemid === payload.product.itemid
      );
      if (filteredProds.length > 0) {
        dispatch(
          changedQuantityCartEvent({
            newQuantity: payload.product.qty!,
            product: payload.product,
            fromProductsList: true,
          })
        );
      } else {
        const updatedProducts = [...products, payload.product];
        var mkdt =
          payload.sdt ??
          payload.sdt ??
          CONSTANTS.getJustDatePart(new Date(Date.now()));
        var mkdtplus1 =
          payload.edt ??
          payload.edt ??
          CONSTANTS.getJustDatePart(addDays(mkdt, 1));
        dispatch(
          calculateupdatedprice({
            prodList: updatedProducts ?? [],
            sdt: mkdt,
            edt: mkdtplus1,
            state,
          })
        );
      }
      return true;
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return false;
    }
  }
);

export const changedQuantityCartEvent = createAsyncThunk(
  "cart/changedQuantityCartEvent",
  async (payload: any, { dispatch, getState }) => {
    const state: ICartSlice = (getState() as any).cart;
    try {
      if (payload.fromProductsList !== null && payload.fromProductsList!) {
        let products = [...state.cartState.cart!.productlist!];
        let index = products.findIndex(
          (product) => product.itemid === payload.product!.itemid
        );
        let oldQuantity = products[index].qty!;
        // let oldProductPrice = products[index].totalprice!;
        // let pr = products[index];
        // let np = payload.product;

        if (
          products[index].itemid === payload.product!.itemid &&
          products[index].totalprice === payload.product!.totalprice &&
          products[index].unit === payload.product!.unit
        ) {
          let newq = oldQuantity + 1;
          let newprod = { ...products![index], qty: newq };
          let updatedProducts = [
            ...products.slice(0, index),
            ...products.slice(index + 1),
          ];
          updatedProducts = [
            ...updatedProducts.slice(0, index),
            newprod,
            ...updatedProducts.slice(index),
          ];
          dispatch(
            calculateupdatedprice({
              prodList: updatedProducts ?? [],
              sdt: state.cartState.cart!.sdate,
              edt: state.cartState.cart!.edate,
              state,
            })
          );
        } else {
          let updatedProducts = [
            ...products.slice(0, index),
            ...products.slice(index + 1),
          ];
          if (payload.newQuantity! > 0)
            updatedProducts = [
              ...updatedProducts.slice(0, index),
              payload.product!,
              ...updatedProducts.slice(index),
            ];
          dispatch(
            calculateupdatedprice({
              prodList: updatedProducts ?? [],
              sdt: state.cartState.cart!.sdate,
              edt: state.cartState.cart!.edate,
              state,
            })
          );
        }
      } else {
        let products = [...state.cartState.cart!.productlist!];
        let index = products.findIndex(
          (product) => product.itemid === payload.product!.itemid
        );
        // let oldQuantity = products[index].qty!;
        let updatedProducts = products.filter((_, i) => i !== index);
        if (payload.newQuantity! > 0) {
          const newelement = {
            ...payload.product,
            qty: payload.newQuantity,
          };
          updatedProducts.splice(index, 0, newelement);
        }
        dispatch(
          calculateupdatedprice({
            prodList: updatedProducts ?? [],
            sdt: state.cartState.cart!.sdate,
            edt: state.cartState.cart!.edate,
            state,
          })
        );
      }
      return true;
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return false;
    }
  }
);

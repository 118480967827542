/* eslint-disable */
import { Box, FormControlLabel, Grid, Paper, Switch, useMediaQuery, useTheme } from '@mui/material';
import { Form, Formik } from 'formik';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CButton from 'src/components/button-group/CButton';
import FormikControl from 'src/components/formik/FormikControl';
import { TripLookupAutocomplete } from 'src/components/pages/trips/TripLookupAutocomplete';
import { useGeolocationContext } from 'src/contexts/GeolocationContext';
import { selectProductState } from 'src/slices/product/selectors';
import { ProductState } from 'src/slices/product/types';
import { clearEndUserTripState } from 'src/slices/trip';
import { selectEndUserSearchResults } from 'src/slices/trip/selectors';
import { getEndUserSearchResults } from 'src/slices/trip/thunks';
import { DriverCard } from 'src/slices/trip/types';
import { AppDispatch } from 'src/store';

import { UserListItem } from './user-list-item';


export const EndUserList = () => {
  const dispatch = useDispatch<AppDispatch>();
  const formikRef = useRef(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const productState: ProductState = useSelector(selectProductState);
  const endUserSearchResults = useSelector(selectEndUserSearchResults);
  const { district } = useGeolocationContext();

  const [driversList, setDriversList] = useState<DriverCard[]>([]);

  function getValueFromGenericNameValueList(mlst, name) {
    if (mlst !== null && mlst !== undefined) {
      for (var i = 0; i < mlst.length; i++) {
        var k = mlst[i];
        if (k.name === name) {
          return k.value;
        }
      }
    }

    return null;
  }
  const toDriverCard = (data, routid, tripid) => {
    var dclst = [];
    var mspid = data.spid;
    var mspname = data.spname;
    var driname = data.staff1 ? data.staff1.name : null;
    var driphone = data.staff1 ? data.staff1.info : null;
    var driphoto = getValueFromGenericNameValueList(
      data.vehicleModel ? data.vehicleModel.info1 : null,
      "photo"
    );
    var vehnum = data.vehicleModel ? data.vehicleModel.name : null;
    var vehid = getValueFromGenericNameValueList(
      data.vehicleModel ? data.vehicleModel.info1 : null,
      "vid"
    );
    var vehphoto = getValueFromGenericNameValueList(
      data.vehicleModel ? data.vehicleModel.info1 : null,
      "vph"
    );
    var driid = getValueFromGenericNameValueList(
      data.vehicleModel ? data.vehicleModel.info1 : null,
      "did"
    );
    var routename = routid;
    var vehinfo = data.vehicleModel ? data.vehicleModel.info : null;
    var vehtyp, vehmake;
    if (vehinfo) {
      var kp = vehinfo.split("@");
      vehtyp = kp[0];
      vehmake = kp[1];
    }

    var dc = {
      spid: mspid,
      spname: mspname,
      driname: driname,
      driphone: driphone,
      driphoto: driphoto,
      vehphoto: vehphoto,
      vehnum: vehnum,
      vehtype: vehtyp,
      vehmake: vehmake,
      routename: routename,
      vehid: vehid,
      driid: driid,
    };

    if (data.rtl && data.rtl.length) {
      for (var i = 0; i < data.rtl.length; i++) {
        var k = data.rtl[i];
        if (!k.rtl) continue;
        for (var j = 0; j < k.rtl.length; j++) {
          var p = k.rtl[j];
          if (tripid && p.value !== tripid) continue;
          var stime = 0;
          var lati = 0;
          var longi = 0;
          var mk = p.name.split("@");
          if (mk.length === 2) {
            var pq = mk[1].split(":");
            stime = (parseInt(pq[0]) || 0) * 60 + (parseInt(pq[1]) || 0);
          }
          if (tripid) {
            lati = lati;
            longi = longi;
          }
          var d = Object.assign({}, dc, {
            stime: stime,
            tripname: p.name,
            tripid: p.value,
            routename: k.rm ? k.rm.name : null,
            relationid: data.id,
          });
          dclst.push(d);
        }
      }
    }
    return dclst;
  };

  function getRelationById(id) {
    return endUserSearchResults?.relationlist?.find(
      (element) => element.id === id
    );
  }

  function getRouteMainPoints(relation, routename) {
    const { rtl } = relation;
    if (rtl !== null && rtl !== undefined) {
      for (var i = 0; i < rtl.length; i++) {
        var v = rtl[i];
        if (v.rm && v.rm.name === routename) {
          return v.rm.mainPoints || [];
        }
      }
    }
    return [];
  }

  const pepareDataForUserItem = (driver) => {
    const relation = getRelationById(driver.relationid);
    const mainPoints =
      getRouteMainPoints(relation, driver.routename ?? "") ?? [];

    return {
      driver,
      mainPoints,
    };
  };

  useEffect(() => {
    if (endUserSearchResults?.relationlist) {
      const newLst = endUserSearchResults?.relationlist?.flatMap((relation) =>
        toDriverCard(relation, null, null)
      );
      setDriversList(newLst);
    }
  }, [endUserSearchResults?.relationlist]);

  useEffect(() => {
    return () => {
      dispatch(clearEndUserTripState());
    };
  }, []);

  // Form Inital Values
  const formInitialValue = {
    showActiveTrips: true,
    startPoint: "",
    endPoint: "",
    tripType: "People",
    dateOfTravel: new Date(Date.now()),
  };

  return (
    <Box width='100%' sx={{ p: 2, display: "flex", alignSelf: "center" }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper>
            <Formik
              initialValues={formInitialValue}
              innerRef={formikRef}
              onSubmit={(values) => {
                dispatch(
                  getEndUserSearchResults({
                    state: district,
                    nidlst: [{ name: "lt", vallst: [values.tripType] }],
                    ...values,
                  })
                );
              }}
            >
              {({ values, touched, errors, setFieldValue, getFieldProps }) => (
                <Form style={{ display: "flex", justifyContent: "center" }}>
                  {/* Complaint info */}
                  <Grid
                    container
                    spacing={1}
                    pb={3}
                    sx={{
                      width: isMobile ? "100%" : "60%",
                      alignSelf: "center",
                      textAlign: "-webkit-center",
                    }}
                  >
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={values.showActiveTrips}
                            onChange={(e) => {
                              setFieldValue(
                                "showActiveTrips",
                                e.target.checked
                              );
                            }}
                          />
                        }
                        label="Show Active Trips"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormikControl
                        control="Radio"
                        showInRow
                        required
                        name="tripType"
                        error={Boolean(touched.tripType && errors.tripType)}
                        helperText={touched.tripType && errors.tripType}
                        {...getFieldProps("tripType")}
                        options={[
                          {
                            value: "People",
                            label: "People",
                          },
                          {
                            value: "Goods",
                            label: "Goods",
                          },
                        ]}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TripLookupAutocomplete
                        label="Start Point"
                        onSelect={(selectedPoint) => {
                          setFieldValue("startPoint", selectedPoint);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TripLookupAutocomplete
                        label="End Point"
                        onSelect={(selectedPoint) => {
                          setFieldValue("endPoint", selectedPoint);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormikControl
                        control="CalendarTime"
                        label="Date of Travel"
                        dateOrTimeOnly="date"
                        name="dateOfTravel"
                        error={Boolean(
                          touched.dateOfTravel && errors.dateOfTravel
                        )}
                        helperText={touched.dateOfTravel && errors.dateOfTravel}
                        {...getFieldProps("dateOfTravel")}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <CButton
                        sx={{ color: "white" }}
                        variant="contained"
                        type="submit"
                      >
                        Go
                      </CButton>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Paper>
        </Grid>
        {driversList?.map((driver) => (
          <Grid item xs={12} md={6}>
            <UserListItem {...pepareDataForUserItem(driver)} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

import { Box } from "@mui/material";
import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api";
import { FC } from "react";

interface IMarkerProps {
  lat: number;
  lng: number;
  icon?: string;
}

interface IStaticGoogleMap {
  latlngs: IMarkerProps[];
  height: number | string;
  width: number | string;
  zoom?: number;
}

const StaticGoogleMap: FC<IStaticGoogleMap> = ({
  latlngs,
  height,
  width,
  zoom = 10,
}) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  });

  const getCenter = () => {
    var bound = new google.maps.LatLngBounds();

    for (let i = 0; i < latlngs.length; i++) {
      bound.extend(new google.maps.LatLng(latlngs[i].lat, latlngs[i].lng));
    }

    return bound.getCenter();
  };

  return (
    <Box
      sx={{
        height: height,
        width: width,
      }}
    >
      {!isLoaded ? (
        <></>
      ) : (
        <GoogleMap
          mapContainerStyle={{
            height: "100%",
            width: "100%",
          }}
          center={getCenter()}
          zoom={zoom}
        >
          {latlngs.map((latlng, index) => (
            <MarkerF
              key={latlng.lat+index}
              position={latlng}
              icon={
                latlng.icon ??
                "http://maps.google.com/mapfiles/ms/icons/red-dot.png"
              }
            />
          ))}
        </GoogleMap>
      )}
    </Box>
  );
};

export default StaticGoogleMap;

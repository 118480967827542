import { Box, styled, useMediaQuery, useTheme } from "@mui/material";
import { FC } from "react";
import CTypography from "src/components/CTypography";
import { ICartState } from "src/slices/cart/types";
import { CartCounter } from "../../counter";
import { changedQuantityCartEvent } from "src/slices/cart/thunks";
import { useDispatch } from "react-redux";
import { AppDispatch } from "src/store";
import CButton from "src/components/button-group/CButton";
import { useDisplaySettingsContext } from "src/contexts/DisplaySettings";

const CBox = styled(Box)(({ theme }) => ({
  height: "50px",
  width: "100px",
  display: "flex",
  background: theme.palette.primary.main,
  alignItems: "center",
  justifyContent: "center",
  color: "white",
  borderRadius: "5px",
}));

interface OrderCartItemProps {
  item?: any;
  modifiableStatuses?: string[];
  isnoneditable?: boolean;
  state?: ICartState;
  isRental?: boolean;
  showrentalitemselector?: boolean;
  showrentalreturnbutton?: boolean;
  showrentalitemstatus?: boolean;
  showitembuildstatus?: boolean;
  allowbuildstateupdate?: boolean;
  showitemstate?: boolean;
  allowitemstateupdate?: boolean;
  showresouurcepicekeroncard?: boolean;
  showresourceavailabilityonitemcard?: boolean;
  startDate?: Date;
  endDate?: Date;
  onRentStatusChange?: Function;
  isActive?: boolean;
}

export const OrderCartItem: FC<OrderCartItemProps> = ({
  item,
  modifiableStatuses,
  state,
  isnoneditable,
  showitemstate,
  allowitemstateupdate,
  isRental,
  isActive,
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { heading2, heading3, text1, text2, text3 } = useDisplaySettingsContext();
  
  return (
    <Box
      sx={{
        border: "1px solid black",
        borderRadius: "5px",
        width: "100%",
        [theme.breakpoints.up("md")]: { height: "150px" },
        [theme.breakpoints.down("md")]: {
          flexDirection: "column",
          textAlign: "center",
        },
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "5px",
        padding: "10px 10px 10px 10px",
        marginBottom: "10px",
      }}
    >
      <Box
        minHeight={isMobile ? "100px" : "130px"}
        minWidth={isMobile ? "100px" : "130px"}
        sx={{
          borderRadius: "5px",
          marginRight: "10px",
          backgroundImage: `url(${item.tileimage})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "contain",
        }}
      ></Box>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <CTypography fontSize={text2}>{item.title ?? ""}</CTypography>
        <CTypography fontSize={text1}>{item.unitprice!}</CTypography>
        {showitemstate && (
          <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <CTypography>Current State</CTypography>
            {allowitemstateupdate ? (
              <CButton size="small" variant="outlined">
                Update State
              </CButton>
            ) : (
              <></>
            )}
          </Box>
        )}
        {isRental && !isActive && (
          <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <CButton
              onClick={() => {
                // var _bloc = context.read<CartBloc>();
                // showDialog(
                //   context: context,
                //   builder: (BuildContext cont) => BlocProvider(
                //       create: (cont) => CartBloc(initialState: _bloc.state),
                //       child: InventoryDialog(
                //         endDate: _bloc.state.cart!.edate ?? DateTime.now(),
                //         startDate: _bloc.state.cart!.sdate ?? DateTime.now(),
                //         product: item,
                //       )),
              }}
            >
              Inventory
            </CButton>
          </Box>
        )}
      </Box>
      {!(item?.unit?.length === 0 ?? true) ? (
        <CBox>{item.qty!.toFixed(1)}</CBox>
      ) : (
        <CartCounter
          initValue={parseInt(item.qty!.toFixed(0))}
          onChange={(value) => {
            dispatch(
              changedQuantityCartEvent({
                newQuantity: value,
                product: item,
              })
            );
            // console.log(value);
          }}
        />
      )}
    </Box>
  );
};

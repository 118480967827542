import { Rating, Typography, styled } from "@mui/material";
import MatrialCard from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { useDisplaySettingsContext } from "src/contexts/DisplaySettings";

const ReviewCardContainer = styled(MatrialCard)(() => ({
  width: "290px",
  height: "227px",
  border: "1px solid #65656533",
  padding: "16px",
  gap: "16px",
  textAlign: "left",
  display: "flex",
  flexDirection: "column",
  position: "relative",
}));

const TypographyName = styled(Typography)(() => ({
  fontWeight: 600,
  lineHeight: "24px",
}));

const TypographyReview = styled(Typography)(() => ({
  fontWeight: 400,
  lineHeight: "19px",
}));

export default function ReviewCard({
  name,
  review,
  rating,
  subTitle,
  maxWidth = "290px",
}) {
  const { heading2, text2, foregroundColor } = useDisplaySettingsContext();

  return (
    <ReviewCardContainer sx={{ maxWidth: maxWidth }} variant="elevation">
      <TypographyReview>{review}</TypographyReview>
      <Rating name="read-only" value={rating} readOnly />
      <TypographyName sx={{ color: foregroundColor }} fontSize={heading2}>
        {name}
      </TypographyName>
      <TypographyReview fontSize={text2}>{subTitle}</TypographyReview>
    </ReviewCardContainer>
  );
}

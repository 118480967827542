import { experimentalStyled } from "@mui/material";
import CButton from "./CButton";

const InLineButton = experimentalStyled(CButton)(() => ({
  minWidth: 150,
}));

const ContainedButton = (props) => {
  const { children, onClick, ...rest } = props;

  return (
    <InLineButton
      {...rest}
      onClick={onClick}
      color="primary"
      variant="contained"
      sx={{
        color: 'white'
      }}
    >
      {children}
    </InLineButton>
  );
};
const OutlinedButton = (props) => {
  const { children, onClick, ...rest } = props;

  return (
    <InLineButton
      onClick={onClick}
      color="primary"
      variant="outlined"
      {...rest}
      sx={{
        color: 'white'
      }}
    >
      {children}
    </InLineButton>
  );
};

export { ContainedButton, OutlinedButton };

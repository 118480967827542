import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from "src/hooks/useDebounce";
import { selectGeneralTextLookupResult } from "src/slices/home/selectors";
import { generalTextLookUp } from "src/slices/home/thunks";
import {
  IGeneralTextLookupResult,
  IGeneralTextLookupResultItem,
} from "src/slices/home/types";
import { AppDispatch } from "src/store";

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}
interface IAsyncAutocompleteProps {
  disabled: boolean;
  onSelect: (value: IGeneralTextLookupResultItem | null) => void;
}
export const DistrictAutocomplete: FC<IAsyncAutocompleteProps> = ({
  disabled,
  onSelect,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const searchResults: IGeneralTextLookupResult = useSelector(
    selectGeneralTextLookupResult
  );

  const [value, setValue] = useState<any>(null);
  const [inputValue, setInputValue] = React.useState("");

  const [searchText, setSearchText] = useState("");
  const searchQuery = useDebounce(searchText, 1000);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<
    readonly IGeneralTextLookupResultItem[]
  >([]);
  const [loading, setLoading] = useState(false);
  // const [active, setActive] = useState(true);

  useEffect(() => {
    if (disabled) {
      setValue(null);
      onSelect(null);
      setSearchText("");
      setLoading(false);
    }
  }, [disabled]);

  useEffect(() => {
    let active = true;
    if (!loading) {
      return undefined;
    }

    (async () => {
      if (active && loading && searchResults) {
        setOptions([
          ...searchResults?.listdata.map((data) => ({
            ...data,
            text: data.text.replaceAll("@", ","),
          })),
        ]);
      }
    })();
    return () => {
      active = false;
    };
  }, [loading, searchResults]);

  useEffect(() => {
    setLoading(false);
  }, [searchResults]);

  useEffect(() => {
    dispatch(
      generalTextLookUp({
        dynamicProperty: {
          ltype: "areainfosp",
          dtype: "general",
          qtext: searchQuery,
          stext: "HOTELROOM",
          offset: 0,
        },
      })
    );
  }, [searchQuery]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      id="async-autocomplete"
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => option.text === value.text}
      getOptionLabel={(option: IGeneralTextLookupResultItem) => option.text}
      options={options}
      loading={loading}
      disabled={disabled}
      value={value}
      onChange={(event: any, newValue: any | null) => {
        setValue(newValue);
        onSelect(newValue);
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
        setSearchText(newInputValue);
        if (!loading) {
          setLoading(true);
        }
        // setActive(true);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="District"
          size="small"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

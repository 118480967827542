import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../store";

const selectSelf = (state: RootState) => state;
const selectHome = createSelector(selectSelf, (state) => state.home);

export const selectIsHomeLoading = createSelector(
  selectHome,
  (home) => home.isLoading
);

export const selectHomeProviders = createSelector(
  selectHome,
  (home) => home.providers
);

export const selectServiceProviderLastCall = createSelector(
  selectHome,
  (home) => home.serviceProviderLastCall
);

export const selectHomeSelectedProviders = createSelector(
  selectHome,
  (home) => home.selectedProvider
);

export const selectProductFilterResult = createSelector(
  selectHome,
  (home) => home.fetchProductFilterResult
);

export const selectProductFilterItemDetails = createSelector(
  selectHome,
  (home) => home.productFilterItemDetails
);


export const selectProductFilterResultLastCall = createSelector(
  selectHome,
  (home) => home.productFilterResultLastCall
);

export const selectGeneralTextLookupResult = createSelector(
  selectHome,
  (home) => home.generalTextLookupResult
);
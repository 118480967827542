import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../store";

const selectSelf = (state: RootState) => state;
const selectChat = createSelector(
  selectSelf,
  (state) => state.chat
);

export const selectIsChatLoading = createSelector(
  selectChat,
  (chat) => chat.isLoading
);

export const selectRoomListState = createSelector(
  selectChat,
  (chat) => chat.roomlst
);

export const selectcmdictState = createSelector(
  selectChat,
  (chat) => chat.cmdict
);

export const selectFclState = createSelector(
  selectChat,
  (chat) => chat.fcl
);

export const selectRoomStatusState = createSelector(
  selectChat,
  (chat) => chat.roomStatus
);
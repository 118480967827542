import { useSelector } from "react-redux";
import { selectShopHeroContent } from "src/slices/landing-page/selectors";
import { getBestFitImage, getBestFitImageForHero } from "src/lib/utils";
import Banner from "src/components/pages/landing-page/banner";
import Slider from "src/components/pages/landing-page/slider";
import { DEVICE_TYPES } from "src/constants";
import { useEffect, useState } from "react";

export default function Hero({ deviceType, slides }) {
  return <Slider containerHeight="300px" slides={slides} />;
}

import {
  Box,
  Button,
  Fab,
  Grid,
  Paper,
  Stack,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  selectAppointmentState,
  selectBusySlotOccupancyResponse,
} from "src/slices/appointment/selectors";
import { AppDispatch } from "src/store";
import * as CONSTANTS from "src/constants";
import { addDays, format, isBefore } from "date-fns";
import { getBusySlotOccupancy } from "src/slices/appointment/thunks";
import { AppointmentState } from "src/slices/appointment/types";
import { toastMessage } from "src/slices/commonSlice";
import { selectedNewAppointmentSlot } from "src/slices/appointment";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useDisplaySettingsContext } from "src/contexts/DisplaySettings";

interface RootProps {
  selected: boolean;
}

const SelectionTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "selected",
})<RootProps>(({ theme, selected }) => ({
  display: "flex",
  height: "50px",
  minWidth: "200px",
  borderRadius: "10px",
  background: selected ? theme.palette.primary.main : "white",
  color: selected ? "white" : "black",
  textAlign: "center",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  marginBottom: "20px",
  [theme.breakpoints.down("md")]: {
    marginBottom: "0px",
  },
}));

const HeadingTypography = styled(Typography)(({ theme }) => ({
  display: "flex",
  minHeight: "50px",
  minWidth: "100px",
  borderRadius: "10px",
  background: "#CCCCCC",
  color: "black",
  textAlign: "center",
  alignItems: "center",
  justifyContent: "center",
  marginBottom: "20px",
}));

const MonthTypography = styled(Typography)(({ theme }) => ({
  display: "flex",
  height: "30px",
  minWidth: "150px",
  borderRadius: "10px",
  background: "white",
  border: `1px solid ${theme.palette.primary.main}`,
  color: "black",
  textAlign: "center",
  alignItems: "center",
  justifyContent: "center",
  marginBottom: "20px",
  cursor: "pointer",
}));

export const BookTime = () => {
  const theme = useTheme();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { heading3, text2, text3, textColor } = useDisplaySettingsContext();

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const appointmentState: AppointmentState = useSelector(
    selectAppointmentState
  );
  const busySlotOccupancResponse = useSelector(selectBusySlotOccupancyResponse);

  const [pageState, setPageState] = useState({
    curdate: CONSTANTS.getJustDatePart(new Date(Date.now())),
    nowdate: new Date(Date.now()),
    lastDate: new Date(Date.now()),
    selectedView: "week",
    selectedSlotConfig: null,
    selectedSlotName: null,
    selectedSlotDate: null,
    startTime: null,
    queueRunningNumber: null,
    slotsMap: {},
  });

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    setPageState((pState) => ({ ...pState, nowdate: pState.curdate }));
    const startdate = pageState.curdate;
    const enddate = addDays(startdate, 10);
    await createSlotMap(startdate, enddate);
    setPageState((pState) => ({ ...pState, nowdate: addDays(enddate, 1) }));
  };

  useEffect(() => {
    if (busySlotOccupancResponse) {
      if (Object.keys(pageState.slotsMap).length === 0) {
        setPageState((pState) => ({
          ...pState,
          slotsMap: busySlotOccupancResponse,
        }));
      } else {
        setPageState((pState) => ({
          ...pState,
          slotsMap: { ...pState.slotsMap, ...busySlotOccupancResponse },
          // nowdate: pState.
        }));
      }
      // console.log('busySlotOccupancResponse', Object.keys(busySlotOccupancResponse));
    }
  }, [busySlotOccupancResponse]);

  const createSlotMap = async (startdate, enddate) => {
    if (appointmentState.serviceModel!.apttype === "PerShop")
      await dispatch(
        getBusySlotOccupancy({
          entitytype: "SERVICEPROVIDERINFO",
          entityid: appointmentState.serviceModel!.serviceID!,
          startdt: startdate,
          enddt: enddate,
          staffid: "SP",
        })
      );
    else
      await dispatch(
        getBusySlotOccupancy({
          entitytype: "SERVICEPROVIDERINFO",
          entityid: appointmentState.serviceModel!.serviceID!,
          startdt: startdate,
          enddt: enddate,
          staffid: appointmentState.selectedEmployee!.staffid!,
        })
      );
  };

  const setcurrent = () => {
    setPageState((pState) => ({
      ...pState,
      curdate: CONSTANTS.getJustDatePart(new Date(Date.now())),
      selectedView: "week",
    }));
    init();
  };

  const selectMonth = (yearIndex: number, monthsIndex: number) => {
    setPageState((pState) => ({
      ...pState,
      curdate: new Date(
        `${new Date().getFullYear() + yearIndex}-${monthsIndex + 1}-${
          yearIndex === 0 && monthsIndex + 1 === new Date().getMonth()
            ? new Date().getDay()
            : 1
        }`
      ),
      selectedView: "week",
    }));
    init();
  };

  const countDaySlots = (slotConfig, dayNumber: number) => {
    if (dayNumber === 7) dayNumber = 0;
    return slotConfig.type === "checkIn"
      ? fromNullableJsonList(slotConfig.slotslist)!.get(dayNumber)?.length ?? 0
      : slotConfig.type === "regularApt"
      ? fromNullableJsonList(slotConfig.slotslist)!.get(dayNumber)?.length ?? 0
      : fromNullableJsonList(slotConfig.slotslist)!.get(dayNumber)?.length ?? 0;
  };

  function fromNullableJsonList(d: any) {
    if (d === null) {
      return null;
    } else {
      const mlist = [];

      for (const v1 of d) {
        const v = v1 as { [key: string]: any };
        const g = v; // Assuming you have a method to convert JSON to RegularSlotConfigurationItem
        mlist.push(g);
      }

      const slotdatamap = new Map();

      for (let i = 0; i < 7; i++) {
        slotdatamap.set(i, []);
      }

      for (const v of mlist) {
        if (slotdatamap.has(v.dow)) {
          const list = slotdatamap.get(v.dow) || [];
          list.push(v);
          slotdatamap.set(v.dow, list);
        }
      }

      return slotdatamap;
    }
  }

  const getSlot = (slotConfig, dayNumber: number, slotIndex: number) => {
    const ast = fromNullableJsonList(slotConfig.slotslist)!.get(dayNumber)![
      slotIndex
    ];
    if (dayNumber === 7) dayNumber = 0;
    return slotConfig.type === "checkIn"
      ? fromNullableJsonList(slotConfig.slotslist)!.get(dayNumber)![slotIndex]
      : slotConfig.type === "regularApt"
      ? fromNullableJsonList(slotConfig.slotslist)!.get(dayNumber)![slotIndex]
      : fromNullableJsonList(slotConfig.slotslist)!.get(dayNumber)![slotIndex];
  };

  const onSelectSlot = () => {
    if (
      pageState.selectedSlotName === null ||
      pageState.selectedSlotDate === null
    ) {
      dispatch(
        toastMessage({
          error: true,
          message: "Please select appt date and time",
        })
      );
    }
    if (
      pageState.selectedSlotName !== null &&
      pageState.selectedSlotDate !== null
    ) {
      let apType = pageState.selectedSlotConfig.type.toString().split(".");
      apType = apType[apType.length - 1];
      dispatch(
        selectedNewAppointmentSlot({
          appointmentType: apType,
          selectedDate: pageState.selectedSlotDate,
          selectedSlotName: pageState.selectedSlotName,
          slotDuration: pageState.selectedSlotConfig?.slotDuration?.toString(),
          startTime: pageState.startTime,
          queueRunningNumber: pageState.queueRunningNumber,
        })
      );

      if (appointmentState.creatingManualAppointment) {
        navigate("/appointment-details");
      } else {
        navigate("/personal-details");
      }
    } else {
      dispatch(
        toastMessage({
          error: true,
          message: "Please select slot first",
        })
      );
    }
  };

  const WeekComponent = ({ index }) => {
    let date = addDays(pageState.curdate, index);
    let dayNumber = date.getDay();
    let slotConfigs = appointmentState.slotConfigurations!.filter((element) =>
      isBefore(new Date(element!.startUsageDate), date)
    );
    let slotConfig =
      slotConfigs.length !== 0 ? slotConfigs[slotConfigs.length - 1] : null;

    let countDSlots = countDaySlots(slotConfig, dayNumber);
    return (
      <Stack direction="column">
        <HeadingTypography fontSize={heading3}
          sx={{
            minWidth: "150px",
          }}
        >
          {format(date, "MMM, dd")} <br /> {format(date, "EEEE")}
        </HeadingTypography>
        {[...Array(countDSlots)].map((_, index) => {
          const slot = getSlot(slotConfig, dayNumber, index);
          let busy = false;
          try {
            busy =
              pageState.slotsMap[date.getTime()][slot.slotName] !== undefined &&
              !pageState.slotsMap[date.getTime()][slot.slotName]?.isfree!;
          } catch (e) {
            console.log(e);
            busy = false;
          }

          const selected =
            pageState.selectedSlotDate?.getTime() === date?.getTime() &&
            pageState.selectedSlotName === slot.slotName;
          return (
            <MonthTypography fontSize={text2}
              sx={{
                background: selected ? theme.palette.primary.main : "white",
                color: selected ? "white" : "black",
              }}
              onClick={() => {
                if (busy) return;
                setPageState((pState) => ({
                  ...pState,
                  selectedSlotName: slot.slotName,
                  selectedSlotDate: date,
                  selectedSlotConfig: slotConfig,
                  startTime:
                    "slotName" in slot
                      ? slot.slotName
                      : format(slot.startTime, "h:mm a"),
                  queueRunningNumber:
                    "slotName" in slot
                      ? slot.booksCount + 1
                      : slot.booksCount + 1,
                }));
              }}
            >
              {slot.slotName}
            </MonthTypography>
          );
        })}
      </Stack>
    );
  };

  return (
    <>
      {pageState.selectedView === "week" && (
        <Box
          sx={{
            position: "absolute",
            bottom: 20,
            right: 20,
            zIndex: 1,
          }}
        >
          <Fab
            size="large"
            color="primary"
            aria-label="add"
            sx={{
              zIndex: 0,
              color: 'white'
            }}
            onClick={async () => {
              const nDate = pageState.nowdate;
              const lDate = addDays(nDate, 10);
              setPageState((pState) => ({
                ...pState,
                lastDate: lDate,
              }));
              var resultmap = await createSlotMap(nDate, lDate);
              setPageState((pState) => ({
                ...pState,
                nowdate: addDays(lDate, 1),
              }));
            }}
          >
            <ExpandMoreIcon />
          </Fab>
        </Box>
      )}
      <Stack direction="column">
        <Typography fontSize={heading3}>Select Time Slot</Typography>
        <Stack direction="row" justifyContent="space-between" mb={2}>
          <Typography fontSize={text2}>
            Select the available members to see available slots
          </Typography>
          <Button
          sx={{
            color: textColor
          }}
            variant="contained"
            disabled={
              pageState.selectedSlotName === null ||
              pageState.selectedSlotDate === null
            }
            onClick={() => {
              onSelectSlot();
            }}
          >
            Next
          </Button>
        </Stack>

        <Paper sx={{ p: 2 }}>
          <Grid container spacing={1}>
            <Grid item xs={12} sx={{marginBottom: '50px'}}>
              <Stack
                direction={isMobile ? "column" : "row"}
                spacing={2}
                justifyContent="center"
              >
                <SelectionTypography
                  selected={pageState.selectedView === "week"}
                  onClick={() => {
                    setcurrent();
                  }}
                >
                  Week
                </SelectionTypography>
                <SelectionTypography
                  selected={pageState.selectedView === "month"}
                  onClick={() => {
                    setPageState((pState) => ({
                      ...pState,
                      selectedView: "month",
                    }));
                  }}
                >
                  Month
                </SelectionTypography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack
                direction="column"
                justifyContent="start"
                alignItems="start"
                overflow="auto"
              >
                {(appointmentState.slotConfigurations === null ||
                  appointmentState.slotConfigurations?.length === 0) && (
                  <Typography fontSize={text3}>
                    Service Provider is in process of defining appointment
                    services
                  </Typography>
                )}
                {pageState.selectedView === "month" && (
                  <Stack direction="row" spacing={2}>
                    {[...Array(10)].map((_, index) => (
                      <Stack direction="column" spacing={1}>
                        <HeadingTypography fontSize={heading3}>
                          {new Date().getFullYear() + index}
                        </HeadingTypography>

                        {[...Array(12)].map((_, monthsIndex) => (
                          <>
                            {index === 0 &&
                            monthsIndex < new Date().getMonth() ? (
                              <></>
                            ) : (
                              <MonthTypography fontSize={text2}
                                onClick={() => {
                                  selectMonth(index, monthsIndex);
                                }}
                              >
                                {format(
                                  new Date(
                                    `${
                                      new Date().getFullYear() + monthsIndex
                                    }-${monthsIndex + 1}-1`
                                  ),
                                  "MMMM"
                                )}
                              </MonthTypography>
                            )}
                          </>
                        ))}
                      </Stack>
                    ))}
                  </Stack>
                )}
                {pageState.selectedView === "week" && (
                  <Grid container spacing={2}>
                    {Object.keys(pageState.slotsMap).map((_, index) => (
                      <Grid item xs={12} sm={4} md={3}>
                        <WeekComponent index={index} />
                      </Grid>
                    ))}
                  </Grid>
                )}
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </Stack>
    </>
  );
};

import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  Divider,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import CKeyValueList from "src/components/CKeyValueList";
import { useDisplaySettingsContext } from "src/contexts/DisplaySettings";
import { toastMessage } from "src/slices/commonSlice";
import { AppDispatch } from "src/store";

const ALPHABET = ["A", "B", "C", "D", "E", "F", "G", "H"];

interface IViewAnswersDialog {
  dialogName: string;
  data: any;
  choices: any;
}

export const ViewAnswersDialog: FC<IViewAnswersDialog> = ({
  dialogName,
  data,
  choices,
}) => {
  const { foregroundColor } = useDisplaySettingsContext();

  const KeyValue = ({ label, value }) => {
    return (
      <Stack direction="row" justifyContent="space-between" sx={{ my: 1 }}>
        <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
          {label}
        </Typography>
        <Typography sx={{ fontSize: "12px" }}>{value}</Typography>
      </Stack>
    );
  };

  return (
    <>
      {data ? (
        <Grid container sx={{ maxHeight: "70vh" }}>
          <Typography
            sx={{
              width: "100%",
              height: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: "bold",
              color: "white",
              background: foregroundColor,
            }}
          >
            Selected Choices
          </Typography>
          <Grid item xs={12} sx={{ display: "flex", flexDirection: "column" }}>
            {data &&
              data.map((mcq, index) => (
                <Box sx={{ py: 2 }}>
                  <Typography sx={{ marginBottom: "5px" }} variant="h6">{`${
                    index + 1
                  }. ${mcq?.questiontext}`}</Typography>
                  <Typography variant="subtitle2">Options</Typography>
                  <FormGroup>
                    {mcq?.choices?.map((choice, choiceIndex) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              choices[index]?.choices?.includes(choiceIndex) ??
                              false
                            }
                          />
                        }
                        label={`${ALPHABET[index]}. ${choice.option}`}
                        sx={{
                          margin: "4px 0px",
                          border: "1px solid black",
                          borderRadius: "4px",
                          padding: 0,
                        }}
                      />
                    ))}
                  </FormGroup>
                  <KeyValue label={"Weightage"} value={mcq.score} />
                  <Divider />
                </Box>
              ))}
          </Grid>
        </Grid>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography>No Result Found</Typography>
        </Box>
      )}
    </>
  );
};

import * as React from "react";
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { styled } from "@mui/material";
import { useDisplaySettingsContext } from "src/contexts/DisplaySettings";

const TypographyTitle = styled(Typography)(() => ({
  fontWeight: 400,
  color: "white",
}));

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number }
) {
  const { text2 } = useDisplaySettingsContext();
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <TypographyTitle fontSize={text2} color="text.secondary">{`${Math.round(
          props.value
        )}%`}</TypographyTitle>
      </Box>
    </Box>
  );
}

export default function RatingBar({ value }) {
  return (
    <Box sx={{ width: "100%" }}>
      <LinearProgressWithLabel
        color="warning"
        sx={{ backgroundColor: "#D9D9D9" }}
        value={value}
      />
    </Box>
  );
}

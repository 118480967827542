import { createAsyncThunk } from "@reduxjs/toolkit";
import { doc, getDoc } from "firebase/firestore";
import { db } from "src/configs/firebase";
import { BaseUrl } from "src/constants";
import * as CONSTANTS from "src/constants";
import { sendRequestJSON } from "src/lib/axios";

import { setLastProductCallPayload } from ".";
import { setEndUserClusterInfoSP } from "../auth";
import { toastMessage } from "../commonSlice";
import { fetchProductFilterResultP } from "../product/thunks";
import {
  selectLastPayloadForProductCall,
  selectLsm,
  selectSelectedAtypeCategory,
} from "./selectors";
import { WebpageDataPayload } from "./types";

const getServerListForServiceProviderByAdditionalType = (
  euci: any,
  type: string | null,
  dispatch
): string[] | null => {
  if (euci === null) {
    dispatch(
      toastMessage({
        error: true,
        message: "Unable to determine Cluster Info",
      })
    );
    return null;
  }

  if (type === null) {
    return euci.serviceprovider;
  }

  switch (type) {
    case CONSTANTS.prodat_cnamedef:
      return euci.product;
    case "REALESTATE":
      return euci.realestate;
    case "PET":
      return euci.pet;
    case CONSTANTS.prodat_homestay:
    case CONSTANTS.prodat_hotel:
    case CONSTANTS.prodat_hospital:
    case CONSTANTS.prodat_banquet:
    case CONSTANTS.prodat_farm:
      return euci!.rentalh;
    case "JOB":
      return euci.job;
    case "VEHICLE":
      return euci.vehicle;
    case CONSTANTS.prodat_classifiedprod:
      return euci.cproduct;
    case CONSTANTS.prodat_farmproduct:
      return euci.farmprod;
    case "REPO":
      return euci.repo;
    case "TRIP":
      return euci.trip;
    case "spp":
    case CONSTANTS.prodat_lab:
    case CONSTANTS.prodat_doctor:
    case CONSTANTS.prodat_foodjoint:
    case CONSTANTS.prodat_medicine:
    case CONSTANTS.prodat_genericservice:
    case CONSTANTS.prodat_gen:
    case CONSTANTS.prodat_rentprod:
      return euci.product;
    default:
      return euci.serviceprovider;
  }
};

const getServerListForCname = async (
  cname: string,
  addtype: string = null,
  enduserclusterInfoMapSP,
  dispatch
) => {
  if (enduserclusterInfoMapSP[cname]) {
    const res = getServerListForServiceProviderByAdditionalType(
      enduserclusterInfoMapSP[cname],
      addtype,
      dispatch
    );
    if (!res) {
      return null;
    }
    return res;
  } else {
    const kd = await getEndUserClusterInfoSP(cname, dispatch);
    if (!kd) {
      dispatch(
        toastMessage({
          error: true,
          message:
            "Unable to locate servers for ServiceProvider, please contact admin",
        })
      );
      return null;
    }
  }
};

const getEndUserClusterInfoSP = async (clustername: string, dispatch) => {
  // if (enduserclusterInfoMap[clustername]) {
  //   return enduserclusterInfoMapSP[clustername];
  // }

  let enduserclusterInfoSP = {};
  const kd: any = await getDoc(doc(db, "ENDUSERCLUSTERINFOSP", clustername));
  if (kd.exists()) {
    let data = kd.data();
    if (data.adata) {
      Object.keys(data.adata).forEach((key) => {
        data[key] = data.adata[key];
      });
    }
    enduserclusterInfoSP[clustername] = data;
    dispatch(setEndUserClusterInfoSP(enduserclusterInfoSP));
    // dispatch(setEndUserClusterInfo(enduserclusterInfoMap));
    return [enduserclusterInfoSP, data];
  } else {
    return null;
  }
};

function fromUserEndUserClusterInfo(euci, type, forservice) {
  if (
    forservice &&
    ![
      CONSTANTS.prodat_homestay,
      CONSTANTS.prodat_hotel,
      CONSTANTS.prodat_hospital,
      CONSTANTS.prodat_banquet,
      CONSTANTS.prodat_farm,
      CONSTANTS.prodat_farmproduct,
    ].includes(type)
  ) {
    return euci.serviceprovider || [];
  } else {
    if (type === "REALESTATE") {
      return euci.realestate || [];
    }
    if (
      [
        CONSTANTS.prodat_homestay,
        CONSTANTS.prodat_hotel,
        CONSTANTS.prodat_hospital,
        CONSTANTS.prodat_banquet,
        CONSTANTS.prodat_farm,
      ].includes(type)
    ) {
      return euci.rentalh || [];
    } else if (type === CONSTANTS.prodat_farmproduct) {
      return euci.farmprod || [];
    } else if (type === "PET") {
      return euci.pet || [];
    } else if (type === "JOB") {
      return euci.job || [];
    } else if (type === "VEHICLE") {
      return euci.vehicle || [];
    } else if (type === CONSTANTS.prodat_classifiedprod) {
      return euci.cproduct || [];
    } else if (type === "TRIP") {
      return euci.trip || [];
    } else {
      return [];
    }
  }
}

function getRandomInt(min: number, max: number): number {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const webPageDataRequest = createAsyncThunk(
  "webpageData/webPageDataRequest",
  async (payload: WebpageDataPayload, { dispatch, rejectWithValue }) => {
    try {
      const response = await sendRequestJSON(
        {
          data: {
            qrytype: "WEBPAGEDATAREQ",
            wpdm: {
              websitename: `${payload.websitename}`,
              action: `${payload.action}`,
            },
          },
        },
        "",
        "post",
        BaseUrl.digital
      );

      // return realestateData;

      if (response.result?.data?.data?.errorid === -1) {
        return response.result?.data;
      } else {
        return false;
      }
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: "Error while fetching site data. Please try again.",
        })
      );
      return rejectWithValue("Error has occured");
    }
  }
);

export const getSubMenuCategories = createAsyncThunk(
  "webpageData/getSubMenuCategories",
  async (payload: any, { dispatch, rejectWithValue }) => {
    try {
      const response = await sendRequestJSON(
        {
          data: {
            qrytype: "WEBPAGEDATAREQ",
            wpdm: {
              entityid: payload.entityId,
              websitename: `${payload.websitename}`,
              action: "getdataforchildcat",
              atype: "spp",
              cat: payload.cat,
            },
          },
        },
        "",
        "post",
        BaseUrl.digital
      );

      if (response.result?.data?.data?.errorid === -1) {
        return response.result?.data;
      } else {
        return false;
      }
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: "Error while fetching site data. Please try again.",
        })
      );
      return rejectWithValue("Error has occured");
    }
  }
);

export const getSubcategories = createAsyncThunk(
  "webpageData/getSubcategories",
  async (payload: any, { dispatch, rejectWithValue }) => {
    try {
      const response = await sendRequestJSON(
        {
          data: {
            qrytype: "WEBPAGEDATAREQ",
            wpdm: {
              entityid: payload.entityId,
              websitename: `${payload.websitename}`,
              action: "getdataforchildcat",
              atype: payload.atype,
              cat: payload.cat,
            },
          },
        },
        "",
        "post",
        BaseUrl.digital
      );

      if (response.result?.data?.data?.errorid === -1) {
        return response.result?.data;
      } else {
        return false;
      }
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: "Error while fetching site data. Please try again.",
        })
      );
      return rejectWithValue("Error has occured");
    }
  }
);

export const getClassData = createAsyncThunk(
  "webpageData/getClassData",
  async (payload: any, { dispatch, rejectWithValue }) => {
    try {
      const response = await sendRequestJSON(
        {
          data: {
            qrytype: "WEBPAGEDATAREQ",
            wpdm: {
              websitename: `${payload.websitename}`,
              action: `getdataforgrade`,
              gradename: payload.gradename,
            },
          },
        },
        "",
        "post",
        BaseUrl.digital
      );

      if (response.result?.data?.data?.errorid === -1) {
        return response.result?.data;
      } else {
        return false;
      }
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: "Error while fetching site data. Please try again.",
        })
      );
      return rejectWithValue("Error has occured");
    }
  }
);

export const fetchProductsForCategory = createAsyncThunk(
  "webpageData/dataForProductCategory",
  async (payload: WebpageDataPayload, { dispatch, rejectWithValue }) => {
    try {
      const response = await sendRequestJSON(
        {
          data: {
            qrytype: "WEBPAGEDATAREQ",
            wpdm: {
              websitename: `${payload.websitename}`,
              action: `${payload.action}`,
            },
          },
        },
        "",
        "post",
        BaseUrl.digital
      );

      if (response.result?.data?.data?.errorid === -1) {
        return response.result.data;
      } else {
        return false;
      }
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: "Error while fetching site data. Please try again.",
        })
      );
      return rejectWithValue("Error has occured");
    }
  }
);

//// GET PRODUCTS FROM LUCID SEARCH

export const fetchShopProductFilterResult = createAsyncThunk(
  "webpageData/fetchShopProductFilterResult",
  async (payload: any, { dispatch, getState }) => {
    const state = getState();
    const lsm = selectLsm(state);
    const atype = selectSelectedAtypeCategory(state);

    const lastPayload = selectLastPayloadForProductCall(state);

    dispatch(setLastProductCallPayload(payload));

    dispatch(
      fetchProductFilterResultP({
        serviceModel: lsm,
        isService: lsm.hasapt === null ? false : lsm.hasapt,
        prodtype: atype,
      })
    );

    //     var um = await HelpUtil.getUserRepository()
    const um = await getEndUserClusterInfoSP(lsm?.cname, dispatch);
    const newEndUserClusterInfoSP = um[0];
    const newUm = um[1];
    if (newUm === null) {
      dispatch(
        toastMessage({
          error: true,
          message: "Unable to find the ServiceProvider, please try again",
        })
      );
      return;
    }

    let serverList = [];
    if (um) {
      serverList = await getServerListForCname(
        lsm?.cname,
        atype,
        newEndUserClusterInfoSP,
        dispatch
      );
      if (!serverList) return;
    }

    if (serverList.length === 0) {
      dispatch(
        toastMessage({
          error: true,
          message: "Unable to locate servers, please contact admin",
        })
      );
      return;
    }

    let filterResultModel;
    try {
      const response = await sendRequestJSON(
        payload,
        "/api/Search/fetchProductFilterResult",
        "post",
        BaseUrl.custom,
        serverList[0],
        newEndUserClusterInfoSP[lsm?.cname].docid
      );
      return {
        payload: response.result.data,
        isAppendResult:
          lastPayload?.dynamicProperty?.categorytype ===
          payload?.dynamicProperty?.categorytype,
      };
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return false;
    }
  }
);

export const fetchShopResult = createAsyncThunk(
  "webpageData/fetchShopResult",
  async (payload: any, { dispatch, getState }) => {
    const state = getState();
    const lsm = selectLsm(state);
    const atype = selectSelectedAtypeCategory(state);

    const lastPayload = selectLastPayloadForProductCall(state);

    dispatch(setLastProductCallPayload(payload));

    // dispatch(
    //   fetchProductFilterResultP({
    //     serviceModel: lsm,
    //     isService: lsm.hasapt === null ? false : lsm.hasapt,
    //     prodtype: atype,
    //   })
    // );

    //     var um = await HelpUtil.getUserRepository()
    const um = await getEndUserClusterInfoSP(lsm?.cname, dispatch);
    const newEndUserClusterInfoSP = um[0];
    const newUm = um[1];
    // if (newUm === null) {
    //   dispatch(
    //     toastMessage({
    //       error: true,
    //       message: "Unable to find the ServiceProvider, please try again",
    //     })
    //   );
    //   return;
    // }

    let serverList = [];
    if (um) {
      serverList = await getServerListForCname(
        lsm?.cname,
        null,
        newEndUserClusterInfoSP,
        dispatch
      );
      if (!serverList) return;
    }

    // if (serverList.length === 0) {
    //   dispatch(
    //     toastMessage({
    //       error: true,
    //       message: "Unable to locate servers, please contact admin",
    //     })
    //   );
    //   return;
    // }

    let filterResultModel;
    try {
      const response = await sendRequestJSON(
        payload,
        "/api/Search/GetServiceProviderList",
        "post",
        BaseUrl.custom,
        serverList[0],
        newEndUserClusterInfoSP[lsm?.cname].docid
      );

      const lastP = lastPayload?.dynamicProperty;
      const latestP = payload?.dynamicProperty;

      const lastCmi = lastP?.cmi;
      const latestCmi = latestP?.cmi;
      return {
        payload: response.result.data,
        isAppendResult:
          lastP?.servicetype === latestP?.servicetype &&
          ((lastCmi?.selvalue === latestCmi?.selvalue &&
            lastCmi?.selkey === latestCmi?.selkey) ||
            (latestP?.sp?.lati && latestP?.sp?.lati === lastP?.sp?.lati)),
      };
    } catch (error) {
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return false;
    }
  }
);

import { Box, Rating, Typography, useMediaQuery, useTheme } from "@mui/material";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import InfoIcon from "@mui/icons-material/Info";
import * as CONSTANTS from "src/constants";
import { CartOrigin, LimitedServiceModel } from "src/slices/cart/types";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "src/store";
import { fetchProductFilterResultP } from "src/slices/product/thunks";
import { setAppointmentServiceModel } from "src/slices/appointment";
import { useGeolocationContext } from "src/contexts/GeolocationContext";
import { Chat } from "@mui/icons-material";
import { toastMessage } from "src/slices/commonSlice";
import {
  selectRoomListState,
  selectcmdictState,
} from "src/slices/chat/selectors";
import { auth } from "src/configs/firebase";

interface IServiceListItem {
  sm: LimitedServiceModel;
}

export const ServiceListItem: FC<IServiceListItem> = ({ sm }) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const position = useGeolocationContext();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const roomlist = useSelector(selectRoomListState);
  const cmdict = useSelector(selectcmdictState);

  const openrentalSpProduct = (rentalptype: string, rentaldisptype: string) => {
    dispatch(
      fetchProductFilterResultP({
        serviceModel: sm,
        isService: false,
        prodtype: rentalptype,
      })
    ).then(() => {
      // var widget = RentalSpListView(
      //   servicetype: rentalptype,
      //   displaytype: rentaldisptype,
      //   lsm: serviceModel,
      // );
      navigate(`/product-list/product-item`, {
        state: {
          serviceModel: sm,
          isService: false,
          prodtype: rentalptype,
        },
      });
    });
  };

  const openStoreProducts = (orderproducttype: string) => {
    dispatch(
      fetchProductFilterResultP({
        serviceModel: sm,
        isService: false,
        prodtype: orderproducttype,
      })
    ).then(() => {
      navigate(`/product-list/product-item`, {
        state: {
          serviceModel: sm,
          isService: false,
          prodtype: orderproducttype,
        },
      });
    });
  };

  const callappointmentrelatedaction = (producttype: string) => {
    dispatch(
      fetchProductFilterResultP({
        serviceModel: sm,
        isService: sm.hasapt === null ? false : sm.hasapt,
        prodtype: producttype,
      })
    ).then(() => {
      dispatch(
        setAppointmentServiceModel({
          serviceModel: sm,
          isfromowner: false,
          fromentitypage: false,
          prodtype: producttype,
        })
      );
      setTimeout(function () {
        navigate(`/appointment-filter`, {
          state: {
            origin: CartOrigin.AppointmentEndUser,
            serviceModel: sm,
          },
        });
      }, 500);
    });
  };

  const checkifchannelexist = (pid, metaid) => {
    var mlst = roomlist;

    for (var v of mlst) {
      if (v.isindi && v.metaid === metaid && v.pid === pid) {
        return v;
      }
    }
  };
  const goToChatRoom = (serviceModel) => {
    console.log(serviceModel);
    if (!serviceModel.crid) {
      dispatch(
        toastMessage({
          error: true,
          message: "Service is not supporting chat",
        })
      );
      return;
    }

    let channel = checkifchannelexist(null, serviceModel.crid!.split("@")[0]);
    let ccr = {
      sid: serviceModel.serviceID,
      sname: serviceModel.serviceName,
      cid: serviceModel.crid!.split("@")[0],
      appname: serviceModel.appname,
      uid: auth.currentUser.uid,
      simg: serviceModel.photo1,
      crid: serviceModel.crid,
    };

    if (!channel) {
      navigate("/chat", {
        state: {
          cmagg: null,
          croi: null,
          fromChatlist: false,
          username: auth.currentUser?.displayName ?? "",
          uid: auth.currentUser?.uid ?? "",
          newchatreq: ccr,
        },
      });
    } else {
      let cmm = cmdict[channel.metaid];
      if (!cmm) {
        dispatch(
          toastMessage({
            error: true,
            message: "No Chat room configured",
          })
        );
        return;
      }
      let mc = {
        cm: cmm.cm,
        forroom: {
          rid: channel.rid,
          isact: cmm.listeneractive,
          pid: channel.pid,
          rkey: channel.rkey,
        },
        isindi: channel.isindi,
        metaid: channel.metaid,
        toconsider: true,
        info: false,
        defaultactivated: cmm.listeneractive ? "T" : "F",
        listeneractive: !cmm.userdeactivated ? "T" : "F",
      };
      navigate("/chat", {
        state: {
          cmagg: mc,
          croi: channel.lastopeninfo,
          fromChatlist: true,
          username: auth.currentUser?.displayName ?? "",
          uid: auth.currentUser?.uid ?? "",
          newchatreq: null,
        },
      });
    }
  };

  const showIconsBasedOnService = (serviceModel: LimitedServiceModel) => {
    let elements = [];
    if (CONSTANTS.sphasappointmentenduser(serviceModel)) {
      elements.push(
        <EventAvailableIcon
          sx={{
            cursor: "pointer",
          }}
          onClick={() => {
            callappointmentrelatedaction(CONSTANTS.prodat_genericservice);
          }}
        />
      );
    }
    if (CONSTANTS.hasHotelInSt(serviceModel.servicetype)) {
      elements.push(
        <ShoppingCartIcon
          onClick={() => {
            openrentalSpProduct(CONSTANTS.sert_hotel, CONSTANTS.dispt_hotel);
          }}
        />
      );
    }
    if (CONSTANTS.hasBanquetInSt(serviceModel.servicetype)) {
      elements.push(
        <ShoppingCartIcon
          onClick={() => {
            openrentalSpProduct(
              CONSTANTS.sert_banquet,
              CONSTANTS.dispt_banquet
            );
          }}
        />
      );
    }
    if (CONSTANTS.hasHomeStayInSt(serviceModel.servicetype)) {
      elements.push(
        <ShoppingCartIcon
          onClick={() => {
            openrentalSpProduct(
              CONSTANTS.sert_homestay,
              CONSTANTS.dispt_homestay
            );
          }}
        />
      );
    }
    if (CONSTANTS.hasWeddingHallInSt(serviceModel.servicetype)) {
      elements.push(
        <ShoppingCartIcon
          onClick={() => {
            openrentalSpProduct(CONSTANTS.sert_wedding, CONSTANTS.sert_wedding);
          }}
        />
      );
    }
    if (CONSTANTS.lmsphasjob(serviceModel)) {
      elements.push(
        <ShoppingCartIcon
          onClick={() => {
            const fetchedList = CONSTANTS.fetchProductBySectionName("Jobs");
            if (fetchedList.length) {
              const fetchedData = fetchedList[0].servicetypelist;
              // dispatch(
              //   fetchProductFilterResult({
              //     dynamicProperty: {
              //       psc: {
              //         servicetype: fetchedData.querystr,
              //         grouptype: fetchedData.grptype,
              //         isshop: false,
              //         sp: {
              //           curStatename: position.district,
              //           statename: position.district,
              //           lati: position.latitude,
              //           longi: position.longitude,
              //         },
              //       },
              //       categorytype: fetchedData.querystr,
              //       termquery: "",
              //       filters: [],
              //       facetparalist: [],
              //       serviceproviderid: "",
              //       isservice: false,
              //     },
              //   } as IFetchProductFilterResultPayload)
              // ).then(() => {
              //   navigate("/job");
              // });
              // openJobPage(context);
            }
          }}
        />
      );
    }

    if (CONSTANTS.lmspwanttosellproduct(serviceModel)) {
      elements.push(
        <ShoppingCartIcon
          sx={{
            cursor: "pointer",
          }}
          onClick={() => {
            openStoreProducts(CONSTANTS.prodat_gen);
          }}
        />
      );
    }
    if (CONSTANTS.lmsphasfoodjoint(serviceModel)) {
      elements.push(
        <ShoppingCartIcon
          sx={{
            cursor: "pointer",
          }}
          onClick={() => {
            openStoreProducts(CONSTANTS.prodat_foodjoint);
          }}
        />
      );
    }
    if (CONSTANTS.lmsphasrentalprod(serviceModel)) {
      elements.push(
        <ShoppingCartIcon
          sx={{
            cursor: "pointer",
          }}
          onClick={() => {
            openStoreProducts(CONSTANTS.prodat_rentprod);
          }}
        />
      );
    }
    if (CONSTANTS.lmsphasmedicine(serviceModel)) {
      elements.push(
        <ShoppingCartIcon
          sx={{
            cursor: "pointer",
          }}
          onClick={() => {
            openStoreProducts(CONSTANTS.prodat_medicine);
          }}
        />
      );
    }
    if (CONSTANTS.lmsphasdoctor(serviceModel)) {
      elements.push(
        <ShoppingCartIcon
          sx={{
            cursor: "pointer",
          }}
          onClick={() => {
            callappointmentrelatedaction(CONSTANTS.prodat_doctor);
          }}
        />
      );
    }
    if (CONSTANTS.lmsphasmedicallab(serviceModel)) {
      elements.push(
        <ShoppingCartIcon
          sx={{
            cursor: "pointer",
          }}
          onClick={() => {
            callappointmentrelatedaction(CONSTANTS.prodat_lab);
          }}
        />
      );
    }
    elements.push(
      <Chat
        sx={{
          cursor: "pointer",
        }}
        onClick={() => {
          goToChatRoom(serviceModel);
        }}
      />
    );
    return elements;
  };

  return (
    <Box
      key={sm.crid}
      sx={{
        padding: "10px",
        wordBreak: "break-word",
        background: "white",
        borderRadius: "10px",
        // "&:hover": {
        //   border: "1px solid #000000",
        //   cursor: "pointer",
        // },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile? "column" : "row",
        }}
      >
        <Box
          minHeight={isMobile ? "100px" : "150px"}
          minWidth={isMobile ? "100px" : "150px"}
          sx={{
            borderRadius: "5px",
            marginRight: "10px",
            backgroundImage: `url(${sm.photo1})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "contain",
          }}
        ></Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "bold",
              }}
            >
              {sm.servicename}
            </Typography>
            <InfoIcon
              sx={{
                m: 1,
                "&:hover": {
                  cursor: "pointer",
                },
              }}
              onClick={() => {
                navigate(`${sm.serviceID}`);
              }}
            />
          </Box>
          <Typography
            sx={{
              fontSize: "13px",
              marginBottom: "15px",
            }}
          >{`${sm.adm.area_sector}, ${sm.adm.town_village}, ${sm.adm.district}, ${sm.adm.state}`}</Typography>
          <Typography
            sx={{
              fontSize: "13px",
            }}
          >
            {sm.phone}
          </Typography>
          <Rating name="read-only" value={sm.ratingcount} readOnly />
          <Box sx={{ paddingTop: 2, display: "flex", gap: 2 }}>
            {showIconsBasedOnService(sm)}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import StaticGoogleMap from 'src/components/pages/home/static-google-map';
import { useDisplaySettingsContext } from 'src/contexts/DisplaySettings';
import { LimitedServiceModel } from 'src/slices/cart/types';
import { selectHomeSelectedProviders, selectIsHomeLoading } from 'src/slices/home/selectors';
import { fetchSingleServiceProvider } from 'src/slices/home/thunks';
import { IServiceProvider } from 'src/slices/home/types';
import { AppDispatch } from 'src/store';

export function ProviderDetailsPage() {
  const dispatch = useDispatch<AppDispatch>();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { heading2, heading3, text1, text2, text3 } = useDisplaySettingsContext();
  
  const params = useParams();
  const selectSingleProvider: IServiceProvider = useSelector(
    selectHomeSelectedProviders
  );
  const selectIsLoading = useSelector(selectIsHomeLoading);
  const [providerDetails, setProviderDetails] = useState<LimitedServiceModel>();
  useEffect(() => {
    dispatch(
      fetchSingleServiceProvider({
        dynamicProperty: params.providerId,
      })
    );
  }, []);

  useEffect(() => {
    if (
      selectSingleProvider &&
      selectSingleProvider?.smlist &&
      selectSingleProvider?.smlist?.length !== 0
    ) {
      setProviderDetails(selectSingleProvider?.smlist[0]);
    }
  }, [selectSingleProvider]);

  return (
    <>
      {providerDetails ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: isMobile ? "100%" : "50%",
            margin: "0 auto",
          }}
        >
          <Box
            sx={{
              background: "white",
              padding: "10px",
              borderRadius: "5px",
              marginBottom: "5px",
            }}
          >
            {providerDetails ? (
              <Box
                key={providerDetails.crid}
                onClick={() => {}}
                sx={{
                  padding: "10px",
                  marginTop: "5px",
                  marginBottom: "5px",
                  wordBreak: "break-word",
                  background: "white",
                  borderRadius: "10px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Box
                    minHeight="150px"
                    minWidth="150px"
                    sx={{
                      borderRadius: "5px",
                      marginRight: "10px",
                      backgroundImage: `url(${providerDetails.photo1})`,
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      backgroundSize: "contain",
                    }}
                  ></Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: heading3,
                        fontWeight: "bold",
                      }}
                    >
                      {providerDetails.servicename}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "15px",
                        marginBottom: "15px",
                      }}
                    >{`${providerDetails.adm.area_sector}, ${providerDetails.adm.town_village}, ${providerDetails.adm.district}, ${providerDetails.adm.state}`}</Typography>
                    <Typography
                      sx={{
                        fontSize: "17px",
                      }}
                    >
                      {providerDetails.phone}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            ) : (
              <></>
            )}
          </Box>
          <Box
            sx={{
              background: "white",
              padding: "10px",
              borderRadius: "5px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography fontSize={text2} fontWeight="bold" marginBottom="10px">
              LATEST FEED
            </Typography>
            {providerDetails ? (
              <StaticGoogleMap
                latlngs={[
                  {
                    lat: providerDetails.adm.latitude,
                    lng: providerDetails.adm.longitude,
                  },
                ]}
                height="50vh"
                width="100%"
              />
            ) : (
              <></>
            )}
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: "80vh",
            margin: "0 auto",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {selectIsLoading ? (
            <></>
          ) : (
            <Typography fontSize={heading2}>No Record Found</Typography>
          )}
        </Box>
      )}
    </>
  );
}

import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  Divider,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import CKeyValueList from "src/components/CKeyValueList";
import { useDisplaySettingsContext } from "src/contexts/DisplaySettings";
import { toastMessage } from "src/slices/commonSlice";
import { setDialog } from "src/slices/uiSettingsSlice";
import {
  loadPollQuestions,
  loadPracticeQuestions,
  loadResults,
  submitPoll,
  submitPracticeQuestions,
} from "src/slices/user-panel/thunks";
import { AppDispatch } from "src/store";

const ALPHABET = ["A", "B", "C", "D", "E", "F", "G", "H"];

interface IPollDialog {
  dialogName: string;
  resultData: any;
  setActionButton: any;
}

export const PollDialog: FC<IPollDialog> = ({
  dialogName,
  resultData,
  setActionButton,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [mcqs, setMcqs] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [answerList, setAnswerList] = useState([]);

  const { foregroundColor } = useDisplaySettingsContext();

  const ActionButtons = () => [
    {
      text: "Submit",
      color: "primary",
      variant: "contained",
      click: () => {
        console.log(resultData);
        console.log(answerList);
        dispatch(
          submitPoll({
            asgid: resultData.id,
            answerList: [
              {
                id: resultData.id,
                name: resultData.name,
                date: resultData.date,
                answerList: answerList.map((data) => ({
                  qid: data.qid,
                  choices: data.choices,
                  score: data.score,
                })),
              },
            ],
          })
        ).then(response => {
          if(response.payload.data.errorid === -1){
            dispatch(toastMessage({
              message: 'Answers Submitted'
            }));
          } else {
            dispatch(toastMessage({
              error: true,
              message: 'Unable to submit anwers'
            }));
          }
        });
        dispatch(
          setDialog({
            open: false,
            dialogName,
          })
        );
      },
    },
    {
      text: "Cancel",
      color: "secondary",
      variant: "contained",
      click: () => {
        dispatch(
          setDialog({
            open: false,
            dialogName,
          })
        );
      },
    },
  ];

  useEffect(() => {
    setActionButton(ActionButtons());
  }, [answerList, mcqs, resultData]);

  useEffect(() => {
    if (resultData) {
      dispatch(loadPollQuestions(resultData)).then((response) => {
        setIsLoading(false);
        if(response.payload?.data?.infomsg){
          dispatch(toastMessage({
            error: true,
            message: response.payload?.data?.infomsg ?? ''
          }));
          dispatch(setDialog({open: false, dialogName}));
          return;
        }
        setMcqs(response.payload?.data?.rawasg?.qlist ?? null);
        setAnswerList(
          response.payload?.data?.rawasg?.qlist?.map((_) => null) ?? []
        );
      });
    }
  }, [resultData]);

  const KeyValue = ({ label, value }) => {
    return (
      <Stack direction="row" justifyContent="space-between" sx={{ my: 1 }}>
        <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
          {label}
        </Typography>
        <Typography sx={{ fontSize: "12px" }}>{value}</Typography>
      </Stack>
    );
  };

  return (
    <>
      {mcqs ? (
        <Grid container sx={mcqs && { maxHeight: "70vh" }}>
          <Typography
            sx={{
              width: "100%",
              height: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: "bold",
              color: "white",
              background: foregroundColor,
            }}
          >
            Practice Questions
          </Typography>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
            }}
          >
            {mcqs.map((mcq, index) => (
              <Box sx={{ py: 2 }}>
                <Typography sx={{ marginBottom: "5px" }} variant="h6">{`${
                  index + 1
                }. ${mcq?.questiontext}`}</Typography>
                <Typography variant="subtitle2">Options</Typography>
                <FormGroup>
                  {mcq?.choices?.map((choice, choiceIndex) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={answerList[index]?.option === choice.option}
                          onChange={() => {
                            if (answerList[index]?.option === choice.option) {
                              answerList[index] = null;
                              setAnswerList([...answerList]);
                              return;
                            }
                            answerList[index] = {
                              option: choice.option,
                              choices: [choiceIndex],
                              qid: mcq.qid,
                              score: mcq.score,
                            };
                            setAnswerList([...answerList]);
                          }}
                        />
                      }
                      label={`${ALPHABET[choiceIndex]}. ${choice.option}`}
                      sx={{
                        margin: "4px 0px",
                        border: "1px solid black",
                        borderRadius: "4px",
                        padding: 0,
                      }}
                    />
                  ))}
                </FormGroup>
                <KeyValue label={"Weightage"} value={mcq.score} />
                <Divider />
              </Box>
            ))}
          </Grid>
        </Grid>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {!isLoading ? (
            <Typography>No Result Found</Typography>
          ) : (
            <CircularProgress color="inherit" size={20} />
          )}
        </Box>
      )}
    </>
  );
};

import { Divider, useMediaQuery, useTheme } from "@mui/material";
import { useRef } from "react";
import { useSelector } from "react-redux";
import Box from "src/components/pages/landing-page/Box";
import Card from "src/components/pages/landing-page/Card";
import Management3Card from "src/components/pages/landing-page/Management3Card";
import Management2Card from "src/components/pages/landing-page/Managment2Card";
import { getBestFitImage } from "src/lib/utils";
import { selectFeatureList } from "src/slices/landing-page/selectors";

export default function Features({ deviceType }) {
  const features = useSelector(selectFeatureList);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    features &&
    features?.length > 0 && (
      <Box
        id="features"
        title="Features"
        subtitle=""
        sx={{
          backgroundColor: "white",
          paddingLeft: { xs: 2, sm: 4 },
          paddingRight: { xs: 2, sm: 4 },
          paddingBottom: 2,
        }}
      >
        <Box.BoxContainer type={"flex"} gap={0}>
          {features.map((feature, index) => {
            return (
              <>
                <Management3Card
                  key={feature.id}
                  image={{
                    url:
                      getBestFitImage(deviceType, feature.imageList)?.value ??
                      null,
                    alt: feature.title,
                  }}
                  title={feature.title}
                  subtitle1={feature.info1}
                  subtitle2={feature.info2}
                />
              </>
            );
          })}
        </Box.BoxContainer>
      </Box>
    )
  );
}

import { TextField, Box, useMediaQuery, useTheme } from "@mui/material";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "src/store";
import { useNavigate } from "react-router-dom";
import { CategoryButton } from "./CategoryButton";
import {
  selectEntityId,
  selectIsConsolidateMarket,
  selectLsm,
} from "src/slices/landing-page/selectors";
import { useDisplaySettingsContext } from "src/contexts/DisplaySettings";
import { hexToRGBA } from "src/lib/utils";
import { fetchShopResult } from "src/slices/landing-page/thunks";

export const StListCategory = ({ subdomain, selectedCategory, setSelectedCategory }) => {

  const { foregroundColor } = useDisplaySettingsContext();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const consolidateMarket = useSelector(selectIsConsolidateMarket);

  return (
    <>
      <Box
        sx={{
          backgroundColor: hexToRGBA(foregroundColor, 0.1),
          padding: isMobile ? "24px" : "24px 72px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            overflow: "scroll",
            gap: "32px",
            "&::-webkit-scrollbar": {
              display: "none",
            },
            "-ms-overflow-style": "none",
            "scrollbar-width": "none",
          }}
        >
          {consolidateMarket?.stlst?.map((st) => (
            <CategoryButton
              name={st?.name}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
            />
          ))}
        </Box>
      </Box>
    </>
  );
};

import { Search } from "@mui/icons-material";
import {
  Box,
  IconButton,
  InputAdornment,
  styled,
  TextField,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { FC, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDisplaySettingsContext } from "src/contexts/DisplaySettings";
import {
  selectCatlst,
  selectEntityId,
  selectLsm,
  selectProdatypeinfo,
  selectSelectedAtypeCategory,
} from "src/slices/landing-page/selectors";
import { fetchShopProductFilterResult } from "src/slices/landing-page/thunks";
import { AppDispatch } from "src/store";
import { AtypeTab } from "../AtypeTab";
import { PopoverMenu } from "./CNestedMenu";

const SearchTextField = styled(TextField)`
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border: 1px solid #0294cf;
    }

    &.Mui-focused active {
      border: 1px solid #0294cf;
    }

    .Mui-focused focus {
      border: 1px solid #0294cf;
    }
  }
`;

interface Category1Props {
  subdomain: string;
}

export const Category1: FC<Category1Props> = ({ subdomain }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [searchText, setSearchText] = useState("");
  const catlst = useSelector(selectCatlst);
  const prodatypeinfo = useSelector(selectProdatypeinfo);
  const selectedAtype = useSelector(selectSelectedAtypeCategory);
  const [selectedCat, setSelectedCat] = useState("");
  const entityId = useSelector(selectEntityId);
  const lsm = useSelector(selectLsm);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { foregroundColor } = useDisplaySettingsContext();
  //   useEffect(() => {
  //     if (catlst?.length > 0) {
  //       const category = catlst[0];
  //       if (!category.hc) setSelectedCat(category.name);
  //     }
  //   }, [selectedAtype]);

  const getCategoryDataFromSearch = useCallback(
    (text) => {
      dispatch(
        fetchShopProductFilterResult({
          dynamicProperty: {
            atyp: selectedAtype,
            categorytype: null,
            psc: {
              atyp: selectedAtype,
              isshop: true,
              lsm: lsm,
            },
            termquery: text,
            serviceproviderid: entityId,
            isservice: false,
          },
        })
      );
    },
    [selectedAtype, entityId, lsm]
  );

  return (
    <>
      {catlst && (
        <Box
          sx={{
            backgroundColor: 'white',
            padding: isMobile ? "15px" : "15px 50px",
          }}
        >
          {prodatypeinfo.atypelst && (
            <AtypeTab
              atype={selectedAtype}
              atypelst={prodatypeinfo?.atypelst}
              subdomain={subdomain}
            />
          )}
          <SearchTextField
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            size="small"
            label="Search Product"
            variant="outlined"
            inputProps={{
              autoComplete: "off",
              //   style: { paddingRight: 0}
            }}
            InputProps={{
              style: { paddingRight: 5, borderRadius: "50px" },
              endAdornment: (
                <InputAdornment position="end" sx={{ padding: 0, margin: 0 }}>
                  <IconButton
                    sx={{
                      backgroundColor: foregroundColor,
                      m: 0,
                      p: 0,
                      width: "50px",
                      height: "25px",
                      borderRadius: "50px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      if (searchText.trim() !== "") {
                        getCategoryDataFromSearch(searchText.trim());
                        setSelectedCat("");
                      }
                    }}
                  >
                    <Search sx={{ color: "white" }} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{
              width: "100%",
              backgroundColor: "white",
              borderRadius: "50px",
              mb: "32px",
            }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              overflow: "scroll",
              gap: "32px",
              "&::-webkit-scrollbar": {
                display: "none",
              },
              "-ms-overflow-style": "none",
              "scrollbar-width": "none",
            }}
          >
            {catlst.map((category) => (
              //   <CategoryMenu
              //     id={category.id}
              //     name={category.name}
              //     hc={category.hc}
              //     subdomain={subdomain}
              //     selected={selectedCat}
              //     setSelected={setSelectedCat}
              //   />
              // <NestedMenu
              //   id={category.id}
              //   name={category.name}
              //   hc={category.hc}
              //   subdomain={subdomain}
              //   selected={selectedCat}
              //   setSelected={setSelectedCat}
              // />
              <PopoverMenu
                id={category.id}
                name={category.name}
                hc={category.hc}
                subdomain={subdomain}
                selected={selectedCat}
                setSelected={setSelectedCat}
              />
            ))}
          </Box>
        </Box>
      )}
    </>
  );
};
